import React, { useEffect } from "react";
import { useFetch } from "hooks/useFetch";
import PathsAPI from "constants/PathsAPI";
import styles from "styles/Devices.module.css";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import Button from "components/Button";
import NavButtonLink from "components/NavButtonLink";
import stateMode from "constants/stateMode";
import { toast } from "react-toastify";
import TableComponent from "components/Table";
import devicesTable from "constants/tables/devicesTable";
import { useAuthContext } from "hooks/useAuthContext";

const DevicesList = () => {
	const { useGet } = useFetch(PathsAPI.Devices);
	const { request, response, loading, hasError } = useGet(true);
	const location = useLocation();
	const { user } = useAuthContext();
	const isGlobal = user.supplier === "GLOBAL";
	const isAdmin = "ADMIN" === user?.role ? true : false;

	useEffect(() => {
		if (location.state === stateMode.Refresh) {
			request();
			location.state = null;
		}
	}, [location, request]);

	let navigate = useNavigate();

	const useHandleLoadData = () => {
		request();
	};

	const handleOpenDevice = ({ _id }) => {
		navigate(_id);
	};

	useEffect(() => {
		toast.error(hasError);
	}, [hasError]);

	return (
		<div className={styles.main}>
			<div className={styles.panelContainer}>
				<div className={styles.header}>
					<h2>Lista urządzeń</h2>
					<div>
						<Button loading={loading} error={hasError} onClick={useHandleLoadData}>
							Odśwież
						</Button>
					</div>
				</div>
				<TableComponent title="Lista urządzeń" headValues={devicesTable} bodyValues={response} error={hasError} rowAction={handleOpenDevice} />
			</div>
			<Outlet />
		</div>
	);
};

export default DevicesList;

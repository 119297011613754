import React from "react";
import styles from "styles/ProductQrCode.module.css";
import { useParams } from "react-router-dom";
import NavButtonLink from "components/NavButtonLink";
import Draggable from "react-draggable";
import stateMode from "constants/stateMode";
import { useRef } from "react";
import Barcode from "react-barcode";

const ProductQrCode = () => {
	const nodeRef = useRef(null);
	let params = useParams();

	const formatBarcode = (barcode) => {
		switch (barcode.length) {
			case 7:
				return "EAN8";
			case 12:
				return "EAN13";
			default:
				return "CODE128";
		}
	};

	return (
		<Draggable nodeRef={nodeRef} handle={`.${styles.header}`}>
			<div ref={nodeRef} className={styles.container}>
				<div className={styles.header}>
					<h2>Kod kreskowy produktu</h2>
					<NavButtonLink type="reset" to="../" state={stateMode.Refresh}>
						&#x2715;
					</NavButtonLink>
				</div>
				<div className={styles.form}>
					<div className={styles.qrCodeContainer}>
						<Barcode format={formatBarcode(params.barcode)} value={params.barcode} />
					</div>
				</div>
			</div>
		</Draggable>
	);
};

export default ProductQrCode;

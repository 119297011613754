import React from "react";
import { Navigate, Route } from "react-router-dom";
import AdminDashboard from "pages/Dashboard/Admin";
import ProductsList from "pages/Dashboard/Admin/Products/ProductsList";
import ProductQrCode from "pages/Dashboard/Admin/Products/ProductQrCode";
import OrdersList from "pages/Dashboard/Admin/Orders/OrdersList";
import OrderInfo from "pages/Dashboard/Admin/Orders/OrderInfo";
import VendingList from "pages/Dashboard/Admin/Vending/VendingList";
import NewVendingOffer from "pages/Dashboard/Admin/Vending/NewVendingOffer";
import VendingOrdersList from "pages/Dashboard/Admin/VendingOrders/VendingOrdersList";
import VendingOrderInfo from "pages/Dashboard/Admin/VendingOrders/VendingOrderInfo";
import ChangeOrderStatus from "pages/Dashboard/Admin/Orders/ChangeOrderStatus";
import DevicesList from "pages/Dashboard/Admin/Devices/DevicesList";
import DeviceInfo from "pages/Dashboard/Admin/Devices/DeviceInfo";
import ProductEdit from "pages/Dashboard/Admin/Products/ProductEdit";
import NewProduct from "pages/Dashboard/Admin/Products/NewProduct";
import ShopsList from "pages/Dashboard/Admin/Shops/ShopsList";
import ShopEdit from "pages/Dashboard/Admin/Shops/ShopEdit";
import ChangeOrdersStatus from "pages/Dashboard/Admin/Orders/ChangeOrdersStatus";
import VendingSetDiscount from "pages/Dashboard/Admin/Vending/VendingSetDiscount";

const DelivererRoutes = () => {
	return (
		<Route path="dashboard" element={<AdminDashboard />}>
			<Route index element={<Navigate to="orders" />} />

			<Route path="orders" element={<OrdersList />}>
				<Route path="change-status" element={<ChangeOrdersStatus />}></Route>
			</Route>
			<Route path="orders/:id" element={<OrderInfo />}>
				<Route path="change-status" element={<ChangeOrderStatus />}></Route>
			</Route>

			<Route path="vending-orders" element={<VendingOrdersList />}></Route>
			<Route path="vending-orders/:id" element={<VendingOrderInfo />}></Route>

			<Route path="vending" element={<VendingList />}>
				<Route path="create" element={<NewVendingOffer />} />
				<Route path=":offerId/set-discount" element={<VendingSetDiscount />} />
			</Route>

			<Route path="devices" element={<DevicesList />} />
			<Route path="devices/:id" element={<DeviceInfo />} />

			<Route path="products" element={<ProductsList />}>
				<Route path=":id/edit" element={<ProductEdit />} />
				<Route path=":id/barcode/:barcode" element={<ProductQrCode />} />
				<Route path="create" element={<NewProduct />} />
			</Route>

			<Route path="shops" element={<ShopsList />}>
				<Route path=":id/edit" element={<ShopEdit />} />
			</Route>
		</Route>
	);
};

export default DelivererRoutes;

import React from "react";
import styles from "styles/DeviceEdit.module.css";
import { useFetch } from "hooks/useFetch";
import PathsAPI from "constants/PathsAPI";
import { useNavigate, useParams } from "react-router-dom";
import Button from "components/Button";
import NavButtonLink from "components/NavButtonLink";
import { useEffect, useMemo, useRef, useState } from "react";
import Draggable from "react-draggable";
import { AttributionControl, MapContainer, Marker, TileLayer } from "react-leaflet";
import "styles/leaflet.css";
import iconUrl from "assets/marker-icon.png";
import iconShadowUrl from "leaflet/dist/images/marker-shadow.png";
import { icon } from "leaflet";
import { OpenStreetMapProvider } from "react-leaflet-geosearch";
import stateMode from "constants/stateMode";
import CenterToCords from "components/leaflet/CenterToCords";

const DeviceEdit = () => {
	const nodeRef = useRef(null);
	let params = useParams();
	const { useGet, usePut, useDelete } = useFetch(`${PathsAPI.Devices}/${params.id}`);
	const { response: deviceInfo } = useGet(true);
	const { request, response, loading, hasError } = usePut();
	const { request: delReq, response: delRes, loading: delLoading, hasError: delHasError } = useDelete();
	const [markerPosition, setMarkerPosition] = useState(false);
	const [address, setAddress] = useState(false);
	let navigate = useNavigate();

	const prov = useMemo(() => {
		return OpenStreetMapProvider();
	}, []);

	useEffect(() => {
		if (markerPosition) {
			return;
		}

		if (!deviceInfo?.locatedAt) {
			return;
		}

		if (deviceInfo?.locatedAt !== address) {
			setAddress(deviceInfo?.locatedAt);
		}
	}, [markerPosition, address, deviceInfo?.locatedAt]);

	useEffect(() => {
		address &&
			prov.search({ query: address }).then((results) => {
				const { x, y } = results[0];
				const coords = [y, x];
				setMarkerPosition(coords);
			});
	}, [prov, address]);

	const handleChangeAddress = (e) => {
		setAddress(e.target.value);
	};

	const handleSubmit = (e) => {
		e.preventDefault();
		const formData = new FormData(e.target);
		request({
			name: formData.get("name"),
			IPAddress: formData.get("IPAddress"),
			locatedAt: formData.get("locatedAt"),
			coords: { lat: markerPosition[0], lng: markerPosition[1] },
		});
	};

	const handleDelete = () => {
		delReq();
	};

	useEffect(() => {
		(delRes?._id || response?._id) && navigate("../", { state: stateMode.Refresh });
	}, [delRes, response, navigate]);

	return (
		<Draggable nodeRef={nodeRef} handle={`.${styles.header}`}>
			<div ref={nodeRef} className={styles.container}>
				<div className={styles.header}>
					<h2>Edytuj dane urządzenia</h2>
					<NavButtonLink type="reset" to="../" state={stateMode.Refresh}>
						&#x2715;
					</NavButtonLink>
				</div>
				<form className={styles.form} onSubmit={handleSubmit}>
					<fieldset>
						<legend>Nazwa</legend>
						<input required type="text" name="name" placeholder="Podaj nazwę urządzenia .." defaultValue={deviceInfo?.name} />
					</fieldset>

					<fieldset>
						<legend>Adres IP</legend>
						<input required type="text" name="IPAddress" placeholder="Podaj adres IP .." defaultValue={deviceInfo?.IPAddress} />
					</fieldset>

					<fieldset>
						<legend>Lokalizacja</legend>
						<input
							required
							type="text"
							name="locatedAt"
							placeholder="Podaj lokalizację urządzenia .."
							onChange={handleChangeAddress}
							defaultValue={deviceInfo?.locatedAt}
						/>
					</fieldset>

					<MapContainer attributionControl={false} center={[51.72513413988088, 17.399333257415663]} zoom={13} className={styles.mapContainer}>
						<TileLayer
							attribution='<a target="_blank" href="https://leafletjs.com/">Leaflet</a> | &copy; Autorzy <a target="_blank" href="https://www.openstreetmap.org/copyright">OpenStreetMap</a>'
							url="https://tile.openstreetmap.org/{z}/{x}/{y}.png"
							noWrap={true}
							bounds={[
								[-90, -180],
								[90, 180],
							]}
						/>

						<AttributionControl position="bottomright" prefix={false} />

						<CenterToCords coords={markerPosition || [51.72513413988088, 17.399333257415663]} />

						{markerPosition && (
							<Marker
								position={markerPosition}
								icon={icon({
									iconUrl: iconUrl,
									shadowUrl: iconShadowUrl,
									iconSize: [25, 41],
									iconAnchor: [13, 40],
								})}
							/>
						)}
					</MapContainer>

					{hasError && <div className="error">{hasError}</div>}
					{delHasError && <div className="error">{delHasError}</div>}

					<div className={styles.buttons}>
						{/* <Button type="reset" loading={delLoading} onClick={handleDelete}>
							Usuń
						</Button> */}
						<Button type="submit" loading={loading}>
							Zapisz zmiany
						</Button>
					</div>
				</form>
			</div>
		</Draggable>
	);
};

export default DeviceEdit;

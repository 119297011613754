import React from "react";

const viewActionText = (offer, devicesList, selectedDevice) => {
	const { boxId } = offer;
	const device = devicesList.find((device) => device._id === selectedDevice);

	if (!device) {
		return;
	}

	try {
		if (!device.boxes[boxId].vendingOfferId) {
			return "Dodaj ofertę do urządzenia";
		}

		if (offer.endOfferLife.enableOption) {
			const endOfferLife = new Date(offer.placedAt);
			endOfferLife.setDate(endOfferLife.getDate() + offer.endOfferLife.daysUntilDisabled);

			if (new Date() > endOfferLife) {
				return <b style={{ color: "var(--error-color)" }}>Oferta wygasła, wyjmij ofertę</b>;
			}
		}

		return "Zajęta na potrzeby zamówienia";
	} catch (error) {
		return "Status nieznany";
	}
};

const vendingTable = {
	offerName: {
		name: "Nazwa oferty",
		mobile: false,
	},
	boxId: {
		name: "Numer skrytki",
		mobile: false,
	},
	createdAt: {
		name: "Data dodania",
		mobile: false,
		isDate: true,
		filter: (value) => {
			return new Date(value);
		},
		parser: (value) => {
			const date = new Date(value).toLocaleString().split(", ");
			return (
				<>
					{date[0]}
					<br />
					{date[1]}
				</>
			);
		},
	},
	updatedAt: {
		name: "Data aktualizacji",
		mobile: false,
		isDate: true,
		filter: (value) => {
			return new Date(value);
		},
		parser: (value) => {
			const date = new Date(value).toLocaleString().split(", ");
			return (
				<>
					{date[0]}
					<br />
					{date[1]}
				</>
			);
		},
	},
	placedAt: {
		name: "Data złożenia",
		mobile: false,
		isDate: true,
		filter: (value) => {
			return new Date(value);
		},
		parser: (value) => {
			if (!value) {
				return "-";
			}

			const date = new Date(value).toLocaleString().split(", ");
			return (
				<>
					{date[0]}
					<br />
					{date[1]}
				</>
			);
		},
	},
	action: {
		name: "Akcja",
		mobile: false,
		parser: (value, { row, devicesList, selectedDevice }) => {
			if (!devicesList || !selectedDevice || row.deviceId !== selectedDevice) {
				return "Ładowanie ..";
			}
			return viewActionText(row, devicesList, selectedDevice);
		},
	},
	offerNetto: {
		name: "Cena netto (PLN)",
		mobile: false,
	},
	offerBrutto: {
		name: "Cena brutto (PLN)",
		mobile: false,
	},
	orderCounter: {
		name: "Ilość transakcji",
		mobile: false,
		parser: (value, { row }) => {
			if (new Date(row.updatedAt) < new Date("2023-11-28T12:00:00.000Z")) {
				return "-";
			}

			return value;
		},
	},
};

export default vendingTable;

import React from "react";
import { Link, Outlet } from "react-router-dom";
import { useAuthContext } from "hooks/useAuthContext";
import styles from "styles/Navbar.module.css";
import NavButtonLink from "./NavButtonLink";
import { ReactComponent as FoodBoxIcon } from "assets/lemon.svg";
import Icon from "@mdi/react";
import Button from "./Button";
import { mdiAccountWrench, mdiLogout, mdiMenu, mdiPackageCheck, mdiSecurity } from "@mdi/js";
import actionType from "constants/actionTypes";

const Navbar = () => {
	const { user, menu, dispatch } = useAuthContext();

	const isAdmin = user.role === "ADMIN";
	const isGlobal = user.supplier === "GLOBAL";
	const isDeliverer = user.role === "DELIVERER";

	const userRoleViewer = (object) => {
		if (isGlobal) {
			return object.global;
		}

		if (isAdmin) {
			return object.admin;
		}

		if (isDeliverer) {
			return object.deliverer;
		}
	};

	const handleViewMainMenu = () => {
		dispatch({ type: actionType.VIEW_MENU, payload: !menu });
	};

	return (
		<>
			<div className={styles.container}>
				<div className={styles.background}>
					<FoodBoxIcon name="top-left" />
					<FoodBoxIcon name="bottom-left" />
					<FoodBoxIcon name="bottom-right" />
					<FoodBoxIcon name="center-1" />
					<FoodBoxIcon name="center-2" />
					<FoodBoxIcon name="center-3" />
				</div>
				<div className={styles.navbar}>
					<h2 style={{ display: "inline-flex", alignItems: "center" }}>
						<Button
							onClick={handleViewMainMenu}
							style={{
								marginRight: "1em",
								display: "inline-flex",
								alignItems: "center",
								justifyContent: "center",
								background: menu ? "" : "var(--warning-color)",
							}}
						>
							<Icon path={mdiMenu} size={1} />
						</Button>
						<Icon path={userRoleViewer({ admin: mdiAccountWrench, deliverer: mdiPackageCheck, global: mdiSecurity })} size={2} />
					</h2>
					<div className={styles.mainIcon}>
						<FoodBoxIcon />
						<span className={styles.iconText}>FoodBox</span>
					</div>
					<nav>
						{user?.role ? (
							<div>
								<div className={styles.username}>{user.fname}</div>
								<span title="Wyloguj się">
									<NavButtonLink to="/logout" className={styles.logoutButton}>
										<Icon path={mdiLogout} size={1} />
									</NavButtonLink>
								</span>
							</div>
						) : (
							<div>
								<Link to="/login">
									<button>Logowanie</button>
								</Link>
							</div>
						)}
					</nav>
				</div>
				<div className={styles.main}>
					<Outlet />
				</div>
			</div>
			<div className={styles.footer}>
				<Link to="/">
					<h3>foodBOX {new Date().getFullYear()}</h3>
				</Link>
			</div>
		</>
	);
};

export default Navbar;

import React, { useEffect } from "react";
import { useFetch } from "hooks/useFetch";
import PathsAPI from "constants/PathsAPI";
import styles from "styles/Devices.module.css";
import { Outlet, useLocation } from "react-router-dom";
import Button from "components/Button";
import { mdiBarcodeScan, mdiNoteEdit } from "@mdi/js";
import NavButtonLink from "components/NavButtonLink";
import stateMode from "constants/stateMode";
import { useAuthContext } from "hooks/useAuthContext";
import TableComponent from "components/Table";
import productTable from "constants/tables/productTable";

const ProductsList = () => {
	const { user } = useAuthContext();
	const { useGet } = useFetch(PathsAPI.Products);
	const { request, response, loading, hasError } = useGet(true);
	const location = useLocation();

	const isAdmin = "ADMIN" === user?.role ? true : false;
	const isGlobal = "GLOBAL" === user?.supplier ? true : false;

	useEffect(() => {
		if (location.state === stateMode.Refresh) {
			request();
			location.state = null;
		}
	}, [location, request]);

	const useHandleLoadData = () => {
		request();
	};

	const productTableActions = [
		{
			type: "edit",
			name: "Edytuj",
			icon: mdiNoteEdit,
			navigate: (value) => {
				return `${value._id}/edit`;
			},
		},
		{
			type: "barcode",
			name: (
				<>
					Kod
					<br />
					kreskowy
				</>
			),
			viewRowValue: (value) => {
				return Boolean(value.barcode);
			},
			icon: mdiBarcodeScan,
			navigate: (value) => {
				return `${value._id}/barcode/${value.barcode}`;
			},
		},
	];

	return (
		<div className={styles.main}>
			<div className={styles.panelContainer}>
				<div className={styles.header}>
					<h2>Lista produktów</h2>
					<div>
						{isAdmin && isGlobal && <NavButtonLink to="category">Zarządzaj kategoriami</NavButtonLink>}
						<NavButtonLink to="create">Dodaj nowy produkt</NavButtonLink>
						<Button loading={loading} error={hasError} onClick={useHandleLoadData}>
							Odśwież
						</Button>
					</div>
				</div>
				<TableComponent title="Lista produktów" headValues={productTable} bodyValues={response} error={hasError} actions={productTableActions} />
			</div>
			<Outlet />
		</div>
	);
};

export default ProductsList;

import React, { useCallback, useEffect, useRef, useState } from "react";
import styles from "styles/DeviceInfo.module.css";
import tableStyles from "styles/Table.module.css";
import Button from "components/Button";
import NavButtonLink from "components/NavButtonLink";
import { useFetch } from "hooks/useFetch";
import PathsAPI from "constants/PathsAPI";
import { Outlet, useLocation, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import stateMode from "constants/stateMode";
import { ReactSVG } from "react-svg";
import NavButtonIcon from "components/NavButtonIcon";
import Icon from "@mdi/react";
import { mdiSquareEditOutline } from "@mdi/js";
import getDeviceImage from "constants/getDeviceImage";
import { DateTimePicker } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import locale from "date-fns/locale/pl";
import { sendTelemetry } from "components/ErrorBoundary";
import { useAuthContext } from "hooks/useAuthContext";

const currentDateTimeStatusType = "CURRENT_DATE_TIME_STATUS";

const DeviceInfo = () => {
	let params = useParams();
	const { useGet: useGetBasicData } = useFetch(PathsAPI.DeviceBasic(params.id));
	const { request: reqBasicData, response: resBasicData } = useGetBasicData(true);

	const { useGet } = useFetch(PathsAPI.DeviceStatus(params.id));
	const { response: deviceInfo, request: refreshData, loading: loadingData, hasError: hasDataError } = useGet(true);

	const { usePost: useRelayPost } = useFetch(PathsAPI.DeviceRelay(params.id));
	const { request: reqRelay } = useRelayPost();

	const { useGet: useSuppliersGet } = useFetch(PathsAPI.Suppliers);
	const { response: suppliersList } = useSuppliersGet(true);

	const [requestRefresh, setRequestRefresh] = useState(false);

	const location = useLocation();

	const dateTimePickerRef = useRef();

	const [viewDateTimePicker, setViewDateTimePicker] = useState(false);

	const [selectedTimeDateStatus, setSelectedTimeDateStatus] = useState(currentDateTimeStatusType);

	const { user } = useAuthContext();
	const isGlobalAdmin = user.supplier === "GLOBAL" && "ADMIN" === user?.role ? true : false;

	const handleRefreshData = useCallback(async () => {
		if (!loadingData) {
			await reqBasicData();
			await refreshData({ force: true });
			setRequestRefresh(false);
		}
	}, [loadingData, reqBasicData, refreshData]);

	useEffect(() => {
		if (location.state === stateMode.Refresh) {
			handleRefreshData();
			location.state = null;
		}
	}, [location, reqBasicData, handleRefreshData]);

	const handleOpenLocker = async (boxId) => {
		const toastId = toast.loading(`Otwarcie skrytki ${boxId} ..`, {
			autoClose: 10000,
		});
		try {
			await reqRelay({ boxId });
			toast.update(toastId, {
				render: `Skrytka ${boxId} została otwarta`,
				type: "success",
				isLoading: false,
				closeOnClick: true,
				autoClose: 5000,
				closeButton: true,
			});
		} catch (error) {
			toast.update(toastId, {
				render: `Wystąpił problem z otwarciem skrytki ${boxId}`,
				type: "error",
				isLoading: false,
				closeOnClick: true,
				closeButton: true,
			});
		}

		setRequestRefresh(true);
	};

	const handleChangeOption = async (optionId, option) => {
		const toastId = toast.loading(`Zmiana stanu funkcji "${option.description}" ..`, { autoClose: 10000 });
		try {
			const oldStatus = deviceInfo.switchStatus[option.port]["readModule" in option ? option?.readModule : option.module][option.read];

			const activeBit = resBasicData?.configuration?.frameConfig?.[option.port]?.ESP32Mode ? 2 : 1;

			const { switchRelay } = await reqRelay({ optionId, forceStateNumber: oldStatus === 1 ? 0 : activeBit });

			deviceInfo.switchStatus[option.port]["readModule" in option ? option?.readModule : option.module][option.read] = switchRelay.data === 0 ? 0 : 1;
			toast.update(toastId, {
				render: `Stan funkcji "${option.description}" został zmieniony`,
				type: "success",
				isLoading: false,
				closeOnClick: true,
				autoClose: 5000,
				closeButton: true,
			});
		} catch (error) {
			toast.update(toastId, {
				render: `Wystąpił problem z zmianą stanu funkcji "${option.description}"`,
				type: "error",
				isLoading: false,
				closeOnClick: true,
				closeButton: true,
			});
		}

		setRequestRefresh(true);
	};

	const parseDeviceData = (type, port, data, device) => {
		if (!device) {
			return -1;
		}

		switch (type) {
			case "temp":
				const tempModule = device.tempStatus?.[port]?.[Number(`0x${data[0]}`)] || "ERROR";
				if (typeof tempModule === "string") {
					return "Błąd odczytu";
				}
				const temp = tempModule[Number(data[1])];
				return `${temp} °C`;
			case "door":
				const door = device.switchStatus?.[port]?.[Number(`0x${data[0]}`)]?.[Number(data[1])];
				if (door === 0) {
					return "Otwarte";
				} else if (door === 1) {
					return "Zamknięte";
				} else {
					return "Błąd odczytu";
				}
			case "relay":
				const relay = device.switchStatus?.[port]?.[Number(data.module)]?.[Number(data.read)];
				if (relay === 0) {
					return "Nieaktywne";
				} else if (relay === 1) {
					return "Aktywne";
				} else {
					return "Błąd odczytu";
				}
			default:
				return data;
		}
	};

	useEffect(() => {
		toast.error(hasDataError);
	}, [hasDataError]);

	const destinyParser = (destinyList) => {
		let result = [];

		suppliersList &&
			destinyList?.forEach((destiny) => {
				result.push(
					suppliersList.find((supplier) => {
						return supplier._id === destiny;
					})?.name
				);
			});

		return result;
	};

	const returnBoxRule = (rule) => {
		switch (rule) {
			case "NORMAL":
				return "Dostępna";
			case "BUSY":
				return "Zajęta";
			case "DISABLED":
				return "Wyłączona";
			default:
				return "Nieznany";
		}
	};

	const thermoOptions = resBasicData?.additionalOptions ? Object.entries(resBasicData.additionalOptions)?.filter(([optionId, option]) => option.thermo) : undefined;

	const getThermoType = (thermoType, name = false) => {
		if (name === "ICE") {
			return "Mroźny";
		}

		switch (thermoType) {
			case "WARM":
				return "Grzewczy";
			case "ICE":
				return "Chłodniczy";
			default:
				return "Błąd";
		}
	};

	const handleLoadDeviceImage = () => {
		const title = document.querySelector(`.${styles.deviceSVG} #title`);

		if (title) {
			let matrix = title.transform.baseVal.getItem(0).matrix;
			matrix.e -= 50;
			title.transform.baseVal.getItem(0).setMatrix(matrix);

			title.innerHTML = `Data odczytu : ${deviceInfo?.createdAt ? new Date(deviceInfo?.createdAt).toLocaleString() : "Ładowanie .."}`;
		}

		const boxes = document.querySelectorAll(`.${styles.deviceSVG} rect`);

		boxes.forEach((box) => {
			if (box.id === "" || box.id.includes("c")) {
				return;
			}

			const { boxType } = resBasicData?.boxes[box.id];

			switch (boxType) {
				case "COLD":
					box.style.fill = "#b8cd30";
					break;

				case "WARM":
					box.style.fill = "#ffb777";
					break;

				case "ICE":
					box.style.fill = "#add8e6";
					break;

				default:
					break;
			}
		});
	};

	const getAverageTemp = (boxes) => {
		if (!deviceInfo) {
			return;
		}

		const realBoxes = boxes.map((box) => resBasicData?.boxes[box]);
		const currentTemperatures = realBoxes.map((box) => Number(deviceInfo.tempStatus?.[box.port]?.[box.temp[0]]?.[box.temp[1]]));
		return (currentTemperatures.reduce((a, b) => a + b) / currentTemperatures.length).toFixed(1);
	};

	const getPorts = () => {
		if (!resBasicData) {
			return [];
		}

		return [...new Set([...Object.keys(resBasicData.configuration.switchModules), ...Object.keys(resBasicData.configuration.tempModules)])];
	};

	const handleChangeToCurrentStatus = async () => {
		setSelectedTimeDateStatus(currentDateTimeStatusType);
		await refreshData();
	};

	const handleChangeDateTimePicker = async (selectedDate, { validationError }) => {
		setSelectedTimeDateStatus(selectedDate);
		await refreshData({ searchedDate: selectedDate });
		if (validationError !== null) {
			sendTelemetry({ validationError });
			toast.error("Wybrana data jest nieprawidłowa");
		}
	};

	return (
		<div className={styles.main}>
			<div className={styles.panelContainer}>
				<div className={styles.header}>
					<h2>Szczegóły urządzenia</h2>
					<div className={styles.buttons}>
						{isGlobalAdmin && (
							<>
								<NavButtonLink to="destiny">Uprawnienia dostawców</NavButtonLink>
								<NavButtonLink to="configuration">Konfiguracja urządzenia</NavButtonLink>
								<NavButtonLink to="edit">Dane urządzenia</NavButtonLink>
							</>
						)}
						<Button onClick={handleRefreshData} loading={loadingData} refresh={requestRefresh} error={hasDataError}>
							Odśwież
						</Button>
					</div>
				</div>
				{isGlobalAdmin && (
					<div className={styles.spaceAround}>
						<div>
							<table className={tableStyles.table}>
								<tbody>
									<tr>
										<td>Identyfikator urządzenia</td>
										<td>{resBasicData?._id || "Ładowanie ..."}</td>
									</tr>
									<tr>
										<td>Nazwa</td>
										<td>{resBasicData?.name || "Ładowanie ..."}</td>
									</tr>
									<tr>
										<td>Adres IP</td>
										<td>{resBasicData?.IPAddress || "Ładowanie ..."}</td>
									</tr>
									<tr>
										<td>Użyte porty</td>
										<td>{getPorts().join(", ")}</td>
									</tr>
									<tr>
										<td>Położony w</td>
										<td>{resBasicData?.locatedAt || "Ładowanie ..."}</td>
									</tr>
									<tr>
										<td>Obsługiwani dostawcy</td>
										{resBasicData?.suppliers?.length > 0 ? (
											<td>{destinyParser(resBasicData?.suppliers).map((supplier) => <div key={supplier}>{supplier}</div>) || "Ładowanie ..."}</td>
										) : (
											<td
												style={{
													background: "var(--warning-color)",
													borderTopRightRadius: "8px",
												}}
											>
												Nie ustalono
											</td>
										)}
									</tr>
								</tbody>
							</table>
						</div>

						<div>
							<table className={tableStyles.table}>
								<tbody>
									<tr>
										<td>Tryb serwisowy</td>
										{resBasicData?.serviceMode ? (
											<td
												style={{
													background: "var(--warning-color)",
													borderTopRightRadius: "8px",
												}}
											>
												Aktywny
											</td>
										) : (
											<td>Nieaktywny</td>
										)}
									</tr>
									<tr>
										<td>Automatyczne światła</td>
										<td>{resBasicData?.autoLED ? "Aktywne" : "Nieaktywne"}</td>
									</tr>
									<tr>
										<td>Maksymalny czas oczekiwania na odpowiedź</td>
										<td>{resBasicData?.configuration?.timeout}ms</td>
									</tr>
									<tr>
										<td>Czas opóźnienia pomiędzy żądaniami do urządzenia</td>
										<td>{resBasicData?.configuration?.delay}ms</td>
									</tr>
									<tr>
										<td>Temperatura zewnętrzna</td>
										<td>
											{resBasicData?.configuration?.extTemp?.map(({ port, module: boxModule, index }, tempKey) => (
												<div key={tempKey}>{deviceInfo?.tempStatus?.[port]?.[Number(boxModule)]?.[Number(index)]} °C</div>
											))}
										</td>
									</tr>
								</tbody>
							</table>
						</div>
					</div>
				)}

				{isGlobalAdmin && thermoOptions?.length > 0 && (
					<table style={{ marginTop: 15 }} className={`${tableStyles.table} ${tableStyles.hover}`}>
						<thead style={{ position: "sticky", top: -32, background: "white" }}>
							<tr>
								<th>Funkcja</th>
								<th>Typ</th>
								<th>Stan termostatu</th>
								<th>Temp. min.</th>
								<th>Średnia temp.</th>
								<th>Temp. maks.</th>
								<th>Stan przekaźnika</th>
								<th>Edytuj</th>
							</tr>
						</thead>
						<tbody>
							{thermoOptions.map(([optionId, option]) => (
								<tr className={styles.tmpHover} key={optionId}>
									<td>
										<div>{option.description}</div>
									</td>
									<td>
										<div>{getThermoType(option.thermo.type, option.thermo?.name)}</div>
									</td>
									<td>
										<div>{option.thermo.active ? "Aktywny" : "Nieaktywy"}</div>
									</td>
									<td>
										<div>{option.thermo.min} °C</div>
									</td>
									<td>
										<div>{getAverageTemp(option.thermo.boxes)} °C</div>
									</td>
									<td>
										<div>{option.thermo.max} °C</div>
									</td>
									<td>
										<div>{parseDeviceData("relay", option.port, { module: "readModule" in option ? option?.readModule : option.module, read: option.read }, deviceInfo)}</div>
									</td>
									<td className={tableStyles.icon}>
										<NavButtonIcon to={`thermo/${optionId}`}>
											<Icon path={mdiSquareEditOutline} size={1} />
										</NavButtonIcon>
									</td>
								</tr>
							))}
						</tbody>
					</table>
				)}
			</div>

			<div className={styles.panelContainer}>
				<div style={{ display: "flex" }}>
					<div className={styles.deviceSettingsContainer}>
						<div className={styles.searchDateContainer}>
							<LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={locale}>
								<input
									type="text"
									placeholder="Sprawdź stan z dnia .."
									ref={dateTimePickerRef}
									value={
										selectedTimeDateStatus !== currentDateTimeStatusType
											? new Date(selectedTimeDateStatus).toLocaleString("pl", {
													day: "2-digit",
													month: "2-digit",
													hour: "2-digit",
													minute: "2-digit",
											  })
											: ""
									}
									onClick={() => {
										setViewDateTimePicker(!viewDateTimePicker);
									}}
									className={styles.dateTimePicker}
								/>
								<DateTimePicker
									open={viewDateTimePicker}
									onClose={() => {
										setViewDateTimePicker(false);
									}}
									slotProps={{
										popper: { anchorEl: dateTimePickerRef.current },
									}}
									maxDateTime={new Date()}
									onChange={handleChangeDateTimePicker}
									slots={{ field: () => <></> }}
								/>
							</LocalizationProvider>

							<Button className={selectedTimeDateStatus === currentDateTimeStatusType ? styles.activeCurrentStatus : ""} onClick={handleChangeToCurrentStatus}>
								Stan aktualny
							</Button>
						</div>

						<p className={styles.information}>*Stan skrytki nie jest dostępny dla odczytów historycznych</p>

						<table className={`${tableStyles.table} ${tableStyles.hover}`}>
							<thead style={{ position: "sticky", top: -32, background: "white" }}>
								<tr>
									<th>Numer skrytki</th>
									<th>Temperatura</th>
									<th>Stan zamka</th>
									<th>*Stan skrytki</th>
								</tr>
							</thead>
							<tbody>
								{resBasicData?.boxes &&
									Object.entries(resBasicData?.boxes).map(([index, box]) => (
										<tr
											className={styles.tmpHover}
											key={index}
											onClick={() => {
												handleOpenLocker(index);
											}}
										>
											<td>
												<div>{index}</div>
											</td>
											<td>
												<div>{box.temp === null ? "-" : parseDeviceData("temp", box.port, box.temp, deviceInfo)}</div>
											</td>
											<td>
												<div>{parseDeviceData("door", box.port, box.door, deviceInfo)}</div>
											</td>
											<td>
												<div>{selectedTimeDateStatus === currentDateTimeStatusType ? returnBoxRule(box.rule) : "-"}</div>
											</td>
										</tr>
									))}
							</tbody>
						</table>

						{isGlobalAdmin && resBasicData?.additionalOptions && (
							<table style={{ marginTop: 15 }} className={`${tableStyles.table} ${tableStyles.hover}`}>
								<thead style={{ position: "sticky", top: -32, background: "white" }}>
									<tr>
										<th>Funkcja</th>
										<th>Stan przekaźnika</th>
									</tr>
								</thead>
								<tbody>
									{Object.entries(resBasicData?.additionalOptions)
										?.filter(([_, option]) => !option?.hide)
										.map(([optionId, option]) => (
											<tr
												className={styles.tmpHover}
												key={optionId}
												onClick={() => {
													handleChangeOption(optionId, option);
												}}
											>
												<td>
													<div>{option.description}</div>
												</td>
												<td>
													<div>{parseDeviceData("relay", option.port, { module: "readModule" in option ? option?.readModule : option.module, read: option.read }, deviceInfo)}</div>
												</td>
											</tr>
										))}
								</tbody>
							</table>
						)}
					</div>

					{resBasicData?.configuration?.type && (
						<ReactSVG src={getDeviceImage(resBasicData.configuration.type)} className={styles.deviceSVG} alt="Device Image" afterInjection={handleLoadDeviceImage} />
					)}
				</div>
			</div>

			<Outlet />
		</div>
	);
};

export default DeviceInfo;

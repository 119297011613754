import React, { useEffect, useState } from "react";
import { createContext, useReducer } from "react";
import actionType from "constants/actionTypes";
import PathsAPI from "constants/PathsAPI";
import { toast } from "react-toastify";

export const AuthContext = createContext();

export const authReducer = (state, action) => {
	switch (action.type) {
		case actionType.LOGIN:
			return { ...state, user: action.payload };
		case actionType.LOGOUT:
			return { ...state, user: false };
		case actionType.VIEW_MENU:
			return { ...state, menu: action.payload };
		case actionType.SET_ORDER_DATE:
			localStorage.setItem("orderDate", action.payload);
			return { ...state, orderDate: action.payload };
		default:
			return state;
	}
};

export const AuthContextProvider = ({ children }) => {
	const [state, dispatch] = useReducer(authReducer, {
		user: null,
		menu: true,
		orderDate: new Date(localStorage.getItem("orderDate") || new Date()),
	});
	const [viewError, setViewError] = useState(false);

	useEffect(() => {
		if (!viewError) {
			return;
		}

		toast.error(viewError || "Wystąpił błąd podczas pobierania danych użytkownika");
	}, [viewError]);

	if (state.user === null) {
		fetch(PathsAPI.UserLogged).then(async (response) => {
			const jsonData = await response.json();

			if (response.ok && jsonData) {
				dispatch({
					type: actionType.LOGIN,
					payload: { supplier: jsonData.supplier, role: jsonData.role, fname: jsonData.fname, email: jsonData.email },
				});
				setViewError(false);
			} else {
				setViewError(jsonData.message || true);
				dispatch({ type: actionType.LOGIN, payload: false });
			}
		});
	}

	return <AuthContext.Provider value={{ ...state, dispatch }}>{children}</AuthContext.Provider>;
};

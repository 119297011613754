import React from "react";
import styles from "styles/DeviceConfig.module.css";
import { useFetch } from "hooks/useFetch";
import PathsAPI from "constants/PathsAPI";
import { useNavigate, useParams } from "react-router-dom";
import Button from "components/Button";
import NavButtonLink from "components/NavButtonLink";
import { useEffect, useRef } from "react";
import Draggable from "react-draggable";
import stateMode from "constants/stateMode";

const DeviceConfig = () => {
	const nodeRef = useRef(null);
	let params = useParams();

	const { useGet, usePut } = useFetch(`${PathsAPI.Devices}/${params.id}`);
	const { response: deviceInfo } = useGet(true);
	const { request, response, loading, hasError } = usePut();

	let navigate = useNavigate();

	const handleSubmit = (e) => {
		e.preventDefault();
		const formData = new FormData(e.target);

		request({
			serviceMode: (formData.get("serviceMode") || "off") === "on",
			autoLED: (formData.get("autoLED") || "off") === "on",
			"configuration.timeout": formData.get("timeout"),
			"configuration.delay": formData.get("delay"),
		});
	};

	useEffect(() => {
		response?._id && navigate("../", { state: stateMode.Refresh });
	}, [response, navigate]);

	return (
		<Draggable nodeRef={nodeRef} handle={`.${styles.header}`}>
			<div ref={nodeRef} className={styles.container}>
				<div className={styles.header}>
					<h2>Edytuj konfigurację urządzenia</h2>
					<NavButtonLink type="reset" to="../" state={stateMode.Refresh}>
						&#x2715;
					</NavButtonLink>
				</div>
				<form className={styles.form} onSubmit={handleSubmit}>
					<fieldset className={styles.checkbox}>
						<label htmlFor="serviceMode">Tryb serwisowy</label>
						<span />
						<input type="checkbox" name="serviceMode" id="serviceMode" defaultChecked={deviceInfo?.serviceMode} />
					</fieldset>

					<fieldset className={styles.checkbox}>
						<label htmlFor="autoLED">Automatyczne światła</label>
						<span />
						<input type="checkbox" name="autoLED" id="autoLED" defaultChecked={deviceInfo?.autoLED} />
					</fieldset>

					<fieldset>
						<legend>Maksymalny czas oczekiwania na odpowiedź (ms)</legend>
						<input
							required
							type="text"
							name="timeout"
							placeholder="Podaj wartość w milisekundach"
							defaultValue={deviceInfo?.configuration?.timeout}
						/>
					</fieldset>

					<fieldset>
						<legend>Czas opóźnienia pomiędzy żądaniami do urządzenia (ms)</legend>
						<input required type="text" name="delay" placeholder="Podaj wartość w milisekundach" defaultValue={deviceInfo?.configuration?.delay} />
					</fieldset>

					{hasError && <div className="error">{hasError}</div>}

					<div className={styles.buttons}>
						<Button type="submit" loading={loading}>
							Zapisz zmiany
						</Button>
					</div>
				</form>
			</div>
		</Draggable>
	);
};

export default DeviceConfig;

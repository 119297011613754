import React, { useEffect } from "react";
import { useFetch } from "hooks/useFetch";
import PathsAPI from "constants/PathsAPI";
import styles from "styles/Devices.module.css";
import { Outlet, useLocation } from "react-router-dom";
import Button from "components/Button";
import { mdiNoteEdit } from "@mdi/js";
import stateMode from "constants/stateMode";
import usersTable from "constants/tables/usersTable";
import TableComponent from "components/Table";
import { useAuthContext } from "hooks/useAuthContext";

const UsersList = () => {
	const { useGet } = useFetch(PathsAPI.Users);
	const { request, response, loading, hasError } = useGet(true);
	const location = useLocation();

	const { user } = useAuthContext();
	const isGlobal = user.supplier === "GLOBAL";

	const { useGet: useSuppliersGet } = useFetch(PathsAPI.Suppliers);
	const { response: suppliersList, loading: loadingSuppliersList } = useSuppliersGet(isGlobal);

	useEffect(() => {
		if (location.state === stateMode.Refresh) {
			request();
			location.state = null;
		}
	}, [location, request]);

	const useHandleLoadData = () => {
		request();
	};

	const usersTableActions = [
		{
			type: "edit",
			name: "Edytuj",
			icon: mdiNoteEdit,
			navigate: (row) => {
				return `${row._id}/edit`;
			},
		},
	];

	return (
		<div className={styles.main}>
			<div className={styles.panelContainer}>
				<div className={styles.header}>
					<h2>Lista użytkowników</h2>
					<div>
						<Button loading={loading} error={hasError} onClick={useHandleLoadData}>
							Odśwież
						</Button>
					</div>
				</div>
				<TableComponent
					title="Lista użytkowników"
					headValues={usersTable}
					bodyValues={response}
					error={hasError}
					actions={usersTableActions}
					additionalValues={{ suppliersList }}
					loadedAdditionalValues={[!loadingSuppliersList]}
				/>
			</div>
			<Outlet />
		</div>
	);
};

export default UsersList;

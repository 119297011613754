import React, { useState } from "react";
import styles from "styles/Settings.module.css";
import { Outlet, useNavigate } from "react-router-dom";
import Version from "pages/version";
import Icon from "@mdi/react";
import { mdiCancel } from "@mdi/js";
import RoleFunkcjeFoodbox from "assets/info/Role_i_funkcje_foodbox.png";
import Button from "components/Button";

const InformationList = () => {
	const navigate = useNavigate();

	const [viewOldKioskReleases, setViewOldKioskReleases] = useState(false);
	const [viewOldFoodBoxReleases, setViewOldFoodBoxReleases] = useState(false);

	return (
		<div className={styles.main}>
			<div className={styles.panelContainer}>
				<div className={styles.header}>
					<h2>Informacje</h2>
				</div>
				<Version />
			</div>
			{/* <div className={styles.panelContainer}>
				<div className={styles.header}>
					<h2>Planowane</h2>
				</div>
				<ul>
					<li style={{ fontSize: "32px" }}>
						<span>funkcje:</span>
						<ul>
							<li style={{ fontSize: "24px" }}>
								<span style={{ fontWeight: "bold" }}>system rabatów</span>
							</li>
						</ul>
					</li>
				</ul>
			</div> */}

			<div className={styles.panelContainer}>
				<div className={styles.header}>
					<h2>Lista zmian (FoodBox)</h2>
				</div>
				<ul>
					<li style={{ fontSize: "32px" }}>
						<span id="foodbox-3.0.0">3.0.0</span>
						<ul>
							<li>
								<span>Zmiany:</span>
								<ul>
									<li style={{ fontSize: "24px" }}>
										<span style={{ fontWeight: "bold" }}>Dodawanie produktów przez dostawców</span>
									</li>
									<li style={{ fontSize: "24px" }}>
										<span style={{ fontWeight: "bold" }}>Globalny dostawca może zmieniać status zamówienia na odebrany</span>
									</li>
									<li style={{ fontSize: "24px" }}>
										<span style={{ fontWeight: "bold" }}>Dostawca może zarządzać czasem dostać swoim i godzinami zamówień</span>
									</li>

									<li style={{ fontSize: "24px" }}>
										<span style={{ fontWeight: "bold" }}>Ograniczenie możliwości zamawiania, najwcześniej godzinę od momentu złożenia zamówienia</span>
									</li>

									<li style={{ fontSize: "24px" }}>
										<span style={{ fontWeight: "bold" }}>Sortowanie produktów po dacie dodania</span>
									</li>

									<li style={{ fontSize: "24px" }}>
										<span style={{ fontWeight: "bold" }}>Wyświetlanie voucherów z warunkiem, że są dostępne w danym urządzeniu</span>
									</li>

									<li style={{ fontSize: "24px" }}>
										<span style={{ fontWeight: "bold" }}>Aktualizacja cen produktów w vendingu (automat) bez konieczności ich wyjmowania</span>
									</li>
								</ul>
							</li>
						</ul>
					</li>

					{!viewOldFoodBoxReleases ? (
						<Button
							onClick={() => {
								setViewOldFoodBoxReleases(true);
							}}
						>
							Pokaż starsze zmiany
						</Button>
					) : (
						<>
							<Button
								onClick={() => {
									setViewOldFoodBoxReleases(false);
								}}
							>
								Ukryj starsze zmiany
							</Button>

							<li style={{ fontSize: "32px" }}>
								<span id="foodbox-2.5.0">2.5.0</span>
								<ul>
									<li>
										<span>Zmiany:</span>
										<ul>
											<li style={{ fontSize: "24px" }}>
												<span style={{ fontWeight: "bold" }}>Przystosowanie okna urządzenia dla widoku mobilnego</span>
											</li>
											<li style={{ fontSize: "24px" }}>
												<span style={{ fontWeight: "bold" }}>Wyświetlanie historycznych odczytów urządzenia</span>
											</li>
											<li style={{ fontSize: "24px" }}>
												<span style={{ fontWeight: "bold" }}>Refaktoryzacja ofert vendingowych</span>
											</li>
										</ul>
									</li>
								</ul>
							</li>
							<li style={{ fontSize: "32px" }}>
								<span id="foodbox-2.2.0">2.2.0</span>
								<ul>
									<li>
										<span>Zmiany:</span>
										<ul>
											<li style={{ fontSize: "24px" }}>
												<span style={{ fontWeight: "bold" }}>Zmiana adresu "najbliżej ciebie"</span>
											</li>
											<li style={{ fontSize: "24px" }}>
												<span style={{ fontWeight: "bold" }}>Powiadomienia - dopisanie lokalizacji urządzenia</span>
											</li>
											<li style={{ fontSize: "24px" }}>
												<span style={{ fontWeight: "bold" }}>Licznik transakcji do ofert vendingowych</span>
											</li>
										</ul>
									</li>
								</ul>
							</li>
							<li style={{ fontSize: "32px" }}>
								<span id="foodbox-2.0.0">2.0.0</span>
								<ul>
									<li>
										<span>Nowe funkcje:</span>
										<ul>
											<li style={{ fontSize: "24px" }}>
												<span style={{ fontWeight: "bold" }}>Minimalny koszyk na dostawce</span>
												<ul>
													Edytując dostawcę można podać minimalną wartość koszyka, przy której można złożyć zamówienie.
													<br />
													Jeśli warunek nie zostanie spełniony, zostanie wyświetlony komunikat, ile jeszcze brakuje do minimalnej wartości. A produkty danego dostawcy zostaną podświetlone na
													pomarańczowo.
												</ul>
											</li>
											<li style={{ fontSize: "24px" }}>
												<span style={{ fontWeight: "bold" }}>Refaktoryzacja systemu zamawiania</span>
												<ul>zapis koszyka po stronie API</ul>
												<ul>oznaczenie produktów niedostępnych na czerwono w koszyku, jeśli wybrana została inna data</ul>
												<ul>sprawdzanie dostępności po stronie API</ul>
												<ul>zabezpieczenie całego routingu z powiadomieniem na maila w razie błędu</ul>
												<ul>przepisanie kluczowych funkcji zamawiania do klas</ul>
												<ul>odczytywanie daty z serwera, zamiast daty użytkownika</ul>
											</li>
										</ul>
									</li>
								</ul>
							</li>
							<li style={{ fontSize: "32px" }}>
								<span id="foodbox-1.2.0">1.2.0</span>
								<ul>
									<li>
										<span>Nowe funkcje:</span>
										<ul>
											<li style={{ fontSize: "24px" }}>
												<span style={{ fontWeight: "bold" }}>Maksymalny czas odbioru zamówienia</span>
											</li>
										</ul>
									</li>
								</ul>
							</li>
							<li style={{ fontSize: "32px" }}>
								<span id="foodbox-1.1.1">1.1.1</span>
								<ul>
									<li>
										<span>Nowe funkcje:</span>
										<ul>
											<li style={{ fontSize: "24px" }}>
												<span style={{ fontWeight: "bold" }}>Vouchery</span>
												<div>
													<h1>Instrukcja dodawania voucherów:</h1>
													<p>A. Panel administratora:</p>
													<ol>
														<li>
															<p>Dodać w zakładce &quot;Produkty&quot; nowy produkt:</p>
															<ul>
																<li>Jego cena musi być równa 0 zł</li>
																<li>Status nieaktywny (odznaczony checkbox)</li>
															</ul>
														</li>
														<li>
															<p>Dodać w zakładce &quot;Vouchery&quot; nowy voucher:</p>
															<ul>
																<li>podać nazwę (jest to nazwa robocza tylko dla admina)</li>
																<li>wybrać użytkowników uprawnionych do skorzystania</li>
																<li>wybrać wcześniej przygotowany produkt</li>
															</ul>
														</li>
														<li>
															<p>Przejść do edycji vouchera i zmienić status na aktywny</p>
														</li>
													</ol>
													<p>B. Panel klienta:</p>
													<p>Voucher będzie dodany do listy produktów, zamiast ceny będzie napis &quot;Voucher&quot;</p>
													<p>Dostępny będzie z 2 miejsc:</p>
													<pre>
														<code>
															<span className="hljs-selector-tag">menu</span> -&gt; Vouchery
														</code>
													</pre>
													<p>lub</p>
													<pre>
														<code>
															menu -&gt; <span className="hljs-string">"Dostawca"</span> -&gt; Vouchery
														</code>
													</pre>
													<p>Zamiast ikony + jest koszyk, po kliknięciu automatycznie kieruje do koszyka</p>
													<p>
														UWAGA Jeśli klient użyje voucher w zamówieniu, automatycznie zostanie usunięty z listy uprawnionych użytkowników, do jego wykorzystania. Aby podarować voucher jeszcze raz,
														należy przeprowadzić edycję vouchera, po czym wybrać danego użytkownika jeszcze raz.
													</p>
												</div>
											</li>
										</ul>
									</li>
								</ul>
							</li>
							<li style={{ fontSize: "32px" }}>
								<span id="foodbox-1.0.5">1.0.5</span>
								<ul>
									<li>
										<span>Nowe funkcje:</span>
										<ul>
											<li style={{ fontSize: "24px" }}>
												<span style={{ fontWeight: "bold" }}>Rozdzielenie zamówień</span>
												<ul>
													<li>Możliwość odbioru części zamówienia</li>
												</ul>
											</li>
										</ul>
									</li>
									<li>
										<span>Poprawki:</span>
										<ul>
											<li style={{ fontSize: "24px" }}>
												<span style={{ fontWeight: "bold" }}>Dostępność i czas odbioru zamówienia</span>
											</li>
										</ul>
									</li>
								</ul>
							</li>
							<li style={{ fontSize: "32px" }}>
								<span id="foodbox-1.0.0">1.0.0</span>
								<ul>
									<li>
										<span>Nowe funkcje:</span>
										<ul>
											<li style={{ fontSize: "24px" }}>
												<span style={{ fontWeight: "bold" }}>Zmiany graficzne</span>
												<ul>
													<li>
														<span style={{ fontWeight: "bold" }}>Komputerowa</span>
														<ul>
															<li>Strona startowa</li>
															<li>Jak to działa?</li>
															<li>Zamów teraz</li>
															<li>Mapa</li>
															<li>Produkty</li>
															<li>Koszyk</li>
															<li>Zamówienia</li>
															<li>Moje konto</li>
															<li>Zmiana hasła</li>
															<li>Pomoc</li>
															<li>Logowanie</li>
															<li>Rejestracja</li>
															<li>Pasek nawigacyjny</li>
														</ul>
													</li>

													<li>
														<span style={{ fontWeight: "bold" }}>Mobilna</span>
														<ul>
															<li>Zmiana hasła</li>
															<li>Pomoc</li>
															<li>Produkty</li>
															<li>Koszyk</li>
															<li>Kategorie</li>
														</ul>
													</li>
													<li style={{ fontSize: "24px" }}>
														<span style={{ fontWeight: "bold" }}>Wyszukiwanie na mapie</span>
													</li>
												</ul>
											</li>
										</ul>
									</li>
									<li>
										<span>Poprawki:</span>
										<ul>
											<li style={{ fontSize: "24px" }}>
												<span style={{ fontWeight: "bold" }}>Wysyłanie SMS o dostarczeniu zamówienia</span>
											</li>
											<li style={{ fontSize: "24px" }}>
												<span style={{ fontWeight: "bold" }}>Usprawnienia klienta TCP (Komunikacja z urządzeniem)</span>
											</li>
										</ul>
									</li>
								</ul>
							</li>

							<li style={{ fontSize: "32px" }}>
								<span id="foodbox-0.34.0">0.34.0</span>
								<ul>
									<li>
										<span>Nowe funkcje:</span>
										<ul>
											<li style={{ fontSize: "24px" }}>
												<span style={{ fontWeight: "bold" }}>Zmiany graficzne</span>
												<ul>
													<li>Zmieniony wygląd dostępny tylko na urządzeniach mobilnych</li>
												</ul>
											</li>

											<li style={{ fontSize: "24px" }}>
												<span style={{ fontWeight: "bold" }}>Maksymalna godzina przyjmowania zamówień</span>
												<ul>
													<li>Konfigurowana per dostawca</li>
													<li>Zamówienia złożone przed tą godziną mogą być dostarczone tego samego dnia, w innym przypadku, w dniu kolejnym</li>
												</ul>
											</li>
										</ul>
									</li>
									<li>
										<span>Poprawki:</span>
										<ul>
											<li style={{ fontSize: "24px" }}>
												<span style={{ fontWeight: "bold" }}>Komunikacja z urządzeniem</span>
												<ul>
													<li>Automatyczne włączanie/wyłączanie świateł (wymagane dodatkowe poprawki konfiguracji)</li>
												</ul>
											</li>
										</ul>
									</li>
								</ul>
							</li>

							<li style={{ fontSize: "32px" }}>
								<span id="foodbox-0.33.0">0.33.0</span>
								<ul>
									<li>
										<span>Nowe funkcje:</span>
										<ul>
											<li style={{ fontSize: "24px" }}>
												<span style={{ fontWeight: "bold" }}>Usprawnienia połączenia z urządzeniem</span>
												<ul>
													<li>Priorytety komend do urządzenia</li>
													<li>Ulepszona funkcja odświeżenia danych</li>
												</ul>
											</li>

											<li style={{ fontSize: "24px" }}>
												<span style={{ fontWeight: "bold" }}>Załączanie mat grzewczych oraz agregatów</span>
												<ul>
													<li>Konfiguracja dostępna w szczegółach urządzenia</li>
													<li>Termostat wylicza średnią temperaturę skrytek i porównuje do minimalnej i maksymalnej ustawionej wartości.</li>
													<li>Stan temperatury jest sprawdzany co 15 minut</li>
												</ul>
											</li>

											<li style={{ fontSize: "24px" }}>
												<span style={{ fontWeight: "bold" }}>Uruchamianie automatycznie światła (neony/ledy)</span>
												<ul>
													<li>jeśli towary są w urządzeniu to słupek się świeci, jeśli nie ma to gaśnie</li>
												</ul>
											</li>
										</ul>
									</li>
								</ul>
							</li>

							<li style={{ fontSize: "32px" }}>
								<span id="foodbox-0.32.0">0.32.0</span>
								<ul>
									<li>
										<span>Poprawki bezpieczeństwa:</span>
										<ul>
											<li style={{ fontSize: "24px" }}>
												<span style={{ fontWeight: "bold" }}>Zmiana sposobu autoryzacji</span>
												<ul>
													<li>Autoryzacja na 2 tokenach JWT (zamiast jednego)</li>
													<li>Zmiana lokalizacji tokenów</li>
												</ul>
											</li>
										</ul>
									</li>
								</ul>
							</li>

							<li style={{ fontSize: "32px" }}>
								<span id="foodbox-0.31.0">0.31.0</span>
								<ul>
									<li>
										<span>Nowe funkcje:</span>
										<ul>
											<li style={{ fontSize: "24px" }}>
												<span style={{ fontWeight: "bold" }}>Powiadomienia SMS</span>
												<ul>
													<li>Do klienta jak złoży zamówienie</li>
													<li>Do klienta jak zamówienie będzie do odbioru</li>
													<li>Do klienta jak zamówienie zostanie anulowane (odrzucone)</li>
													<li>Do dostawcy lokalnego jak spadnie zamówienie</li>
												</ul>
											</li>
											<li style={{ fontSize: "24px" }}>
												<span style={{ fontWeight: "bold" }}>Walidacja numeru telefonu</span>
												<ul>
													<li>Podczas rejestracji wymagane jest podanie prawidłowego numeru telefonu.</li>
												</ul>
											</li>
										</ul>
									</li>
									<li>
										<span>Poprawki:</span>
										<ul>
											<li style={{ fontSize: "24px" }}>
												<span style={{ fontWeight: "bold" }}>Kategorie dostawców</span>
												<ul>
													<li>Wybranie danego dostawcy dostosuje listę produktów, tylko do danego dostawcy.</li>
												</ul>
											</li>
										</ul>
									</li>
								</ul>
							</li>

							<li style={{ fontSize: "32px" }}>
								<span id="foodbox-0.30.1">0.30.1</span>
								<ul>
									<li>
										<span>Hotfix:</span>
										<ul>
											<li style={{ fontSize: "24px" }}>
												<span style={{ fontWeight: "bold" }}>Zaznaczanie skrytek urządzenia</span>
											</li>
										</ul>
									</li>
								</ul>
							</li>

							<li style={{ fontSize: "32px" }}>
								<span id="foodbox-0.30.0">0.30.0</span>
								<ul>
									<li>
										<span>Nowe Funkcje:</span>
										<ul>
											<li style={{ fontSize: "24px" }}>
												<span style={{ fontWeight: "bold" }}>Zwroty środków</span>
												<ul>
													<li>
														W szczegółach zamówienia po zmianie statusu na <i>Odrzucone</i> dostępna jest opcja zwrotu środków.
														<br />
														Po udanym zwrocie status zamówienia zmieni się na <i>Płatność zwrócona</i>.
														<br />
														<b style={{ color: "var(--warning-color)" }}>UWAGA</b>
														<br />
														1. Zwrócenie płatności zablokuje możliwość edycji zamówienia.
														<br />
														2. Zwrot jest możliwy tylko dla zamówień, które zostały opłacone.
														<br />W innym przypadku pojawi się komunikat z błędem.
													</li>
												</ul>
											</li>
										</ul>
									</li>
									<li>
										<span>Poprawki:</span>
										<ul>
											<li style={{ fontSize: "24px" }}>
												<span style={{ fontWeight: "bold" }}>Informacja o dostępności produktu</span>
												<ul>
													<li>
														Zmiana tekstu szarfy z <i>dostępny od</i>, na <i>dostępny w</i>
													</li>
												</ul>
											</li>
											<li style={{ fontSize: "24px" }}>
												<span style={{ fontWeight: "bold" }}>Lista dostawców po stronie klienta</span>
												<ul>
													<li>
														Uwzględnienie opcji <i>Aktywny</i> dla produktów.
													</li>
												</ul>
											</li>
										</ul>
									</li>
								</ul>
							</li>

							<li style={{ fontSize: "32px" }}>
								<span id="foodbox-0.29.1">0.29.1</span>
								<ul>
									<li>
										<span>Nowe funkcje:</span>
										<ul>
											<li style={{ fontSize: "24px" }}>
												<span style={{ fontWeight: "bold" }}>Edycja kategorii produktów</span>
												<ul>
													<li>
														Funkcja dostępna w zakładce <i>Produkty</i>, pod przyciskiem <i>Zarządzaj kategoriami</i>,
														<br />
														tylko dla administratora globalnego.
													</li>
												</ul>
											</li>
										</ul>
									</li>
									<li>
										<span>Poprawki:</span>
										<ul>
											<li style={{ fontSize: "24px" }}>
												<span style={{ fontWeight: "bold" }}>Lista dostawców po stronie klienta</span>
												<ul>
													<li>Gdy dostawca nie ma przypisanych produktów, nie będzie się wyświetlał po stronie klienta w liście kategorii.</li>
												</ul>
											</li>
											<li style={{ fontSize: "24px" }}>
												<span style={{ fontWeight: "bold" }}>Informacja o dostępności produktu</span>
												<ul>
													<li>
														Jeśli produkt jest dostępny od dnia X, pojawi się szarfa w lewy górnym rogu.
														<br />
														(Zamiast tekstu na dole karty produktu)
													</li>
												</ul>
											</li>
											<li style={{ fontSize: "24px" }}>
												<span style={{ fontWeight: "bold" }}>Skalowanie obrazów (Opis produktów po stronie klienta)</span>
												<ul>
													<li>
														Od teraz maksymalna szerokość obrazu to 300px + zachowanie proporcji 3/2.
														<br />W przypadku innych proporcji, obraz jest środkowany, a pozostała część zostaje przycinana.
													</li>
												</ul>
											</li>
											<li style={{ fontSize: "24px" }}>
												<span style={{ fontWeight: "bold" }}>Losowe zaokrąglanie kwot przy dodawaniu/edycji produktów</span>
												<ul>
													<li>
														Prawdopodobny problem z implementacją pól numerycznych po stronie przeglądarki.
														<br />
														Od teraz pola te są traktowane jako tekst.
														<br />
														Usuwa to suwak regulacji kwoty oraz zmianę kwoty strzałkami.
														<br />
														Pozostała funkcjonalność się nie zmienia.
													</li>
												</ul>
											</li>
										</ul>
									</li>
								</ul>
							</li>

							<li style={{ fontSize: "32px" }}>
								<span id="foodbox-0.29.0">0.29.0</span>
								<ul>
									<li>
										<span>Nowe funkcje:</span>
										<ul>
											<li style={{ fontSize: "24px" }}>
												<span style={{ fontWeight: "bold" }}>Rozwijane menu po stronie klienta</span>
												<ul>
													<li>Aby rozwinąć kategorie danego dostawcy, należy kliknąć jego zakładkę.</li>
												</ul>
											</li>
											<li style={{ fontSize: "24px" }}>
												<span style={{ fontWeight: "bold" }}>Uwagi do zamówienia</span>
												<ul>
													<li>
														Przy podsumowaniu zamówienia można wpisać uwagi do zamówienia,
														<br />
														można je potem zobaczyć w szczegółach zamówienia oraz w mailu.
													</li>
												</ul>
											</li>
											<li style={{ fontSize: "24px" }}>
												<span style={{ fontWeight: "bold" }}>Dostępność produktu jako dodatkowy checkbox</span>
												<ul>
													<li>
														Produkt posiada dodatkowy checkbox, od którego zależy,
														<br />
														czy ma się wyświetlać na liście produktów po stronie klienta.
													</li>
												</ul>
											</li>
											<li style={{ fontSize: "24px" }}>
												<span style={{ fontWeight: "bold" }}>Kategorie produktów jako lista</span>
												<ul>
													<li>
														Kategorie produktów są dostępne jako lista,
														<br />
														zamiast wcześniejszego wpisywania nazwy kategorii.
													</li>
												</ul>
											</li>
										</ul>
									</li>
									<li>
										<span>Poprawki:</span>
										<ul>
											<li style={{ fontSize: "24px" }}>
												<span style={{ fontWeight: "bold" }}>Dodawanie nowych produktów</span>
												<ul>
													<li>Zmiana domyślnej wartości dostępności na odznaczone wszystkie wartości (wcześniej zaznaczony cały tydzień).</li>
												</ul>
											</li>
										</ul>
									</li>
								</ul>
							</li>

							<li style={{ fontSize: "32px" }}>
								<span id="foodbox-0.28.0">0.28.0</span>
								<ul>
									<li>
										<span>Nowe funkcje:</span>
										<ul>
											<li style={{ fontSize: "24px" }}>
												<span style={{ fontWeight: "bold" }}>Nowy system dostępności produktów</span>
												<ul>
													<li>
														Produkty przy dodawaniu/edycji posiadają opcję "Dostępność", w której można wybrać dni tygodnia,
														<br />
														w które ma być dostępny produkt do zamówienia. Po stronie klienta będzie można wybrać tylko te produkty, które są dostępne.
														<br />
														Dla reszty zostanie wyświetlona informacja, od którego dnia tygodnia będzie można zamówić dany produkt.
													</li>
												</ul>
											</li>
											<li style={{ fontSize: "24px" }}>
												<span style={{ fontWeight: "bold" }}>Walidacja hasła przy rejestracji</span>
												<ul>
													<li>Lista z punktami, jakie są wymagania do hasła, w przypadku spełnienia warunku, punkt zmienia kolor.</li>
												</ul>
											</li>
										</ul>
									</li>
									<li>
										<span>Poprawki:</span>
										<ul>
											<li style={{ fontSize: "24px" }}>
												<span style={{ fontWeight: "bold" }}>Zmiana algorytmu wyliczania czasu na złożenie zamówienia</span>
												<ul>
													<li>Limit do godzin pracy dostawcy per dzień.</li>
													<li>Jeśli zamówienie jest składane w godzinach pracy dostawcy. Można je złożyć od razu.</li>
													<li>Zamówienia można składać maksymalnie 7 dni do przodu.</li>
												</ul>
											</li>
											<li style={{ fontSize: "24px" }}>
												<span style={{ fontWeight: "bold" }}>Wyświetlanie listy produktów</span>
												<ul>
													<li>Poprawa skalowania listy produktów i kategorii.</li>
												</ul>
											</li>
											<li style={{ fontSize: "24px" }}>
												<span style={{ fontWeight: "bold" }}>"Duplikowanie się zamówień"</span>
												<ul>
													<li>Zabezpieczenie przycisku Zapłać przed kilkukrotnym wciśnięciem</li>
												</ul>
											</li>
											<li style={{ fontSize: "24px" }}>
												<span style={{ fontWeight: "bold" }}>Problemy z filtrowaniem produktów (Klient)</span>
												<ul>
													<li>Naprawa filtrowania produktów. Problem występował dla kategorii o tej samej nazwie, u różnych dostawców.</li>
												</ul>
											</li>
										</ul>
									</li>
								</ul>
							</li>
							<li style={{ fontSize: "32px" }}>
								<span id="foodbox-0.27.3">0.27.3</span>
								<ul>
									<li>
										<span>Poprawki:</span>
										<ul>
											<li style={{ fontSize: "24px" }}>
												<span style={{ fontWeight: "bold" }}>Wyliczanie kwoty za produkty zamówione przed aplikację</span>
												<ul>
													<li>Problem występował przy zamówieniu, gdzie ilość jednego z produktów była większa niż 1.</li>
												</ul>
											</li>
										</ul>
									</li>
								</ul>
							</li>
							<li style={{ fontSize: "32px" }}>
								<span id="foodbox-0.27.2">0.27.2</span>
								<ul>
									<li>
										<span>Nowe funkcje:</span>
										<ul>
											<li style={{ fontSize: "24px" }}>
												<span style={{ fontWeight: "bold" }}>Maile dla dostawców globalnych</span>
												<ul>
													<li>Dostawcy globalni dostają maile z wszystkich zamówień, z listą, podzieloną na poszczególnych dostawców.</li>
												</ul>
											</li>
										</ul>
									</li>
								</ul>
							</li>
							<li style={{ fontSize: "32px" }}>
								<span id="foodbox-0.27.1">0.27.1</span>
								<ul>
									<li>
										<span>Poprawki:</span>
										<ul>
											<li style={{ fontSize: "24px" }}>
												<span style={{ fontWeight: "bold" }}>Wczytywanie listy produktów</span>
												<ul>
													<li>Naprawa filtrowania produktów, dla kont z przypisanym dostawcom.</li>
												</ul>
											</li>
											<li style={{ fontSize: "24px" }}>
												<span style={{ fontWeight: "bold" }}>Oferty vendingowe</span>
												<ul>
													<li>Odblokowanie funkcji dodawania nowych ofert dla dostawców.</li>
												</ul>
											</li>
										</ul>
									</li>
								</ul>
							</li>
							<li style={{ fontSize: "32px" }}>
								<span id="foodbox-0.27.0">0.27.0</span>
								<ul>
									<li>
										<span>Nowe funkcje:</span>
										<ul>
											<li style={{ fontSize: "24px" }}>
												<span style={{ fontWeight: "bold" }}>Tryb prezentacji</span>
												<ul>
													<li>
														Podstrona zintegrowana ze sklepem internetowym z widokiem kategorii i podkategorii (z podziałem na dostawców) oraz list produktów (bez opcji zamawiania czy logowania).
														<br />
														Dostępna pod linkiem{" "}
														<a target="_blank" rel="noreferrer" href="https://foodbox.store/presentation/shop">
															foodbox.store/presentation/shop
														</a>
														<br />
														<b style={{ color: "var(--warning-color)" }}>UWAGA</b>
														<br />
														Dostępna tylko, <b>gdy nie jest się zalogowanym!</b>
													</li>
												</ul>
											</li>
											<li style={{ fontSize: "24px" }}>
												<span style={{ fontWeight: "bold" }}>Zmiana algorytmu wyliczania terminu dostawy</span>
												<ul>
													<li>
														Dla zamówień dla kilku dostawców, za czas rozpoczęcia pracy brana jest najpóźniejsza godzina,
														<br />a dla czasu zakończenia, najwcześniejsza.
														<br />
														<br />
														Przykład:
														<br />
														Dostawca 1: 9:00 - 18:30
														<br />
														Dostawca 2: 6:00 - 15:00
														<br />
														Czas brany pod uwagę: 9:00 - 15:00
														<br />
														<br />
													</li>
												</ul>
											</li>
											<li style={{ fontSize: "24px" }}>
												<span style={{ fontWeight: "bold" }}>Usprawnienia podziału funkcji na poszczególne role według schematu poniżej</span>
												<br />
												<br />
												<img style={{ borderRadius: "1em", width: "calc(100% - 15px)" }} alt="Role i funkcje foodbox" src={RoleFunkcjeFoodbox}></img>
											</li>
										</ul>
									</li>
									<li>
										<span>Poprawki:</span>
										<ul>
											<li style={{ fontSize: "24px" }}>
												<span style={{ fontWeight: "bold" }}>Zmiany wyglądu</span>
												<ul>
													<li>Zmiana rozmiaru czcionki na mniejszą (z 16px na 14px).</li>
													<li>Zmiana okna rejestracji, aby pojawiał się scrollbar w przypadku mniejszych rozdzielczości.</li>
												</ul>
											</li>
										</ul>
									</li>
								</ul>
							</li>
							<li style={{ fontSize: "32px" }}>
								<span id="foodbox-0.26.0">0.26.0</span>
								<ul>
									<li>
										<span>Nowe funkcje:</span>
										<ul>
											<li style={{ fontSize: "24px" }}>
												<span style={{ fontWeight: "bold" }}>Przydzielanie skrytek pod konkretnych dostawców</span>
												<ul>
													<li>
														Wchodząc w szczegóły urządzenia, dostępny jest przycisk <i>Uprawnienia dostawców</i>.
														<br />
														Następnie po wyborze dostawcy można przypisać skrytkę klikając na nią,
														<br />
														kolor <span style={{ color: "orange" }}>pomarańczowy</span> oznacza przypisanie skrytki,
														<br />
														kolor <span style={{ color: "var(--primary)" }}>zielony</span> oznacza skrytkę dostępną do przypisania,
														<br />
														kolor <span style={{ color: "red" }}>czerwony</span> oznacza skrytkę przypisaną do innego dostawcy.
													</li>
												</ul>
											</li>
											<li style={{ fontSize: "24px" }}>
												<span style={{ fontWeight: "bold" }}>Podział kategorii na dostawców</span>
												<ul>
													<li>Przy zamawianiu, lista kategorii jest podzielona na dostawców do danego urządzenia.</li>
												</ul>
											</li>
										</ul>
									</li>
								</ul>
							</li>
							<li style={{ fontSize: "32px" }}>
								<span id="foodbox-0.25.5">0.25.5</span>
								<ul>
									<li>
										<span>Nowe funkcje:</span>
										<ul>
											<li style={{ fontSize: "24px" }}>
												<span style={{ fontWeight: "bold" }}>Eksport dodatkowych danych z tabeli</span>
												<ul>
													<li>
														Funkcja dostępna dla zamówień, przy eksporcie dodawane są dodatkowe kolumny:
														<br />
														<i>Imię</i>, <i>Nazwisko</i> oraz <i>Telefon</i>
													</li>
												</ul>
											</li>
											<li style={{ fontSize: "24px" }}>
												<span style={{ fontWeight: "bold" }}>Mail o nowym zamówieniu</span>
												<ul>
													<li>
														Gdy użytkownik opłaci swoje zamówienie wysyłany jest email do użytkowników z rolą <i>Dostawca</i>,
														<br />
														którzy są przypisani do odpowiedniego dostawcy.
													</li>
												</ul>
											</li>
										</ul>
									</li>
								</ul>
							</li>
							<li style={{ fontSize: "32px" }}>
								<span id="foodbox-0.25.4">0.25.4</span>
								<ul>
									<li>
										<span>Nowe funkcje:</span>
										<ul>
											<li style={{ fontSize: "24px" }}>
												<span style={{ fontWeight: "bold" }}>Powiadomienia na maila o włożeniu zamówienia złożonego przez internet</span>
												<ul>
													<li>
														Po zmianie statusu zamówienia na <i>Dostarczone</i>, użytkownik dostaje powiadomienie na maila.
													</li>
												</ul>
											</li>
										</ul>
									</li>
								</ul>
							</li>
							<li style={{ fontSize: "32px" }}>
								<span id="foodbox-0.25.3">0.25.3</span>
								<ul>
									<li>
										<span>Nowe funkcje:</span>
										<ul>
											<li style={{ fontSize: "24px" }}>
												<span style={{ fontWeight: "bold" }}>Zmiana wyglądu aplikacji</span>
												<ul>
													<li>Zmiana wyglądu strony głównej.</li>
													<li>Zmiana wyglądu przycisków oraz ich ułożenia.</li>
												</ul>
											</li>
										</ul>
									</li>
								</ul>
							</li>
							<li style={{ fontSize: "32px" }}>
								<span id="foodbox-0.25.2">0.25.2</span>
								<ul>
									<li>
										<span>Nowe funkcje:</span>
										<ul>
											<li style={{ fontSize: "24px" }}>
												<span style={{ fontWeight: "bold" }}>Zgody podczas rejestracji</span>
												<ul>
													<li>Podpięcie regulaminu i polityki prywatności do rejestracji, jako obowiązkowe pole do zaznaczenia.</li>
												</ul>
											</li>
											<li style={{ fontSize: "24px" }}>
												<span style={{ fontWeight: "bold" }}>Identyfikacja użytkownika w zamówieniach</span>
												<ul>
													<li>Po wejściu w szczegóły zamówienia, w osobnej tabeli są dane użytkownika.</li>
												</ul>
											</li>
										</ul>
									</li>
								</ul>
							</li>
							<li style={{ fontSize: "32px" }}>
								<span id="foodbox-0.25.1">0.25.1</span>
								<ul>
									<li>
										<span>Nowe funkcje:</span>
										<ul>
											<li style={{ fontSize: "24px" }}>
												<span style={{ fontWeight: "bold" }}>Tabele - Filtrowanie po datach</span>
												<ul>
													<li>
														Kolumny, które zawierają daty mają inne okno filtrowania.
														<br />
														Składa się ono z dwóch pól, <i>Filtruj od ..</i> oraz <i>Filtruj do ..</i>.
														<br />
														Kliknięcie jednego z nich pokazuje okno wyboru dnia. Wybór dnia automatycznie ustawia filtr.
														<br />
														Aby wyczyścić dany filtr należy użyć przycisku pomiędzy polami (<Icon path={mdiCancel} size="16px" />
														).
													</li>
												</ul>
											</li>
											<li style={{ fontSize: "24px" }}>
												<span style={{ fontWeight: "bold" }}>Zmiana wyglądu maili</span>
												<ul>
													<li>Zmiany wyglądu maili dla resetu i rejestracji konta.</li>
												</ul>
											</li>
										</ul>
									</li>
									<li>
										<span>Poprawki:</span>
										<ul>
											<li style={{ fontSize: "24px" }}>
												<span style={{ fontWeight: "bold" }}>Zmiana czasu złożenia zamówienia</span>
												<ul>
													<li>Czas wydłużony z dwóch dni do tygodnia.</li>
												</ul>
											</li>
											<li style={{ fontSize: "24px" }}>
												<span style={{ fontWeight: "bold" }}>Zabezpieczenie przed kilkukrotnym kliknięciem przycisku</span>
												<ul>
													<li>
														Po kliknięciu przycisku w czasie oczekiwania na odpowiedź serwera przycisk staje się nieaktywny.
														<br />
														Aby zapobiec ponownemu wykonaniu danej akcji, co mogło się objawiać zduplikowanymi elementami systemu.
													</li>
												</ul>
											</li>
										</ul>
									</li>
								</ul>
							</li>
							<li style={{ fontSize: "32px" }}>
								<span id="foodbox-0.25.0">0.25.0</span>
								<ul>
									<li>
										<span>Nowe funkcje:</span>
										<ul>
											<li style={{ fontSize: "24px" }}>
												<span style={{ fontWeight: "bold" }}>Zamówienia przez aplikację z płatnością (BETA)</span>
												<ul>
													<li>
														Po przejściu do koszyka oraz kliknięciu przycisku <b>Zapłać</b>,
														<br />
														użytkownik zostaje przekierowany do płatności imoje,
														<br />
														po ukończeniu płatności następuje przekierowanie do aplikacji z statusem transakcji
														<br />
														<b style={{ color: "var(--warning-color)" }}>UWAGA</b>
														<br />W tej wersji, status płatności jest sprawdzany <b>po automatycznym przekierowaniu do aplikacji</b>
														<br />W przypadku użycia przycisku <i>Wróć do witryny</i>, może zostać zwrócony niepoprawny komunikat
													</li>
												</ul>
											</li>
										</ul>
									</li>
								</ul>
							</li>
							<li style={{ fontSize: "32px" }}>
								<span id="foodbox-0.24.0">0.24.0</span>
								<ul>
									<li>
										<span>Nowe funkcje:</span>
										<ul>
											<li style={{ fontSize: "24px" }}>
												<span style={{ fontWeight: "bold" }}>Weryfikacja konta użytkownika</span>
												<ul>
													<li>Po rejestracji konta, zostaje wysłany email, z linkiem aktywacyjnym</li>
													<li>
														W przypadku próby zalogowania, na nieaktywne konto,
														<br />
														zostaje wysłany kolejny email, z nowym linkiem aktywacyjnym
													</li>
												</ul>
											</li>
											<li style={{ fontSize: "24px" }}>
												<span style={{ fontWeight: "bold" }}>Resetowanie hasła</span>
												<ul>
													<li>
														Funkcja dostępna podczas logowania, pod linkiem <i>Nie pamiętam hasła</i>
													</li>
													<li>Wysyła wiadomość email z linkiem do resetu, który wygasa po 15 minutach</li>
												</ul>
											</li>
										</ul>
										<ul>
											<li style={{ fontSize: "24px" }}>
												<span style={{ fontWeight: "bold" }}>Panel administratora - boczny panel</span>
												<ul>
													<li>Przycisk ukrywający boczny panel</li>
												</ul>
											</li>
										</ul>
									</li>
									<li>
										<span>Poprawki:</span>
										<ul>
											<li style={{ fontSize: "24px" }}>
												<span style={{ fontWeight: "bold" }}>Komunikat o braku danych w tabeli</span>
												<ul>
													<li>
														Wyświetlania komunikat o braku wierszy w tabeli <i>Brak wierszy do wyświetlenia</i>
													</li>
												</ul>
											</li>
										</ul>
									</li>
								</ul>
							</li>
							<li style={{ fontSize: "32px" }}>
								<span id="foodbox-0.23.0">0.23.0</span>
								<ul>
									<li>
										<span>Nowe funkcje:</span>
										<ul>
											<li style={{ fontSize: "24px" }}>
												<span style={{ fontWeight: "bold" }}>Export danych z tabeli do pliku csv w 2 wariantach:</span>
												<ul>
													<li>widoczne dane</li>
													<li>wszystkie dane</li>
												</ul>
											</li>
										</ul>
									</li>
								</ul>
							</li>
							<li style={{ fontSize: "32px" }}>
								<span id="foodbox-0.22.2">0.22.2</span>
								<ul>
									<li>
										<span>Poprawki:</span>
										<ul>
											<li style={{ fontSize: "24px" }}>
												<span style={{ fontWeight: "bold" }}>Weryfikacja adresów płytek urządzenia</span>
												<ul>
													<li>Poprawiona obsługa dodatkowych funkcji</li>
													<li>zmienione adresy</li>
												</ul>
											</li>
										</ul>
									</li>
								</ul>
							</li>
							<li style={{ fontSize: "32px" }}>
								<span id="foodbox-0.22.1">0.22.1</span>
								<ul>
									<li>
										<span>Nowe funkcje:</span>
										<ul>
											<li style={{ fontSize: "24px" }}>
												<span style={{ fontWeight: "bold" }}>Nowe funkcje tabel dla:</span>
												<ul>
													<li>Lista urządzeń</li>
													<li>Lista zamówień</li>
													<li>Lista sklepów</li>
													<li>Lista użytkowników</li>
													<li>Lista transakcji</li>
													<li>Lista ofert vendingowych</li>
												</ul>
											</li>

											<li style={{ fontSize: "24px" }}>
												<span style={{ fontWeight: "bold" }}>Funkcja, zawiń tekst</span>
												<ul>
													<li>Zawija tekst danej kolumny</li>
												</ul>
											</li>
											<li style={{ fontSize: "24px" }}>
												<span style={{ fontWeight: "bold" }}>Funkcja, ukryj kolumnę</span>
												<ul>
													<li>
														Ukrywa daną kolumnę
														<br />
														<b style={{ color: "var(--warning-color)" }}>UWAGA</b>
														<br />
														Funkcja filtru nadal może być użyta,
														<br />
														gdy jest aktywna, kolor ikony jest brązowy
													</li>
												</ul>
											</li>
											<li style={{ fontSize: "24px" }}>
												<span style={{ fontWeight: "bold" }}>Komunikat o niezgodnej wersji aplikacji z serwerem</span>
												<ul>
													<li>
														W przypadku niezgodnej wersji, zostanie podjęta próba odświeżenia danych aplikacji,
														<br />
														jeśli to nie pomoże, zostanie wyświetlony komunikat
													</li>
												</ul>
											</li>
										</ul>
									</li>
								</ul>
							</li>
							<li style={{ fontSize: "32px" }}>
								<span id="foodbox-0.22.0">0.22.0</span>
								<ul>
									<li>
										<span>Nowe funkcje:</span>
										<ul>
											<li style={{ fontSize: "24px" }}>
												<span style={{ fontWeight: "bold" }}>Usprawnienia tabel</span>
												<ul>
													<li>
														<b>Filtrowanie po kolumnach</b>, poprzez kliknięcie ikony lupy obok nazwy
														<br />
														Pojawia się pole <i>Filtruj</i>, wpisana fraza jest dzielona przez spację na części
														<br />
														a następnie wiersze są sprawdzane, pod kątem dopasowania, ignorując wielkość znaków
														<br />
														<b>Przykład:</b>
														<br />
														Wpisana Fraza: <b>psz CHleB</b>
														<br />
														Możliwy wynik: <b>Chleb pszenny</b>
														<br />
														Po ustawieniu filtru i wybraniu innego elementu, kolor ikony lupy zmienia się na brązowy.
														<br />
														Można wtedy wybrać ikonę przy innej kolumnie i ustawić kolejny filtr.
														<br />
														Filtr będzie działać do czasu usunięcia wpisanej frazy lub przejścia do innej zakładki.
													</li>
												</ul>
												<ul>
													<li>
														<b>Podział wierszy tabeli na stronę</b>
														<br />
														Przed tabelą są 2 dodatkowe pola, <i>Wybrana strona</i> oraz <i>Wierszy na stronę</i>
													</li>
												</ul>
											</li>
										</ul>
									</li>
								</ul>
							</li>
							<li style={{ fontSize: "32px" }}>
								<span id="foodbox-0.21.2">0.21.2</span>
								<ul>
									<li>
										<span>Poprawki:</span>
										<ul>
											<li style={{ fontSize: "24px" }}>
												<span style={{ fontWeight: "bold" }}>Błędne zaokrąglenia kwot</span>
												<ul>
													<li>W niektórych przypadkach występowało niepoprawne zaokrąglenie kwoty o 1 grosz</li>
												</ul>
											</li>
										</ul>
									</li>
								</ul>
							</li>
							<li style={{ fontSize: "32px" }}>
								<span id="foodbox-0.21.1">0.21.1</span>
								<ul>
									<li>
										<span>Nowe funkcje:</span>
										<ul>
											<li style={{ fontSize: "24px" }}>
												<span style={{ fontWeight: "bold" }}>Wyjmowanie dodanych ofert</span>
												<ul>
													<li>
														Jeśli oferta została włożona do skrytki, można ją wyjąć przyciskiem <b>wyjmij</b>. Jest to wymagany krok dla usunięcia oferty.
													</li>
												</ul>
											</li>
										</ul>
									</li>
									<li>
										<span>Poprawki:</span>
										<ul>
											<li style={{ fontSize: "24px" }}>
												<span style={{ fontWeight: "bold" }}>Brak listy produktów (aplikacja klienta)</span>
												<ul>
													<li>
														Niewyświetlająca się lista produktów
														<br />
														<i>poprawki funkcji "Oferty na kilka produktów"</i>
													</li>
												</ul>
											</li>
											<li style={{ fontSize: "24px" }}>
												<span style={{ fontWeight: "bold" }}>Literówki</span>
												<ul>
													<li>Zmiana "Uprawnień dostawców" na "Uprawnienia sklepów"</li>
												</ul>
											</li>
											<li style={{ fontSize: "24px" }}>
												<span style={{ fontWeight: "bold" }}>Wewnętrzne</span>
												<ul>
													<li>Optymalizacja wartości domyślnych dla pól typu select</li>
												</ul>
											</li>
										</ul>
									</li>
								</ul>
							</li>
							<li style={{ fontSize: "32px" }}>
								<span id="foodbox-0.21.0">0.21.0</span>
								<ul>
									<li>
										<span>Nowe funkcje:</span>
										<ul>
											<li style={{ fontSize: "24px" }}>
												<span style={{ fontWeight: "bold" }}>Oferty na kilka produktów</span>
												<ul>
													<li>Dodawanie kilku produktów do ofert vendingowych na jedną skrytkę</li>
													<li>Realizacja zamówień przez aplikację na jedną skrytkę</li>
												</ul>
											</li>
										</ul>
									</li>
								</ul>
							</li>
							<li style={{ fontSize: "32px" }}>
								<span id="foodbox-0.20.3">0.20.3</span>
								<ul>
									<li>
										<span>Nowe funkcje:</span>
										<ul>
											<li style={{ fontSize: "24px" }}>
												<span style={{ fontWeight: "bold" }}>Lista ofert vendingowych</span>
												<ul>
													<li>Możliwość usuwania ofert, osobny przycisk dla dodawania produktów do skrytek</li>
												</ul>
											</li>
										</ul>
									</li>
									<li>
										<span>Poprawki:</span>
										<ul>
											<li style={{ fontSize: "24px" }}>
												<span style={{ fontWeight: "bold" }}>Transakcje</span>
												<ul>
													<li>
														Poprawne wyliczanie kwot zakupu
														<br />
														<i>(problem z pojawiającymi się dodatkowymi produktami w koszyku, o tym samym numerze skrytki)</i>
													</li>
												</ul>
											</li>
										</ul>
									</li>
								</ul>
							</li>
							<li style={{ fontSize: "32px" }}>
								<span id="foodbox-0.20.2">0.20.2</span>
								<ul>
									<li>
										<span>Poprawki:</span>
										<ul>
											<li style={{ fontSize: "24px" }}>
												<span style={{ fontWeight: "bold" }}>(Backend): Transakcje</span>
												<ul>
													<li>hotfix dla otwierania skrytek po opłaceniu zamówienia</li>
												</ul>
											</li>
										</ul>
									</li>
								</ul>
							</li>
							<li style={{ fontSize: "32px" }}>
								<span id="foodbox-0.20.1">0.20.1</span>
								<ul>
									<li>
										<span>Nowe funkcje:</span>
										<ul>
											<li style={{ fontSize: "24px" }}>
												<span style={{ fontWeight: "bold" }}>(Frontend): Własna kwota brutto</span>
												<ul>
													<li>Ręczna zmiana kwoty brutto</li>
													<li>
														Sugerowana kwota brutto do wpisania, dla{" "}
														<a
															href="/dashboard/products/create"
															onClick={(e) => {
																e.preventDefault();
																navigate("/dashboard/products/create");
															}}
														>
															nowych
														</a>{" "}
														i edytowanych produktów
													</li>
												</ul>
											</li>
											<li style={{ fontSize: "24px" }}>
												<span style={{ fontWeight: "bold" }}>(Frontend): Numer karty, przy udanej transakcji</span>
												<ul>
													<li>
														Numer karty dostępny w zakładce{" "}
														<a
															href="/dashboard/vending-orders"
															onClick={(e) => {
																e.preventDefault();
																navigate("/dashboard/vending-orders");
															}}
														>
															Transakcje
														</a>
													</li>
												</ul>
											</li>
										</ul>
									</li>
									<li>
										<span>Poprawki:</span>
										<ul>
											<li style={{ fontSize: "24px" }}>
												<span style={{ fontWeight: "bold" }}>(Frontend): Kwoty brutto</span>
												<ul>
													<li>Wyświetlanie kwot brutto zamiast netto (po stronie klienta)</li>
												</ul>
											</li>
										</ul>
									</li>
								</ul>
							</li>
							<li style={{ fontSize: "32px" }}>
								<span id="foodbox-0.19.12">0.19.12</span>
								<ul>
									<li>
										<span>Poprawki:</span>
										<ul>
											<li style={{ fontSize: "24px" }}>
												<span style={{ fontWeight: "bold" }}>(Frontend): Zamawianie po stronie klienta</span>
												<ul>
													<li>Zmiana nazwy przycisku z "Gotowe" na "Złóż zamówienie"</li>
												</ul>
											</li>
											<li style={{ fontSize: "24px" }}>
												<span style={{ fontWeight: "bold" }}>(Backend): Zamawianie przez kiosk</span>
												<ul>
													<li>Optymalizacja pobierania listy dostępnych ofert (część 1)</li>
													<li>Przekazanie wartości vendingOrderId do callbacku płatności</li>
												</ul>
											</li>
										</ul>
									</li>
								</ul>
							</li>
							<Button
								onClick={() => {
									setViewOldFoodBoxReleases(false);
								}}
							>
								Ukryj starsze zmiany
							</Button>
						</>
					)}
				</ul>
			</div>

			<div className={styles.panelContainer}>
				<div className={styles.header}>
					<h2>Lista zmian (Kiosk)</h2>
				</div>
				<ul>
					<li style={{ fontSize: "32px" }}>
						<span id="kiosk-0.1.8">0.2.0</span>
						<ul>
							<li>
								<span>Nowe funkcje:</span>
								<ul>
									<li style={{ fontSize: "24px" }}>
										<span style={{ fontWeight: "bold" }}>System rabatów (demo)</span>
										<ul>
											<li>
												Dostępny jest dodatkowy przycisk, którego aktywacja, umożliwia skanowanie kodów (zmienia kolor na zielony).
												<br />
												Po zeskanowaniu, jeśli znajduje się w systemie rabatów, podświetla skrytkę na różowo (tylko niewybrane),
												<br />
												Na liście podsumowania, wyświetla się przekreślona cena bez rabatu i nowa z rabatem.
												<br />
												Aktualnie, kody rabatowe, to kody kreskowe, które są przypisane do danych produktów w panelu administratora.
											</li>
										</ul>
									</li>
								</ul>
							</li>
						</ul>
					</li>
					{!viewOldKioskReleases ? (
						<Button
							onClick={() => {
								setViewOldKioskReleases(true);
							}}
						>
							Pokaż starsze zmiany
						</Button>
					) : (
						<>
							<Button
								onClick={() => {
									setViewOldKioskReleases(false);
								}}
							>
								Ukryj starsze zmiany
							</Button>
							<li style={{ fontSize: "32px" }}>
								<span id="kiosk-0.1.8">0.1.8</span>
								<ul>
									<li>
										<span>Poprawki:</span>
										<ul>
											<li style={{ fontSize: "24px" }}>
												<span style={{ fontWeight: "bold" }}>Ignorowanie zamówień przez aplikację</span>
												<ul>
													<li>
														Skrytki, w których znajduje się zamówione złożone przez aplikację,
														<br />
														mają kolor pomarańczowy oraz nie można ich wybrać
													</li>
												</ul>
											</li>
										</ul>
									</li>
								</ul>
							</li>
							<li style={{ fontSize: "32px" }}>
								<span id="kiosk-0.1.7">0.1.7</span>
								<ul>
									<li>
										<span>Poprawki:</span>
										<ul>
											<li style={{ fontSize: "24px" }}>
												<span style={{ fontWeight: "bold" }}>Niepoprawne wyświetlenie zamówienia w skrytkach</span>
												<ul>
													<li>Występowały przypadki wyświetlenia innego zamówienia niż faktycznie znajdowało się w skrytce</li>
												</ul>
											</li>
										</ul>
									</li>
								</ul>
							</li>
							<li style={{ fontSize: "32px" }}>
								<span id="kiosk-0.1.6">0.1.6</span>
								<ul>
									<li>
										<span>Poprawki:</span>
										<ul>
											<li style={{ fontSize: "24px" }}>
												<span style={{ fontWeight: "bold" }}>Oferty na kilka produktów</span>
												<ul>
													<li>Przystosowanie do ofert na kilka produktów</li>
												</ul>
											</li>
										</ul>
									</li>
								</ul>
							</li>
							<li style={{ fontSize: "32px" }}>
								<span id="kiosk-0.1.5">0.1.5</span>
								<ul>
									<li>
										<span>Nowe funkcje:</span>
										<ul>
											<li style={{ fontSize: "24px" }}>
												<span style={{ fontWeight: "bold" }}>Przechwytywanie błędów</span>
												<ul>
													<li>Widok dla błędu krytycznego aplikacji z podziałem, na na winę aplikacji lub serwera</li>
													<li>Telemetria dla błędów krytycznych aplikacji</li>
												</ul>
											</li>
										</ul>
									</li>
									<li>
										<span>Poprawki:</span>
										<ul>
											<li style={{ fontSize: "24px" }}>
												<span style={{ fontWeight: "bold" }}>Zamawianie prze kiosk</span>
												<ul>
													<li>Informacja o nieudanej próbie rozpoczęcia płatności</li>
													<li>Optymalizacja wczytywania listy dostępnych ofert (część 1)</li>
												</ul>
											</li>
										</ul>
									</li>
								</ul>
							</li>
							<Button
								onClick={() => {
									setViewOldKioskReleases(false);
								}}
							>
								Ukryj starsze zmiany
							</Button>
						</>
					)}
				</ul>
			</div>

			<Outlet />
		</div>
	);
};

export default InformationList;

import React from "react";
import styles from "styles/UsersEdit.module.css";
import { useFetch } from "hooks/useFetch";
import PathsAPI from "constants/PathsAPI";
import Button from "components/Button";
import NavButtonLink from "components/NavButtonLink";
import { useNavigate } from "react-router-dom";
import { useEffect, useRef } from "react";
import Draggable from "react-draggable";
import stateMode from "constants/stateMode";

const NewShop = () => {
	const nodeRef = useRef(null);
	const { usePost } = useFetch(PathsAPI.Suppliers);
	const { request, response, loading, hasError } = usePost();
	const navigate = useNavigate();

	const handleSubmit = (e) => {
		e.preventDefault();
		const formData = new FormData(e.target);
		request(formData);
	};

	useEffect(() => {
		response?._id && navigate("../", { state: stateMode.Refresh });
	}, [response, navigate]);

	return (
		<Draggable nodeRef={nodeRef} handle={`.${styles.header}`}>
			<div ref={nodeRef} className={styles.container}>
				<div className={styles.header}>
					<h2>Dodaj nowego dostawcę</h2>
					<NavButtonLink type="reset" to="../" state={stateMode.Refresh}>
						&#x2715;
					</NavButtonLink>
				</div>
				<form className={styles.form} onSubmit={handleSubmit}>
					<fieldset>
						<legend>Nazwa</legend>
						<input required type="text" name="name" placeholder="Podaj nazwę dostawcy .." />
					</fieldset>

					<fieldset>
						<legend>Adres URL logo</legend>
						<input required type="text" name="logoUrl" placeholder="Podaj adres URL dostawcy .." />
					</fieldset>

					{hasError && <div className="error">{hasError}</div>}

					<div className={styles.buttons}>
						<Button loading={loading}>Dodaj nowego dostawcę</Button>
					</div>
				</form>
			</div>
		</Draggable>
	);
};

export default NewShop;

import React, { useEffect } from "react";
import { useFetch } from "hooks/useFetch";
import PathsAPI from "constants/PathsAPI";
import styles from "styles/Orders.module.css";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import Button from "components/Button";
import stateMode from "constants/stateMode";
import TableComponent from "components/Table";
import orderTable from "constants/tables/orderTable";
import { useAuthContext } from "hooks/useAuthContext";
import getOrderStatus from "components/orderStatus";
import orderExportTable from "constants/tables/export/orderExportTable";

const OrdersList = () => {
	const { useGet } = useFetch(PathsAPI.Orders);
	const { request, response, loading, hasError } = useGet(true);

	const { user } = useAuthContext();
	const isGlobal = user.supplier === "GLOBAL";

	const { useGet: useSuppliersGet } = useFetch(PathsAPI.Suppliers);
	const { response: suppliersList, loading: loadingSuppliersList } = useSuppliersGet(isGlobal);

	const location = useLocation();

	useEffect(() => {
		if (location.state === stateMode.Refresh) {
			request();
			location.state = null;
		}

		if(location.state?.mode === stateMode.RefreshWithResponse) {
			if(location.state?.refreshed) {
				return;
			}

			request();
			location.state.refreshed = true;
		}

	}, [location, request]);

	let navigate = useNavigate();

	const useHandleLoadData = () => {
		request();
	};

	const handleOpenDevice = ({ _id }) => {
		navigate(_id);
	};

	const handleExportParser = (body) => {
		let result = [];

		for (const order of body) {
			const { _id: orderId, userData, cartList, deliveryTime, device, statusHistory, createdAt, updatedAt } = order;

			for (const [orderIndex, orderProduct] of cartList.entries()) {
				const { supplier: supplierId, name: productName, cost: productNetto, brutto: productBrutto } = orderProduct;

				const supplierData = suppliersList?.find((supplierFind) => supplierFind._id === supplierId);

				result.push({
					orderId,
					orderProductsCounter: `${orderIndex + 1} / ${cartList.length}`,
					supplierName: supplierData?.name || "Błąd",
					userData,
					deliveryTime,
					deviceLocation: device.location,
					productName,
					productNetto: Number(productNetto).toFixed(2),
					productBrutto: Number(productBrutto).toFixed(2),
					orderStatus: getOrderStatus({ statusHistory })?.message || "Nieznany",
					createdAt,
					updatedAt,
				});
			}
		}

		return result;
	};

	const handleViewChangeOrderStatusDialog = (selectedRows) => {
		console.log(selectedRows);
		navigate("change-status", { state: { selectedRows } });
	};

	return (
		<div className={styles.main}>
			<div className={styles.panelContainer}>
				<div className={styles.header}>
					<h2>Lista zamówień</h2>
					<div>
						<Button loading={loading} error={hasError} onClick={useHandleLoadData}>
							Odśwież
						</Button>
					</div>
				</div>

				<TableComponent
					title="Lista zamówień"
					headValues={orderTable}
					bodyValues={response}
					error={hasError}
					rowAction={handleOpenDevice}
					additionalValues={{ suppliersList }}
					loadedAdditionalValues={[!loadingSuppliersList]}
					exportParser={handleExportParser}
					exportHeaders={orderExportTable}
					selectRows={{
						columnIndexName: "_id",
						enabled: true,
						multiActionButton: {
							title: "Zmień status zaznaczonych zamówień",
							onClick: handleViewChangeOrderStatusDialog,
						},
					}}
				/>
			</div>
			<Outlet />
		</div>
	);
};

export default OrdersList;

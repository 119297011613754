import React from "react";

const shopsTable = {
	_id: {
		name: "Identyfikator",
	},
	name: {
		name: "Nazwa",
	},
	logoUrl: {
		name: "Logo",
		filter: (logoUrl, { row }) => {
			return `Logo sklepu - ${row.name}`;
		},
		parser: (logoUrl, { row }) => {
			return (
				<div style={{ display: "grid", justifyContent: "center" }}>
					<img loading="lazy" height={"32px"} alt={`Logo sklepu - ${row.name}`} src={logoUrl}></img>
				</div>
			);
		},
	},
};

export default shopsTable;

import React from "react";

const productTable = {
	name: {
		name: "Nazwa",
	},
	shopName: {
		name: "Dostawca",
		parser: (value) => {
			return value?.name || "Błąd";
		},
		hideColumn: ({ supplier }) => {
			return !["GLOBAL"].includes(supplier);
		},
	},
	cost: {
		name: "Cena netto",
		parser: (value) => {
			return `${Number(value).toFixed(2)} PLN`;
		},
	},
	brutto: {
		name: "Cena brutto",
		parser: (value) => {
			return `${Number(value).toFixed(2)} PLN`;
		},
	},
	vat: {
		name: "Stawka VAT",
		parser: (value) => {
			return `${Number(value) * 100}%`;
		},
	},
	category: {
		name: "Kategoria",
	},
	prefBoxType: {
		name: "Typ skrytki",
		parser: (value) => {
			switch (value) {
				case "COLD":
					return "Zimna";
				case "WARM":
					return "Ciepła";
				default:
					return "Błąd";
			}
		},
	},
	startDeliveryDate: {
		name: "Rozpoczęcie dostarczania",
		isDate: true,
		isOnlyDate: true,
		filter: (value) => {
			return new Date(value);
		},
		parser: (value) => {
			return new Date(value).toLocaleDateString("pl");
		},
	},
	endDeliveryDate: {
		name: "Zakończenie dostarczania",
		isDate: true,
		isOnlyDate: true,
		filter: (value) => {
			return new Date(value);
		},
		parser: (value) => {
			return new Date(value).toLocaleDateString("pl");
		},
	},
	createdAt: {
		name: "Data dodania",
		isDate: true,
		filter: (value) => {
			return new Date(value);
		},
		parser: (value) => {
			const date = new Date(value).toLocaleString("pl").split(", ");
			return (
				<>
					{date[0]}
					<br />
					{date[1]}
				</>
			);
		},
	},
	updatedAt: {
		name: "Data aktualizacji",
		isDate: true,
		filter: (value) => {
			return new Date(value);
		},
		parser: (value) => {
			const date = new Date(value).toLocaleString("pl").split(", ");
			return (
				<>
					{date[0]}
					<br />
					{date[1]}
				</>
			);
		},
	},
};

export default productTable;

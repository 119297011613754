import React from "react";
import styles from "styles/DeviceConfig.module.css";
import { useFetch } from "hooks/useFetch";
import PathsAPI from "constants/PathsAPI";
import { useNavigate, useParams } from "react-router-dom";
import Button from "components/Button";
import NavButtonLink from "components/NavButtonLink";
import { useEffect, useRef } from "react";
import Draggable from "react-draggable";
import stateMode from "constants/stateMode";

const EditCoffeeData = () => {
	const nodeRef = useRef(null);
	let params = useParams();

	const { useGet, usePut } = useFetch(`${PathsAPI.Devices}/${params.id}`);
	const { response: deviceInfo } = useGet(true);
	const { request, response, loading, hasError } = usePut();

	let navigate = useNavigate();

	const handleSubmit = (e) => {
		e.preventDefault();
		const formData = new FormData(e.target);

		request({
			"coffee.cost": formData.get("cost"),
			"coffee.size": formData.get("size"),
			"coffee.vat": formData.get("vat"),
		});
	};

	useEffect(() => {
		response?._id && navigate("../", { state: stateMode.Refresh });
	}, [response, navigate]);

	return (
		<>
			{deviceInfo && (
				<Draggable nodeRef={nodeRef} handle={`.${styles.header}`}>
					<div ref={nodeRef} className={styles.container}>
						<div className={styles.header}>
							<h2>Edytuj dane kawy</h2>
							<NavButtonLink type="reset" to="../" state={stateMode.Refresh}>
								&#x2715;
							</NavButtonLink>
						</div>
						<form className={styles.form} onSubmit={handleSubmit}>
							<fieldset>
								<legend>Cena kawy (PLN)</legend>
								<input required type="text" name="cost" placeholder="Podaj wartość w PLN" defaultValue={deviceInfo.coffee.cost} />
							</fieldset>

							<fieldset>
								<legend>Wielkość kawy (ml)</legend>
								<input required type="text" name="size" placeholder="Podaj wartość w mililitrach" defaultValue={deviceInfo.coffee.size} />
							</fieldset>

							<fieldset>
								<legend>Stawka VAT</legend>
								<select className={styles.select} name="vat" defaultValue={deviceInfo.coffee.vat}>
									<option value={"0.23"}>Stawka A (23%)</option>
									<option value={"0.08"}>Stawka B (8%)</option>
									<option value={"0.05"}>Stawka C (5%)</option>
									<option value={"0.00"}>Stawka D (0%)</option>
								</select>
							</fieldset>

							{hasError && <div className="error">{hasError}</div>}

							<div className={styles.buttons}>
								<Button type="submit" loading={loading}>
									Zapisz zmiany
								</Button>
							</div>
						</form>
					</div>
				</Draggable>
			)}
		</>
	);
};

export default EditCoffeeData;

const orderExportTable = {
	orderId: {
		name: "Identyfikator zamówienia",
	},
	orderProductsCounter: {
		name: "Ilość produktów",
		parser: (value) => {
			return `'${value}'`;
		},
	},
	supplierName: {
		name: "Dostawca",
		hideColumn: ({ supplier }) => {
			return !["GLOBAL"].includes(supplier);
		},
	},
	"userData.details.fname": {
		name: "Imię",
	},
	"userData.details.lname": {
		name: "Nazwisko",
	},
	"userData.details.phone": {
		name: "Telefon",
	},
	deliveryTime: {
		name: "Najlepiej dostarczyć do",
		isDate: true,
		filter: (value) => {
			return new Date(value);
		},
		parser: (value) => {
			return `${new Date(value).toLocaleString()}`;
		},
	},
	deviceLocation: {
		name: "Lokalizacja FoodBox",
	},
	productName: {
		name: "Nazwa produktu",
	},
	productNetto: {
		name: "Koszt netto",
		parser: (value) => {
			return `${value} zł`;
		},
	},
	productBrutto: {
		name: "Koszt brutto",
		parser: (value) => {
			return `${value} zł`;
		},
	},
	orderStatus: {
		name: "Status zamówienia",
	},
	createdAt: {
		name: "Data utworzenia",
		isDate: true,
		isOnlyDate: true,
		filter: (value) => {
			return new Date(value);
		},
		parser: (value) => {
			return `${new Date(value).toLocaleString()}`;
		},
	},
	updatedAt: {
		name: "Data aktualizacji",
		isDate: true,
		isOnlyDate: true,
		filter: (value) => {
			return new Date(value);
		},
		parser: (value) => {
			return `${new Date(value).toLocaleString()}`;
		},
	},
};

export default orderExportTable;

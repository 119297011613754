import React, { useEffect, useState } from "react";
import styles from "styles/VendingSetDiscount.module.css";
import { useFetch } from "hooks/useFetch";
import PathsAPI from "constants/PathsAPI";
import Button from "components/Button";
import NavButtonLink from "components/NavButtonLink";
import { useRef } from "react";
import Draggable from "react-draggable";
import stateMode from "constants/stateMode";
import Icon from "@mdi/react";
import { mdiTrashCan } from "@mdi/js";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { Checkbox, FormControlLabel } from "@mui/material";
import { useNavigate } from "react-router-dom";

const VendingSetDiscount = () => {
	const isMobile = window.innerWidth < 700;

	const nodeRef = useRef(null);
	const mainContainerRef = useRef(null);

	const params = useParams();

	const { useGet: useDiscountGet, usePut: useDiscountPut } = useFetch(PathsAPI.VendingOfferDiscount(params.offerId));
	const { response: discountData, loading, hasError } = useDiscountGet(true);
	const { request: saveDiscountChanges } = useDiscountPut();

	const endOfferLifeInput = useRef();
	const [enableEndOfferLife, setEnableEndOfferLife] = useState(false);

	const [discountList, setDiscountList] = useState([]);

	const navigate = useNavigate();

	useEffect(() => {
		if (!discountData) return;

		const { discountConditions, endOfferLife } = discountData;

		setDiscountList(discountConditions);
		setEnableEndOfferLife(endOfferLife.enableOption);
		endOfferLifeInput.current.value = endOfferLife.daysUntilDisabled;
		endOfferLifeInput.current.disabled = !endOfferLife.enableOption;
	}, [discountData]);

	const handleAddDiscount = () => {
		setDiscountList([
			...discountList,
			{
				discountRate: "0",
				activateAfterDays: discountList[discountList.length - 1] ? Number(discountList[discountList.length - 1].activateAfterDays) + 1 : 1,
			},
		]);
	};

	const handleRemoveDiscount = (discountIndex) => {
		setDiscountList(discountList.filter((_, index) => index !== discountIndex));
	};

	const handleChangeDiscountRow = (e, keyName, discountIndex) => {
		if (isNaN(Number(e.target.value))) {
			e.target.value = e.target.value.slice(0, -1);
			return;
		}

		discountList[discountIndex][keyName] = e.target.value?.length > 0 ? Number(e.target.value) : null;
		setDiscountList([...discountList]);
	};

	const handleSubmit = async () => {
		try {
			const daysUntilDisabled = endOfferLifeInput.current.value.trim();

			let validationError = false;

			for (let discountIndex = 0; discountIndex < discountList.length; discountIndex++) {
				const discount = discountList[discountIndex];

				if (discount.discountRate === null || isNaN(discount.discountRate)) {
					mainContainerRef.current.querySelector(`#discountRate-${discountIndex}`).style.border = "2px solid var(--error-color)";
					validationError = true;
				} else {
					mainContainerRef.current.querySelector(`#discountRate-${discountIndex}`).style.border = "";
				}

				if (discount.activateAfterDays === null || isNaN(discount.activateAfterDays)) {
					mainContainerRef.current.querySelector(`#activateAfterDays-${discountIndex}`).style.border = "2px solid var(--error-color)";
					validationError = true;
				} else {
					mainContainerRef.current.querySelector(`#activateAfterDays-${discountIndex}`).style.border = "";
				}
			}

			if (validationError) {
				toast.error("Nie wszystkie pola zostały wypełnione poprawnie, sprawdź formularz i spróbuj ponownie");
				return;
			}

			const { message } = await saveDiscountChanges({ discountConditions: discountList, endOfferLife: { enableOption: enableEndOfferLife, daysUntilDisabled } });
			toast.success(message);
			navigate("../", { state: stateMode.Refresh });
		} catch (error) {
			toast.error(error?.message || "Wystąpił problem z zapisem zmian");
		}
	};

	const handleChangeEndOfferLifeCheckbox = () => {
		endOfferLifeInput.current.disabled = enableEndOfferLife;
		setEnableEndOfferLife(!enableEndOfferLife);
	};

	useEffect(() => {
		toast.error(hasError);
	}, [hasError]);

	const renderDiscountRow = ({ discountRate, activateAfterDays, rowTitle }, discountIndex = 0) => {
		return (
			<div className={styles.rowContainer} key={`discount-${discountIndex}`}>
				<div className={styles.rowControls}>
					<h3>{rowTitle ? rowTitle : "Rabat"}</h3>
					<tooptip title={`Usuń rabat ${discountIndex + 1}`}>
						<Button
							style={{ padding: "4px 5px", boxShadow: "unset", display: discountRate === false && activateAfterDays === false ? "none" : "block" }}
							onClick={(e) => {
								e.preventDefault();
								handleRemoveDiscount(discountIndex);
							}}
						>
							<Icon path={mdiTrashCan} size={1} />
						</Button>
					</tooptip>
				</div>

				<div className={styles.rowInputs}>
					<fieldset>
						<legend htmlFor="discountRate">Wartość rabatu (podaj w % np. 12.34)</legend>
						<input
							type="text"
							name="discountRate"
							disabled={discountRate === false}
							id={`discountRate-${discountIndex}`}
							defaultValue={discountRate === false ? "" : discountRate}
							onChange={(e) => {
								handleChangeDiscountRow(e, "discountRate", discountIndex);
							}}
						/>
					</fieldset>
					<fieldset>
						<legend htmlFor="activateAfterDays">Aktywuj po (ilość dni)</legend>
						<input
							type="text"
							name="activateAfterDays"
							disabled={activateAfterDays === false}
							id={`activateAfterDays-${discountIndex}`}
							defaultValue={activateAfterDays === false ? "" : activateAfterDays}
							onChange={(e) => {
								handleChangeDiscountRow(e, "activateAfterDays", discountIndex);
							}}
						/>
					</fieldset>
				</div>
			</div>
		);
	};

	return (
		<Draggable nodeRef={nodeRef} handle={`.${styles.header}`} cancel={"button"} disabled={isMobile} positionOffset={{ x: "-50%", y: "-50%" }}>
			<div ref={nodeRef} className={styles.container}>
				<header className={styles.header}>
					<h2>Ustaw rabatowanie</h2>
					<NavButtonLink type="reset" to="../" state={stateMode.Refresh}>
						&#x2715;
					</NavButtonLink>
				</header>

				<div className={styles.form}>
					<div className={styles.mainContainer} ref={mainContainerRef}>
						{loading
							? renderDiscountRow({ discountRate: false, activateAfterDays: false, rowTitle: "Ładowanie .." }, 0)
							: discountList.length > 0
							? discountList.map(renderDiscountRow)
							: renderDiscountRow({ discountRate: false, activateAfterDays: false, rowTitle: "Dodaj rabat przyciskiem poniżej" }, 0)}

						<div className={styles.rowInputs} style={{ background: "#1a1a1a30" }}>
							<fieldset>
								<legend htmlFor="endOfferLife">Produkt zablokowany po (ilość dni)</legend>
								<input type="text" name="endOfferLife" id="endOfferLife" ref={endOfferLifeInput} defaultValue={""} />
							</fieldset>
							<div style={{ alignSelf: "center" }}>
								<tooptip title="Aktywuj blokadę produktu po czasie">
									<Checkbox checked={enableEndOfferLife} onChange={handleChangeEndOfferLifeCheckbox} />
								</tooptip>
							</div>
						</div>

						<Button onClick={handleAddDiscount}>Dodaj kolejny rabat</Button>
					</div>

					<footer>
						<Button onClick={handleSubmit} loading={loading || hasError}>
							Zapisz zmiany
						</Button>
					</footer>
				</div>
			</div>
		</Draggable>
	);
};

export default VendingSetDiscount;

import React, { useState } from "react";
import styles from "styles/DeviceConfig.module.css";
import { useFetch } from "hooks/useFetch";
import PathsAPI from "constants/PathsAPI";
import { useNavigate, useLocation } from "react-router-dom";
import Button from "components/Button";
import NavButtonLink from "components/NavButtonLink";
import { useEffect, useRef } from "react";
import Draggable from "react-draggable";
import stateMode from "constants/stateMode";
import { toast } from "react-toastify";
import { useAuthContext } from "hooks/useAuthContext";

const ChangeOrdersStatus = () => {
	const nodeRef = useRef(null);
	const location = useLocation();

	const { usePut } = useFetch(PathsAPI.Orders);
	const { request, loading } = usePut();
	const [selectedRows, setSelectedRows] = useState([]);

	const { user } = useAuthContext();
	const isAdmin = user.role === "ADMIN";
	const isGlobal = user.supplier === "GLOBAL";
	const isDeliverer = user.role === "DELIVERER";

	let navigate = useNavigate();

	const handleSubmit = async (e) => {
		e.preventDefault();

		let newOrderStatus = new FormData(e.target).get("status");

		const promises = [];

		for (const orderId of selectedRows) {
			promises.push(request({ version: "V2", url: `/${orderId}`, body: { status: newOrderStatus } }));
		}

		let responses = {};

		await (
			await Promise.allSettled(promises)
		).forEach((response, index) => {
			responses[selectedRows[index]] = response;
			if (response.status === "rejected") {
				toast.error(
					<>
						<b>ID zamówienia: {selectedRows[index]}</b>
						<br />
						{response.reason?.message || "Wystąpił problem z zapisem zmiany statusu zamówienia"}
					</>,
					{ autoClose: false }
				);
			}
		});

		navigate("../", { state: { mode: stateMode.RefreshWithResponse, responses } });
	};

	useEffect(() => {
		if (location.state?.selectedRows) {
			setSelectedRows(location.state.selectedRows);
		}
	}, [location]);

	return (
		<Draggable nodeRef={nodeRef} handle={`.${styles.header}`}>
			<div ref={nodeRef} className={styles.container}>
				<div className={styles.header}>
					<h2>Edytuj status zamówień</h2>
					<NavButtonLink type="reset" to="../" state={stateMode.Refresh}>
						&#x2715;
					</NavButtonLink>
				</div>
				<form className={styles.form} onSubmit={handleSubmit}>
					<fieldset>
						<legend>Status</legend>
						<select className={styles.select} name="status">
							<option value={"WAIT_FOR_ACCEPT"}>Oczekuje na zatwierdzenie</option>
							<option value={"ACCEPTED_TO_DELIVER"}>Przyjęte do dostarczenia</option>
							<option value={"DECLINED"}>Odrzucone</option>
							<option value={"DELIVERED"}>Dostarczone</option>
							{(isAdmin || (isGlobal && isDeliverer)) && <option value={"RECEIVED"}>Odebrane</option>}
						</select>
					</fieldset>

					<h3>Wybranych wierszy: {selectedRows.length}</h3>

					<div className={styles.buttons}>
						<Button type="submit" loading={loading}>
							Zapisz zmiany
						</Button>
					</div>
				</form>
			</div>
		</Draggable>
	);
};

export default ChangeOrdersStatus;

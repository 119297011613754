import React from "react";

const voucherTable = {
	name: {
		name: "Nazwa",
	},
	shopName: {
		name: "Dostawca",
		parser: (value) => {
			return value?.name || "Błąd";
		},
		hideColumn: ({ supplier }) => {
			return !["GLOBAL"].includes(supplier);
		},
	},
	productId: {
		name: "Produkt",

		parser: (productId, { productsList }) => {
			if (!productId) {
				return "Błąd";
			}

			return productsList?.find((product) => product._id === productId)?.name || "Błąd";
		},
		oneLine: true,
	},
	usersId: {
		name: "Przypisanych użytkowników",
		parser: (usersId) => {
			return usersId.length;
		},
	},
	createdAt: {
		name: "Data dodania",
		isDate: true,
		filter: (value) => {
			return new Date(value);
		},
		parser: (value) => {
			const date = new Date(value).toLocaleString("pl").split(", ");
			return (
				<>
					{date[0]}
					<br />
					{date[1]}
				</>
			);
		},
	},
	updatedAt: {
		name: "Data aktualizacji",
		isDate: true,
		filter: (value) => {
			return new Date(value);
		},
		parser: (value) => {
			const date = new Date(value).toLocaleString("pl").split(", ");
			return (
				<>
					{date[0]}
					<br />
					{date[1]}
				</>
			);
		},
	},
};

export default voucherTable;

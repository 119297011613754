import React from "react";
import styles from "styles/CategoryEdit.module.css";
import { useFetch } from "hooks/useFetch";
import PathsAPI from "constants/PathsAPI";
import Button from "components/Button";
import NavButtonLink from "components/NavButtonLink";
import { useEffect, useRef } from "react";
import Draggable from "react-draggable";
import stateMode from "constants/stateMode";
import { toast } from "react-toastify";
import Icon from "@mdi/react";
import { mdiContentSave, mdiTrashCan } from "@mdi/js";

const CategoryEdit = () => {
	const nodeRef = useRef(null);
	const { useGet, usePut } = useFetch(PathsAPI.EditCategories);
	const { request: reloadCategories, response: listCategories, hasError } = useGet(true);
	const { request: updateCategories, hasError: hasPutError } = usePut();
	const listRef = useRef();

	const handleDeleteCategory = async (id) => {
		const toastId = toast.loading("Ładowanie ..");
		await updateCategories({ mode: "delete", id });
		await reloadCategories();
		toast.dismiss(toastId);
	};

	const handleAddNewCategory = async () => {
		const toastId = toast.loading("Ładowanie ..");
		await updateCategories({ mode: "new" });
		await reloadCategories();
		toast.dismiss(toastId);
	};

	const handleChangeCategory = async (id, value) => {
		const toastId = toast.loading("Ładowanie ..");
		await updateCategories({ mode: "change", id, value });
		await reloadCategories();
		toast.dismiss(toastId);
	};

	useEffect(() => {
		toast.error(hasError);
	}, [hasError]);

	useEffect(() => {
		toast.error(hasPutError);
	}, [hasPutError]);

	return (
		<Draggable nodeRef={nodeRef} handle={`.${styles.header}`}>
			<div ref={nodeRef} className={styles.container}>
				<div className={styles.header}>
					<h2>Zarządzaj kategoriami</h2>
					<NavButtonLink type="reset" to="../" state={stateMode.Refresh}>
						&#x2715;
					</NavButtonLink>
				</div>
				<div className={styles.mainContainer}>
					<div className={styles.form}>
						<div ref={listRef} className={styles.formContainer}>
							{listCategories ? (
								listCategories.length > 0 ? (
									listCategories.map((category) => (
										<div key={category._id} style={{ display: "flex", justifyContent: "space-between" }}>
											<input
												required
												type="text"
												name={`category-${category._id}`}
												placeholder="Podaj tytuł kategorii .."
												defaultValue={category.name}
												onChange={() => {
													listRef.current.querySelector(`svg[name=category-save-${category._id}]`).style.color = "var(--error-color)";
												}}
											/>
											<Button onClick={() => handleDeleteCategory(category._id)}>
												<Icon path={mdiTrashCan} size={1} />
											</Button>
											<Button
												onClick={() => {
													handleChangeCategory(
														category._id,
														listRef.current.querySelector(`input[name=category-${category._id}]`).value
													);
													listRef.current.querySelector(`svg[name=category-save-${category._id}]`).style.color = "";
												}}
											>
												<Icon name={`category-save-${category._id}`} path={mdiContentSave} size={1} />
											</Button>
										</div>
									))
								) : (
									<div>Lista jest pusta, dodaj kategorie</div>
								)
							) : (
								<div>Ładowanie</div>
							)}
							<Button onClick={handleAddNewCategory}>Dodaj nową kategorię</Button>
						</div>
					</div>
				</div>
			</div>
		</Draggable>
	);
};

export default CategoryEdit;

import React, { useEffect } from "react";
import { useFetch } from "hooks/useFetch";
import PathsAPI from "constants/PathsAPI";
import styles from "styles/Devices.module.css";
import { Outlet, useLocation } from "react-router-dom";
import Button from "components/Button";
import { mdiNoteEdit } from "@mdi/js";
import stateMode from "constants/stateMode";
import NavButtonLink from "components/NavButtonLink";
import TableComponent from "components/Table";
import shopsTable from "constants/tables/shopsTable";

const ShopsList = () => {
	const { useGet } = useFetch(`${PathsAPI.Suppliers}?authorizedOnly=1`);
	const { request, response, loading, hasError } = useGet(true);
	const location = useLocation();

	useEffect(() => {
		if (location.state === stateMode.Refresh) {
			request();
			location.state = null;
		}
	}, [location, request]);

	const useHandleLoadData = () => {
		request();
	};

	const shopsTableActions = [
		{
			type: "edit",
			name: "Edytuj",
			icon: mdiNoteEdit,
			navigate: (row) => {
				return `${row._id}/edit`;
			},
		},
	];

	return (
		<div className={styles.main}>
			<div className={styles.panelContainer}>
				<div className={styles.header}>
					<h2>Lista dostawców</h2>
					<div>
						<NavButtonLink to="create">Dodaj nowego dostawcę</NavButtonLink>
						<Button loading={loading} error={hasError} onClick={useHandleLoadData}>
							Odśwież
						</Button>
					</div>
				</div>

				<TableComponent title="Lista dostawców" headValues={shopsTable} bodyValues={response} error={hasError} actions={shopsTableActions} />
			</div>
			<Outlet />
		</div>
	);
};

export default ShopsList;

import React from "react";
import styles from "styles/VoucherEdit.module.css";
import { useFetch } from "hooks/useFetch";
import PathsAPI from "constants/PathsAPI";
import { useNavigate, useParams } from "react-router-dom";
import Button from "components/Button";
import NavButtonLink from "components/NavButtonLink";
import { useEffect, useRef, useState } from "react";
import Draggable from "react-draggable";
import stateMode from "constants/stateMode";
import { toast } from "react-toastify";
import { useAuthContext } from "hooks/useAuthContext";

const VoucherEdit = () => {
	const nodeRef = useRef(null);
	let params = useParams();
	const { useGet, usePut, useDelete } = useFetch(`${PathsAPI.Voucher}/${params.id}`);
	const { response: voucherInfo } = useGet(true);
	const { request, response, loading, hasError } = usePut();
	const { request: delReq, response: delRes, loading: delLoading, hasError: delHasError } = useDelete();
	let navigate = useNavigate();

	const { useGet: useSuppliersGet } = useFetch(PathsAPI.Suppliers);
	const { response: suppliersList } = useSuppliersGet(true);

	const { useGet: useProductsGet } = useFetch(PathsAPI.Products);
	const { response: resProductList, loading: loadingProducts, hasError: hasProductsError } = useProductsGet(true);

	const { useGet: useUserGet } = useFetch(PathsAPI.Users);
	const { response: resUsersList } = useUserGet(true);

	const { user } = useAuthContext();
	const isGlobal = user.supplier === "GLOBAL";

	const [productList, setProductList] = useState([]);
	const [productId, setProductId] = useState();

	const [usersList, setUsersList] = useState([]);
	const [usersId, setUsersId] = useState({});

	useEffect(() => {
		if (!voucherInfo) {
			return;
		}

		setUsersId(voucherInfo.usersId.reduce((a, v) => ({ ...a, [v]: true }), {}));

		setProductId(voucherInfo.productId);
	}, [voucherInfo]);

	const handleSubmit = (e) => {
		e.preventDefault();
		let result = Object.fromEntries(new FormData(e.target));
		result.active = (result.active || "off") === "on";

		result.productId = productId;
		result.usersId = Object.keys(usersId).filter((k) => usersId[k]);

		request(result);
	};

	const handleDelete = () => {
		delReq();
	};

	useEffect(() => {
		(delRes?._id || response?._id) && navigate("../", { state: stateMode.Refresh });
	}, [delRes, response, navigate]);

	useEffect(() => {
		toast.error(hasError);
	}, [hasError]);

	useEffect(() => {
		toast.error(delHasError);
	}, [delHasError]);

	useEffect(() => {
		toast.error(hasProductsError);
	}, [hasProductsError]);

	useEffect(() => {
		if (resProductList) {
			setProductList(resProductList);
		}
	}, [resProductList]);

	useEffect(() => {
		if (resUsersList) {
			setUsersList(resUsersList);
		}
	}, [resUsersList]);

	const handleChangeProductSearchInput = (e) => {
		const searchText = e.target.value.toLowerCase();

		const result = resProductList.filter((product) => {
			const formattedName = product?.name?.toLowerCase();

			if (!formattedName) {
				return false;
			}

			return formattedName?.includes(searchText);
		});

		setProductList(result);
	};

	const handleChangeUserSearchInput = (e) => {
		const searchText = e.target.value.toLowerCase();

		const result = resUsersList.filter((user) => {
			const formattedName = `${user.details.fname} ${user.details.lname} (${user.details.phone}) (${user._id})`.toLowerCase();

			if (!formattedName) {
				return false;
			}

			return formattedName?.includes(searchText);
		});

		setUsersList(result);
	};

	const getSupplierName = (supplierId) => {
		return suppliersList?.find((supplier) => supplier._id === supplierId)?.name;
	};

	const handleSelectProduct = (productId) => {
		setProductId(productId);
	};

	const handleSelectUser = (userId) => {
		setUsersId({ ...usersId, [userId]: !usersId[userId] });
	};

	return (
		<Draggable nodeRef={nodeRef} handle={`.${styles.header}`}>
			<div ref={nodeRef} className={styles.container}>
				<div className={styles.header}>
					<h2>Edytuj dane vouchera</h2>
					<NavButtonLink type="reset" to="../" state={stateMode.Refresh}>
						&#x2715;
					</NavButtonLink>
				</div>
				<div className={styles.mainContainer}>
					<form className={styles.form} onSubmit={handleSubmit}>
						<div className={styles.formContainer}>
							<fieldset>
								<legend>Nazwa</legend>
								<input required type="text" name="name" placeholder="Podaj nazwę vouchera .." defaultValue={voucherInfo?.name} />
							</fieldset>

							<div>
								<div style={{ display: "flex" }}>
									<div>
										<fieldset>
											<legend>Wyszukiwarka użytkowników</legend>
											<input type="text" placeholder="Wpisz szukaną frazę.." onChange={handleChangeUserSearchInput} />
										</fieldset>
										<div className={styles.productContainer}>
											{usersList &&
												usersList.map((user) => (
													<fieldset
														key={user._id}
														className={styles.productListItem}
														style={usersId[user._id] === true ? { background: "var(--primary)" } : {}}
														onClick={() => handleSelectUser(user._id)}
													>
														<label htmlFor={`product-${user._id}`}>
															{user.details.fname} {user.details.lname} ({user.details.phone}) ({user._id})
														</label>
													</fieldset>
												))}
										</div>
									</div>

									<div>
										<fieldset>
											<legend>Wyszukiwarka produktów</legend>
											<input type="text" placeholder="Wpisz szukaną frazę.." onChange={handleChangeProductSearchInput} />
										</fieldset>
										<div className={styles.productContainer}>
											{productList &&
												productList.map((product) => (
													<fieldset
														key={product._id}
														className={styles.productListItem}
														style={productId === product._id ? { background: "var(--primary)" } : {}}
														onClick={() => handleSelectProduct(product._id)}
													>
														<label htmlFor={`product-${product._id}`}>
															{product.name} ({product._id})
															{isGlobal && (
																<>
																	<br />
																	{getSupplierName(product.supplier)}
																</>
															)}
														</label>
													</fieldset>
												))}
										</div>
									</div>
								</div>
							</div>
						</div>

						<div className={styles.buttons}>
							{voucherInfo !== null && (
								<fieldset className={styles.checkbox}>
									<label htmlFor="active">Aktywny</label>
									<span />
									<input type="checkbox" name="active" id="active" defaultChecked={voucherInfo.active} />
								</fieldset>
							)}
							<Button type="reset" loading={delLoading} onClick={handleDelete}>
								Usuń
							</Button>
							<Button type="submit" loading={loading && loadingProducts}>
								Zapisz zmiany
							</Button>
						</div>
					</form>
				</div>
			</div>
		</Draggable>
	);
};

export default VoucherEdit;

import React from "react";
import styles from "styles/UsersEdit.module.css";
import { useFetch } from "hooks/useFetch";
import PathsAPI from "constants/PathsAPI";
import { useNavigate, useParams } from "react-router-dom";
import Button from "components/Button";
import NavButtonLink from "components/NavButtonLink";
import { useEffect, useRef, useState } from "react";
import Draggable from "react-draggable";
import stateMode from "constants/stateMode";

import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import locale from "date-fns/locale/pl";
import { TimePicker } from "@mui/x-date-pickers";

import timeToDate from "constants/timeToDate";

const timeParser = (date) => {
	return new Date(date).toLocaleTimeString(navigator.language, {
		hour: "2-digit",
		minute: "2-digit",
	});
};

const ShopEdit = () => {
	let navigate = useNavigate();
	const nodeRef = useRef(null);
	let params = useParams();
	const { useGet, usePut } = useFetch(`${PathsAPI.Suppliers}/${params.id}`);
	const { response: supplierInfo } = useGet(true);
	const { request, response, loading, hasError } = usePut();

	const [shopMaxOrderingTime, setShopMaxOrderingTime] = useState(new Date());
	const [shopMaxOrderingTimePicker, setShopMaxOrderingTimePicker] = useState(false);

	const [shopOpenTime, setShopOpenTime] = useState(new Date());
	const [shopOpenTimePicker, setShopOpenTimePicker] = useState(false);

	const [shopCloseTime, setShopCloseTime] = useState(new Date());
	const [shopCloseTimePicker, setShopCloseTimePicker] = useState(false);

	const shopOpenTimePickerRef = useRef();
	const shopCloseTimePickerRef = useRef();
	const shopMaxOrderingTimePickerRef = useRef();

	const weekDaysRef = useRef();
	const handleCheckWeekDay = (e) => {
		const elm = e.target;
		if (elm.className === styles.active) {
			elm.className = "";
		} else {
			elm.className = styles.active;
		}
	};

	useEffect(() => {
		if (!supplierInfo) {
			return;
		}

		supplierInfo.weekDays.forEach((weekDay) => {
			const elm = weekDaysRef.current.querySelector(`div#${weekDay}`);
			elm.className = styles.active;
		});
	}, [supplierInfo]);

	const handleSubmit = (e) => {
		e.preventDefault();
		let result = Object.fromEntries(new FormData(e.target));

		const weekDays = [...weekDaysRef.current.querySelectorAll(`.${styles.active}`)].map((elm) => elm.innerText);
		result.weekDays = weekDays;

		result.maxOrdering = timeParser(shopMaxOrderingTime);
		result.openTime = timeParser(shopOpenTime);
		result.closeTime = timeParser(shopCloseTime);

		request(result);
	};

	useEffect(() => {
		response?._id && navigate("../", { state: stateMode.Refresh });
	}, [response, navigate]);

	useEffect(() => {
		if (!supplierInfo) {
			return;
		}

		setShopMaxOrderingTime(timeToDate(supplierInfo.maxOrdering));
		setShopOpenTime(timeToDate(supplierInfo.openTime));
		setShopCloseTime(timeToDate(supplierInfo.closeTime));
	}, [supplierInfo]);

	return (
		<Draggable nodeRef={nodeRef} handle={`.${styles.header}`}>
			<div ref={nodeRef} className={styles.container}>
				<div className={styles.header}>
					<h2>Edytuj dane dostawcy</h2>
					<NavButtonLink type="reset" to="../" state={stateMode.Refresh}>
						&#x2715;
					</NavButtonLink>
				</div>
				<form className={styles.form} onSubmit={handleSubmit}>
					<fieldset>
						<legend>Nazwa</legend>
						<input required type="text" name="name" placeholder="Podaj nazwę dostawcy .." defaultValue={supplierInfo?.name} />
					</fieldset>

					<fieldset>
						<legend>Adres URL logo</legend>
						<input required type="text" name="logoUrl" placeholder="Podaj adres URL logo .." defaultValue={supplierInfo?.logoUrl} />
					</fieldset>

					<fieldset>
						<legend>Maksymalna godzina przyjmowania zamówień</legend>
						<input
							type="text"
							value={timeParser(shopMaxOrderingTime)}
							onClick={() => {
								setShopMaxOrderingTimePicker(!shopMaxOrderingTimePicker);
							}}
							ref={shopMaxOrderingTimePickerRef}
						></input>
					</fieldset>

					<LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={locale}>
						<TimePicker
							open={shopMaxOrderingTimePicker}
							onClose={() => {
								setShopMaxOrderingTimePicker(false);
							}}
							slotProps={{ popper: { anchorEl: shopMaxOrderingTimePickerRef.current } }}
							value={shopMaxOrderingTime}
							onChange={(value) => {
								setShopMaxOrderingTime(value);
							}}
							slots={{ field: () => <></> }}
						/>
					</LocalizationProvider>

					<div style={{ display: "flex", alignItems: "center" }}>
						<fieldset>
							<legend>Rozpoczęcie dostarczania</legend>
							<input
								type="text"
								value={timeParser(shopOpenTime)}
								onClick={() => {
									setShopOpenTimePicker(!shopOpenTimePicker);
								}}
								ref={shopOpenTimePickerRef}
							></input>
						</fieldset>

						<LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={locale}>
							<TimePicker
								open={shopOpenTimePicker}
								onClose={() => {
									setShopOpenTimePicker(false);
								}}
								slotProps={{ popper: { anchorEl: shopOpenTimePickerRef.current } }}
								value={shopOpenTime}
								onChange={(value) => {
									setShopOpenTime(value);
								}}
								slots={{ field: () => <></> }}
							/>
						</LocalizationProvider>

						<fieldset>
							<legend>Zakończenie dostarczania</legend>
							<input
								type="text"
								value={timeParser(shopCloseTime)}
								onClick={() => {
									setShopCloseTimePicker(!shopCloseTimePicker);
								}}
								ref={shopCloseTimePickerRef}
							></input>
						</fieldset>

						<LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={locale}>
							<TimePicker
								open={shopCloseTimePicker}
								onClose={() => {
									setShopCloseTimePicker(false);
								}}
								slotProps={{ popper: { anchorEl: shopCloseTimePickerRef.current } }}
								value={shopCloseTime}
								onChange={(value) => {
									setShopCloseTime(value);
								}}
								slots={{ field: () => <></> }}
							/>
						</LocalizationProvider>
					</div>

					<fieldset>
						<legend>Czas na odebranie zamówienia (w godzinach)</legend>
						<input required type="text" name="maxReceiveTime" placeholder="Podaj czas w godzinach .." defaultValue={supplierInfo?.maxReceiveTime} />
					</fieldset>

					<fieldset>
						<legend>Minimalna wartość koszyka (PLN)</legend>
						<input required type="text" name="minCartCost" placeholder="Podaj minimalną kwotę (PLN) .." defaultValue={supplierInfo?.minCartCost} />
					</fieldset>

					<fieldset>
						<legend>Dni otwarcia</legend>
						<div ref={weekDaysRef} className={styles.selectWeekDaysContainer}>
							<div id="PON" onClick={handleCheckWeekDay}>
								PON
							</div>
							<span />
							<div id="WT" onClick={handleCheckWeekDay}>
								WT
							</div>
							<span />
							<div id="ŚR" onClick={handleCheckWeekDay}>
								ŚR
							</div>
							<span />
							<div id="CZW" onClick={handleCheckWeekDay}>
								CZW
							</div>
							<span />
							<div id="PT" onClick={handleCheckWeekDay}>
								PT
							</div>
							<span />
							<div id="SOB" onClick={handleCheckWeekDay}>
								SOB
							</div>
							<span />
							<div id="NIEDZ" onClick={handleCheckWeekDay}>
								NIEDZ
							</div>
						</div>
					</fieldset>

					{hasError && <div className="error">{hasError?.message}</div>}

					<div className={styles.buttons}>
						<Button type="submit" loading={loading}>
							Zapisz zmiany
						</Button>
					</div>
				</form>
			</div>
		</Draggable>
	);
};

export default ShopEdit;

import React from "react";
import styles from "styles/NewVoucher.module.css";
import { useFetch } from "hooks/useFetch";
import PathsAPI from "constants/PathsAPI";
import Button from "components/Button";
import NavButtonLink from "components/NavButtonLink";
import { useNavigate } from "react-router-dom";
import { useEffect, useRef, useState } from "react";
import Draggable from "react-draggable";
import stateMode from "constants/stateMode";
import { toast } from "react-toastify";
import { useAuthContext } from "hooks/useAuthContext";

const NewVoucher = () => {
	const nodeRef = useRef(null);
	const { usePost } = useFetch(PathsAPI.Voucher);
	const { request, response, loading, hasError } = usePost();
	const navigate = useNavigate();

	const { useGet: useSuppliersGet } = useFetch(PathsAPI.Suppliers);
	const { response: suppliersList } = useSuppliersGet(true);

	const { useGet: useProductsGet } = useFetch(PathsAPI.Products);
	const { response: resProductList, loading: loadingProducts, hasError: hasProductsError } = useProductsGet(true);

	const { useGet } = useFetch(PathsAPI.Users);
	const { response: resUsersList } = useGet(true);

	const [productList, setProductList] = useState([]);
	const [productId, setProductId] = useState();

	const [usersList, setUsersList] = useState([]);
	const [usersId, setUsersId] = useState({});

	const { user } = useAuthContext();
	const isGlobal = user.supplier === "GLOBAL";

	const handleSubmit = (e) => {
		e.preventDefault();
		let result = Object.fromEntries(new FormData(e.target));

		result.productId = productId;
		result.usersId = Object.keys(usersId).filter((k) => usersId[k]);

		request(result);
	};

	useEffect(() => {
		response?._id && navigate("../", { state: stateMode.Refresh });
	}, [response, navigate]);

	useEffect(() => {
		toast.error(hasError);
	}, [hasError]);

	useEffect(() => {
		toast.error(hasProductsError);
	}, [hasProductsError]);

	useEffect(() => {
		if (resProductList) {
			setProductList(resProductList);
		}
	}, [resProductList]);

	useEffect(() => {
		if (resUsersList) {
			setUsersList(resUsersList);
		}
	}, [resUsersList]);

	const handleChangeProductSearchInput = (e) => {
		const searchText = e.target.value.toLowerCase();

		const result = resProductList.filter((product) => {
			const formattedName = product?.name?.toLowerCase();

			if (!formattedName) {
				return false;
			}

			return formattedName?.includes(searchText);
		});

		setProductList(result);
	};

	const handleChangeUserSearchInput = (e) => {
		const searchText = e.target.value.toLowerCase();

		const result = resUsersList.filter((user) => {
			const formattedName = `${user.details.fname} ${user.details.lname} (${user.details.phone}) (${user._id})`.toLowerCase();

			if (!formattedName) {
				return false;
			}

			return formattedName?.includes(searchText);
		});

		setUsersList(result);
	};

	const getSupplierName = (supplierId) => {
		return suppliersList?.find((supplier) => supplier._id === supplierId)?.name;
	};

	const handleSelectProduct = (productId) => {
		setProductId(productId);
	};

	const handleSelectUser = (userId) => {
		setUsersId({ ...usersId, [userId]: !usersId[userId] });
	};

	return (
		<Draggable nodeRef={nodeRef} handle={`.${styles.header}`}>
			<div ref={nodeRef} className={styles.container}>
				<div className={styles.header}>
					<h2>Dodaj nowy voucher</h2>
					<NavButtonLink type="reset" to="../" state={stateMode.Refresh}>
						&#x2715;
					</NavButtonLink>
				</div>
				<div className={styles.mainContainer}>
					<form className={styles.form} onSubmit={handleSubmit}>
						<div className={styles.formContainer}>
							<fieldset>
								<legend>Nazwa</legend>
								<input required type="text" name="name" placeholder="Podaj nazwę vouchera .." />
							</fieldset>

							<div style={{ display: "flex" }}>
								<div>
									<fieldset>
										<legend>Wyszukiwarka użytkowników</legend>
										<input type="text" placeholder="Wpisz szukaną frazę.." onChange={handleChangeUserSearchInput} />
									</fieldset>
									<div className={styles.productContainer}>
										{usersList &&
											usersList.map((user) => (
												<fieldset
													key={user._id}
													className={styles.productListItem}
													style={usersId[user._id] === true ? { background: "var(--primary)" } : {}}
													onClick={() => handleSelectUser(user._id)}
												>
													<label htmlFor={`product-${user._id}`}>
														{user.details.fname} {user.details.lname} ({user.details.phone}) ({user._id})
													</label>
												</fieldset>
											))}
									</div>
								</div>

								<div>
									<fieldset>
										<legend>Wyszukiwarka produktów</legend>
										<input type="text" placeholder="Wpisz szukaną frazę.." onChange={handleChangeProductSearchInput} />
									</fieldset>
									<div className={styles.productContainer}>
										{productList &&
											productList.map((product) => (
												<fieldset
													key={product._id}
													className={styles.productListItem}
													style={productId === product._id ? { background: "var(--primary)" } : {}}
													onClick={() => handleSelectProduct(product._id)}
												>
													<label htmlFor={`product-${product._id}`}>
														{product.name} ({product._id})
														{isGlobal && (
															<>
																<br />
																{getSupplierName(product.supplier)}
															</>
														)}
													</label>
												</fieldset>
											))}
									</div>
								</div>
							</div>
						</div>

						<div className={styles.buttons}>
							<Button type="submit" loading={loading && loadingProducts}>
								Dodaj
							</Button>
						</div>
					</form>
				</div>
			</div>
		</Draggable>
	);
};

export default NewVoucher;

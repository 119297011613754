import React, { useEffect, useState } from "react";
import tableStyles from "styles/Table.module.css";
import Icon from "@mdi/react";
import { useAuthContext } from "hooks/useAuthContext";
import { mdiArrowLeft, mdiArrowRight, mdiEye, mdiEyeOff, mdiSearchWeb, mdiWrap, mdiWrapDisabled } from "@mdi/js";
import NavButtonIcon from "./NavButtonIcon";
import Button from "./Button";
import csvDownload from "json-to-csv-export";
import TableDateControls from "./TableDateControls";
import { mdiCheck } from "@mdi/js";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import stateMode from "constants/stateMode";

const renderComponentTypes = {
	LOADING: "LOADING",
	ERROR: "ERROR",
	EMPTY: "EMPTY",
	TABLE: "TABLE",
};

const TableComponent = ({
	title,
	children,
	headValues,
	bodyValues,
	error = false,
	rowAction = null,
	actions = [],
	className,
	additionalValues = {},
	loadedAdditionalValues,
	exportParser = (body) => {
		return body;
	},
	exportHeaders = false,
	selectRows = {
		columnIndexName: "_id",
		enabled: false,
		multiActionButton: {
			title: "Zaznacz wszystkie",
			onClick: (selectedRows) => {
				console.log(selectedRows);
			},
		},
	},
	...props
}) => {
	const isMobile = window.innerWidth < 700;

	const { user } = useAuthContext();
	const [filterDialog, setFilterDialog] = useState(false);
	const [filters, setFilters] = useState({});
	const [page, setPage] = useState(1);
	const [pages, setPages] = useState(1);
	const [rowsPerPage, setRowsPerPage] = useState(50);
	const [tableBody, setTableBody] = useState([]);
	const [renderComponent, setRenderComponent] = useState(renderComponentTypes.LOADING);
	const [hiddenColumns, setHiddenColumns] = useState({});
	const [wrapColumnText, setWrapColumnText] = useState({});
	const [mobileView, setMobileView] = useState(isMobile);
	const [selectedRows, setSelectedRows] = useState({});

	let navigate = useNavigate();
	let location = useLocation();

	const getRawValue = (row, key) => {
		const keys = key.split(".");
		const result = keys.reduce((obj, key) => obj[key], row);
		return result;
	};

	const hasHiddenColumns = () => {
		return Object.values(hiddenColumns).filter(Boolean).length > 0;
	};

	const handleSelectRow = (rowIndex) => {
		let newSelectedRows = { ...selectedRows };
		newSelectedRows = { ...newSelectedRows, [rowIndex]: !newSelectedRows[rowIndex] };
		navigate(".", { state: { tableSelectedRows: newSelectedRows } });
	};

	useEffect(() => {
		if (location.state?.tableSelectedRows) {
			setSelectedRows(location.state.tableSelectedRows);
		} else if (location.state === stateMode.Refresh) {
			setSelectedRows({});
		} else if (location.state?.mode === stateMode.RefreshWithResponse) {
			setSelectedRows(location.state.responses);
		}
	}, [location]);

	const getCheckboxColor = (checkboxStatus) => {
		if (checkboxStatus?.status === "rejected") {
			return "red";
		} else if (checkboxStatus?.status === "fulfilled") {
			return "green";
		}

		return "";
	};

	const renderTable = (renderValue) => {
		switch (renderValue) {
			case "LOADING":
				return (
					<tr>
						<td colSpan="100%">Ładowanie ..</td>
					</tr>
				);
			case "TABLE":
				return tableBody.map((row, rowIndex) => {
					return (
						<>
							<tr
								key={`row-${rowIndex}`}
								style={rowAction ? { cursor: "pointer" } : {}}
								onClick={() => {
									rowAction && rowAction(row);
								}}
							>
								{Object.entries(headValues)
									.map(([key, value]) => {
										if (hiddenColumns[key]) {
											return false;
										}

										if (
											value.hideColumn &&
											value.hideColumn({
												role: user.role,
												supplier: user.supplier,
											})
										) {
											return false;
										}

										let resultText = getRawValue(row, key);

										if (value.parser) {
											resultText = value.parser(resultText, {
												row,
												...additionalValues,
											});
										}

										return (
											<td key={`field-${key}`}>
												<div
													style={
														wrapColumnText[key]
															? {
																	wordSpacing: "100vw",
																	maxWidth: "min-content",
															  }
															: {}
													}
												>
													{resultText}
												</div>
											</td>
										);
									})
									.filter(Boolean)}
								{actions
									.map((value) => {
										if (value.hideColumn && value.hideColumn()) {
											return false;
										}

										if (value.viewRowValue && !value.viewRowValue(row)) {
											return <td key={`field-action-${value.type}`} name={value.type}></td>;
										}

										return (
											<td key={`field-action-${value.type}`} name={value.type} className={tableStyles.icon} onClick={(e) => e.stopPropagation()} style={value.actionStyle ? value.actionStyle(row) : {}}>
												<NavButtonIcon
													disabled={value.disabled ? value.disabled(row) : null}
													onClick={
														value.onClick
															? () => {
																	value.onClick(row);
															  }
															: null
													}
													to={value.navigate ? value.navigate(row) : ""}
												>
													<Icon path={value.icon} size={1} />
												</NavButtonIcon>
											</td>
										);
									})
									.filter(Boolean)}
								{selectRows.enabled && (
									<td
										className={tableStyles.selectRowCheckbox}
										onClick={(event) => {
											event.stopPropagation();
											handleSelectRow(row[selectRows.columnIndexName]);
										}}
									>
										<div>
											{selectedRows[row[selectRows.columnIndexName]] ? (
												<Icon path={mdiCheck} size={1.5} color={getCheckboxColor(selectedRows[row[selectRows.columnIndexName]])} />
											) : (
												<Icon path={mdiCheck} className={tableStyles.checkboxHover} color="gray" size={1.5} />
											)}
										</div>
									</td>
								)}
							</tr>

							{hasHiddenColumns() && (
								<tr>
									<td colSpan="100%" className={tableStyles.additionalRow}>
										{Object.entries(hiddenColumns)
											.map(([key, value]) => {
												if (!value) {
													return false;
												}

												let resultText = getRawValue(row, key);

												if (headValues[key].parser) {
													resultText = headValues[key].parser(resultText, {
														row,
														...additionalValues,
													});
												}

												return (
													<p key={`row-info-${key}`} className={tableStyles.rowInfo}>
														<span title={`Pokaż kolumnę "${headValues[key].name}"`}>
															<div
																onClick={() => {
																	setHiddenColumns((oldValues) => {
																		return {
																			...oldValues,
																			[key]: !oldValues[key],
																		};
																	});
																}}
																className={searchButtonStyles(key)}
															>
																<Icon path={hiddenColumns[key] ? mdiEye : mdiEyeOff} size={1} />
															</div>
														</span>
														{headValues[key].name}: {resultText}
													</p>
												);
											})
											.filter(Boolean)}
									</td>
								</tr>
							)}
						</>
					);
				});
			case "EMPTY":
				return (
					<tr>
						<td colSpan="100%">Brak wierszy do wyświetlenia</td>
					</tr>
				);
			default:
				return (
					<tr>
						<td colSpan="100%">Wystąpił błąd.</td>
					</tr>
				);
		}
	};

	useEffect(() => {
		if (isMobile === mobileView && renderComponent !== renderComponentTypes.LOADING) {
			return;
		}

		let tempHiddenColumns = {};

		Object.entries(headValues).forEach(([key, value]) => {
			if (value.mobile === !isMobile) {
				tempHiddenColumns[key] = true;
			}
		});

		if (JSON.stringify(tempHiddenColumns) !== JSON.stringify(hiddenColumns)) {
			setHiddenColumns(tempHiddenColumns);
			setMobileView(isMobile);
		}
	}, [renderComponent, headValues, hiddenColumns, isMobile, mobileView]);

	useEffect(() => {
		if (error) {
			renderComponentTypes.ERROR !== renderComponent && setRenderComponent(renderComponentTypes.ERROR);
			return;
		}

		if (!Array.isArray(bodyValues)) {
			renderComponentTypes.LOADING !== renderComponent && setRenderComponent(renderComponentTypes.LOADING);
			return;
		}

		if (!additionalValues && loadedAdditionalValues && !loadedAdditionalValues.every(Boolean)) {
			renderComponentTypes.LOADING !== renderComponent && setRenderComponent(renderComponentTypes.LOADING);
			return;
		}

		if (bodyValues.length <= 0) {
			renderComponentTypes.EMPTY !== renderComponent && setRenderComponent(renderComponentTypes.EMPTY);
			return;
		}

		let resultBodyValues = bodyValues.filter((row) => {
			return Object.keys(filters).every((filterKey) => {
				if (headValues[filterKey].isDate) {
					const field = headValues[filterKey].filter(getRawValue(row, filterKey));
					const filterDate = filters[filterKey];

					if (field) {
						const [startTime, fieldTime, endTime] = [filterDate?.[0]?.getTime(), field.getTime(), filterDate?.[1]?.getTime()];

						if (startTime && !(startTime <= fieldTime)) {
							return false;
						}

						if (endTime && !(fieldTime <= endTime + 86400000)) {
							return false;
						}

						return true;
					} else {
						return true;
					}
				}

				let field = "";

				if (headValues[filterKey].filter) {
					field = headValues[filterKey]
						.filter(getRawValue(row, filterKey), { row, ...additionalValues })
						.toLowerCase()
						.trim();
				} else if (headValues[filterKey].parser) {
					field = headValues[filterKey]
						.parser(getRawValue(row, filterKey), { row, ...additionalValues })
						.toLowerCase()
						.trim();
				} else {
					field = String(getRawValue(row, filterKey)).toLowerCase().trim();
				}

				const valueParts = filters[filterKey].toLowerCase().trim().split(" ");

				return valueParts.every((valuePart) => {
					return field.includes(valuePart);
				});
			});
		});

		const newPages = Math.ceil(resultBodyValues.length / rowsPerPage);
		if (newPages !== pages) {
			if (newPages === 0) {
				if (page > 1) {
					setPage(1);
				}
				setPages(1);
			} else {
				if (page > newPages) {
					setPage(1);
				}
				setPages(newPages);
			}
		}

		resultBodyValues = resultBodyValues.slice((page - 1) * rowsPerPage, page * rowsPerPage);

		if (JSON.stringify(resultBodyValues) !== JSON.stringify(tableBody)) {
			setTableBody(resultBodyValues);
			setRenderComponent(renderComponentTypes.TABLE);
		}
	}, [
		error,
		bodyValues,
		setRenderComponent,
		renderComponent,
		filters,
		headValues,
		actions,
		rowAction,
		user.role,
		user.supplier,
		rowsPerPage,
		pages,
		tableBody.length,
		page,
		setTableBody,
		tableBody,
		additionalValues,
		loadedAdditionalValues,
	]);

	const handleOpenFilter = (key) => {
		if (key === filterDialog.key) {
			return setFilterDialog(false);
		}

		setFilterDialog({ key });
	};

	const searchButtonStyles = (key) => {
		let result = [tableStyles.headCellButton];

		if (filterDialog?.key === key) {
			result.push(tableStyles.headCellButtonActive);
		}

		if (filters[key]) {
			result.push(tableStyles.headCellButtonUse);
		}

		return result.join(" ");
	};

	const handleChangeSearchInput = (key, newValue) => {
		setFilters((oldValues) => {
			return { ...oldValues, [key]: newValue };
		});
	};

	const handleChangePage = (value) => {
		setPage(Number(value));
	};

	const handleChangeRowsPerPage = (e) => {
		const { value } = e.target;
		const newValue = Number(value);

		if (!newValue) {
			return setRowsPerPage(10);
		}

		setRowsPerPage(newValue);
	};

	const handleConvertToCSV = (body, mode) => {
		const headers = Object.entries(exportHeaders ? exportHeaders : headValues)
			.map(([key, value]) => {
				if (
					value.hideColumn &&
					value.hideColumn({
						role: user.role,
						supplier: user.supplier,
						mode: "export",
					})
				) {
					return null;
				}

				if (!hiddenColumns[key]) {
					return value.name;
				}

				return null;
			})
			.filter((a) => a);

		const data = body.map((row) => {
			return Object.entries(exportHeaders ? exportHeaders : headValues)
				.map(([key, value]) => {
					if (hiddenColumns[key]) {
						return null;
					}

					if (
						value.hideColumn &&
						value.hideColumn({
							role: user.role,
							supplier: user.supplier,
							mode: "export",
						})
					) {
						return null;
					}

					let resultText = getRawValue(row, key);

					if (value.filter) {
						resultText = value.filter(resultText, { row, ...additionalValues });

						if (value.isOnlyDate) {
							resultText = resultText.toLocaleDateString();
						} else if (value.isDate) {
							resultText = resultText.toLocaleString();
						}
					} else if (value.parser) {
						resultText = value.parser(resultText, { row, ...additionalValues });
					}

					return String(resultText);
				})
				.filter((a) => a);
		});

		csvDownload({
			filename: `${title}-${mode}-${new Date().toLocaleString()}`,
			headers,
			data,
		});
	};

	return (
		<>
			<div className={tableStyles.controlsContainer}>
				<Button
					onClick={() => {
						handleChangePage(page - 1);
					}}
					disabled={page < 2}
					style={{
						display: "inline-flex",
						alignItems: "center",
						justifyContent: "center",
					}}
				>
					<Icon path={mdiArrowLeft} size={0.7} />
				</Button>

				<fieldset className={tableStyles.fieldset}>
					<legend>Strona</legend>
					<select
						className={tableStyles.select}
						name="status"
						onChange={(e) => {
							handleChangePage(e.target.value);
						}}
						value={page}
					>
						{Array(pages)
							.fill(0)
							.map((e, i) => i + 1)
							.map((value) => (
								<option key={`option-${value}`} value={value}>
									{value}
								</option>
							))}
					</select>
				</fieldset>

				<Button
					onClick={() => {
						handleChangePage(page + 1);
					}}
					disabled={page > pages - 1}
					style={{
						display: "inline-flex",
						alignItems: "center",
						justifyContent: "center",
					}}
				>
					<Icon path={mdiArrowRight} size={0.7} />
				</Button>

				<fieldset className={tableStyles.fieldset}>
					<legend>Wierszy na stronę</legend>
					<input type="number" placeholder="Domyślnie (10)" onChange={handleChangeRowsPerPage} min={1} defaultValue={rowsPerPage} className={tableStyles.select} />
				</fieldset>

				<Button
					onClick={() => {
						handleConvertToCSV(exportParser(tableBody), "part");
					}}
				>
					Eksportuj widoczne dane
				</Button>

				<Button
					onClick={() => {
						handleConvertToCSV(exportParser(bodyValues), "all");
					}}
				>
					Eksportuj wszystkie dane
				</Button>

				{selectRows.enabled && (
					<Button
						onClick={() => {
							selectRows.multiActionButton.onClick(Object.keys(selectedRows).filter((key) => selectedRows[key]));
						}}
					>
						{selectRows.multiActionButton.title}
					</Button>
				)}
			</div>

			<table className={`${tableStyles.table} ${tableStyles.hover} ${className ? className : ""}`} {...props}>
				<thead>
					<tr>
						{Object.entries(headValues)
							.map(([key, value]) => {
								if (value.hideColumn && value.hideColumn({ role: user.role, supplier: user.supplier })) {
									return false;
								}

								if (hiddenColumns[key]) {
									return false;
								}

								return (
									<th key={`head-${key}`}>
										<div className={tableStyles.headCell}>
											<div className={tableStyles.headCellButtons}>
												{!hiddenColumns[key] && (
													<span className={tableStyles.headCellContainer} title={`${wrapColumnText[key] ? "Rozwiń" : "Zawiń"} tekst kolumny "${value.name}"`}>
														<div
															onClick={() => {
																setWrapColumnText((oldValues) => {
																	return {
																		...oldValues,
																		[key]: !oldValues[key],
																	};
																});
															}}
															className={searchButtonStyles(key)}
														>
															<Icon path={wrapColumnText[key] ? mdiWrapDisabled : mdiWrap} size={1} />
														</div>
													</span>
												)}
												<span className={tableStyles.headCellContainer} title={`${hiddenColumns[key] ? "Pokaż" : "Ukryj"} kolumnę "${value.name}"`}>
													<div
														onClick={() => {
															setHiddenColumns((oldValues) => {
																return { ...oldValues, [key]: !oldValues[key] };
															});
														}}
														className={searchButtonStyles(key)}
													>
														<Icon path={hiddenColumns[key] ? mdiEye : mdiEyeOff} size={1} />
													</div>
												</span>
												{!hiddenColumns[key] && (
													<span className={tableStyles.headCellContainer} title={`Filtruj według kolumny "${value.name}"`}>
														<div
															onClick={() => {
																handleOpenFilter(key);
															}}
															name="filter"
															className={searchButtonStyles(key)}
														>
															<Icon path={mdiSearchWeb} size={1} />
														</div>
														{filterDialog?.key === key &&
															(headValues[key].isDate ? (
																<TableDateControls keyVal={key} filters={filters} handleChangeSearchInput={handleChangeSearchInput} />
															) : (
																<input
																	type="text"
																	key={`filter-input-${key}`}
																	placeholder="Filtruj .."
																	onChange={(e) => {
																		const { value } = e.target;
																		handleChangeSearchInput(key, value);
																	}}
																	onKeyDown={(e) => {
																		if (e.key === "Enter") {
																			setFilterDialog(false);
																		}
																	}}
																	onBlur={() => {
																		setFilterDialog(false);
																	}}
																	autoFocus
																	defaultValue={filters[key] || ""}
																	className={tableStyles.filterDialog}
																/>
															))}
													</span>
												)}
											</div>

											{!hiddenColumns[key] && (
												<div
													className={tableStyles.headTextCell}
													style={
														!value.oneLine || wrapColumnText[key]
															? {
																	wordSpacing: "100vw",
																	maxWidth: "min-content",
																	whiteSpace: "unset",
															  }
															: {}
													}
												>
													{value.name}
												</div>
											)}
										</div>
									</th>
								);
							})
							.filter(Boolean)}
						{actions
							.map((value) => {
								if (value.hideColumn && value.hideColumn()) {
									return false;
								}

								return (
									<th key={`head-action-${value.type}`}>
										<div>{value.name}</div>
									</th>
								);
							})
							.filter(Boolean)}
						{selectRows.enabled && (
							<th>
								<div>Zaznacz</div>
							</th>
						)}
					</tr>
				</thead>
				<tbody className={hasHiddenColumns() ? tableStyles.hasHiddenColumns : tableStyles.singleRows}>{renderTable(renderComponent)}</tbody>
			</table>
		</>
	);
};

export default TableComponent;

import React from "react";
import styles from "styles/NewDevice.module.css";
import { useFetch } from "hooks/useFetch";
import PathsAPI from "constants/PathsAPI";
import Button from "components/Button";
import NavButtonLink from "components/NavButtonLink";
import { useNavigate } from "react-router-dom";
import { useEffect, useMemo, useRef, useState } from "react";
import Draggable from "react-draggable";
import stateMode from "constants/stateMode";
import { OpenStreetMapProvider } from "react-leaflet-geosearch";
import { AttributionControl, MapContainer, Marker, TileLayer } from "react-leaflet";
import CenterToCords from "components/leaflet/CenterToCords";
import { icon } from "leaflet";
import iconUrl from "assets/marker-icon.png";
import iconShadowUrl from "leaflet/dist/images/marker-shadow.png";

const NewDevice = () => {
	const nodeRef = useRef(null);
	const { usePost } = useFetch(PathsAPI.Devices);
	const { request, response, loading, hasError } = usePost();
	const navigate = useNavigate();

	const [markerPosition, setMarkerPosition] = useState(false);
	const [address, setAddress] = useState("");

	const prov = useMemo(() => {
		return OpenStreetMapProvider();
	}, []);

	useEffect(() => {
		address &&
			prov.search({ query: "Poland, " + address }).then((results) => {
				const { x, y } = results[0];
				const coords = [y, x];
				setMarkerPosition(coords);
			});
	}, [prov, address]);

	const handleChangeAddress = (e) => {
		setAddress(e.target.value);
	};

	const handleSubmit = async (e) => {
		e.preventDefault();

		const formData = new FormData(e.target);

		request({
			name: formData.get("name"),
			IPAddress: formData.get("IPAddress"),
			locatedAt: formData.get("locatedAt"),
			coords: markerPosition,
		});
	};

	useEffect(() => {
		response?._id && navigate("../", { state: stateMode.Refresh });
	}, [response, navigate]);

	return (
		<Draggable nodeRef={nodeRef} handle={`.${styles.header}`}>
			<div ref={nodeRef} className={styles.container}>
				<div className={styles.header}>
					<h2>Dodaj nowe urządzenie</h2>
					<NavButtonLink type="reset" to="../" state={stateMode.Refresh}>
						&#x2715;
					</NavButtonLink>
				</div>
				<form className={styles.form} onSubmit={handleSubmit}>
					<fieldset>
						<legend>Nazwa</legend>
						<input required type="text" name="name" placeholder="Podaj nazwę urządzenia .." />
					</fieldset>

					<fieldset>
						<legend>Adres IP</legend>
						<input required type="text" name="IPAddress" placeholder="Podaj adres IP .." />
					</fieldset>

					<fieldset>
						<legend>Lokalizacja</legend>
						<input required type="text" name="locatedAt" placeholder="Podaj lokalizację urządzenia .." onChange={handleChangeAddress} value={address} />
					</fieldset>

					<MapContainer attributionControl={false} center={[51.72513413988088, 17.399333257415663]} zoom={13} className={styles.mapContainer}>
						<TileLayer
							attribution='<a target="_blank" href="https://leafletjs.com/">Leaflet</a> | &copy; Autorzy <a target="_blank" href="https://www.openstreetmap.org/copyright">OpenStreetMap</a>'
							url="https://tile.openstreetmap.org/{z}/{x}/{y}.png"
							noWrap={true}
							bounds={[
								[-90, -180],
								[90, 180],
							]}
						/>

						<AttributionControl position="bottomright" prefix={false} />

						<CenterToCords coords={markerPosition || [51.72513413988088, 17.399333257415663]} />

						{markerPosition && (
							<Marker
								position={markerPosition}
								icon={icon({
									iconUrl: iconUrl,
									shadowUrl: iconShadowUrl,
									iconSize: [25, 41],
									iconAnchor: [13, 40],
								})}
							/>
						)}
					</MapContainer>

					{hasError && <div className="error">{hasError}</div>}

					<div className={styles.buttons}>
						<Button type="submit" loading={loading}>
							Zapisz zmiany
						</Button>
					</div>
				</form>
			</div>
		</Draggable>
	);
};

export default NewDevice;

const getCardNumber = (payment) => {
	const { maskedCardNumber, cardsetName } = payment?.transactionEndData?.terminal?.transactionDetails || {};
	if (maskedCardNumber || cardsetName) {
		return `${maskedCardNumber} (${cardsetName})`;
	} else if (payment?.transactionEndData?.demo) {
		return `Transakcja testowa (demo)`;
	} else {
		return `Nie ukończono transakcji`;
	}
};

export default getCardNumber;

import React from "react";
import styles from "styles/BoxDeviceConfig.module.css";
import tableStyles from "styles/Table.module.css";
import { useFetch } from "hooks/useFetch";
import PathsAPI from "constants/PathsAPI";
import { Outlet, useLocation, useNavigate, useParams } from "react-router-dom";
import Button from "components/Button";
import NavButtonLink from "components/NavButtonLink";
import { useEffect, useRef, useState } from "react";
import Draggable from "react-draggable";
import NumberSelect from "components/NumberSelect";
import stateMode from "constants/stateMode";
import { toast } from "react-toastify";

const BoxDeviceConfig = () => {
	const nodeRef = useRef(null);

	let params = useParams();
	const navigate = useNavigate();
	const location = useLocation();

	const [deviceInfo, setDeviceInfo] = useState({});

	const { usePut } = useFetch(`${PathsAPI.DeviceBoxes}/${params.id}`);
	const { request: updateBoxesConfig } = usePut();

	const [deviceRules, setDeviceRules] = useState({});
	const [rowNumber, setRowNumber] = useState(0);
	const [columnNumber, setColumnNumber] = useState(0);
	const [tableResult, setTableResult] = useState({});

	const handleSaveChanges = async () => {
		try {
			const res = await updateBoxesConfig(tableResult);

			if (res._id) {
				toast.success("Zmiany zostały zapisane");
				navigate("../", { state: stateMode.Refresh });
			}
		} catch (error) {
			toast.error(error);
		}
	};

	const createTable = () => {
		let result = {};

		for (let rowIndex = 0; rowIndex < rowNumber; rowIndex++) {
			for (let columnIndex = 1; columnIndex <= columnNumber; columnIndex++) {
				if (!result[rowIndex]) {
					result[rowIndex] = {};
				}

				result[rowIndex][columnIndex] = {
					cell: columnIndex,
					row: rowIndex,
					rule: "NORMAL",
					relay: `${columnIndex}${rowIndex}`,
					door: `${columnIndex}${rowIndex}`,
					temp: `${columnIndex}${rowIndex}`,
					boxType: "COLD",
				};

				if (deviceRules?.[rowIndex]?.[columnIndex]) {
					result[rowIndex][columnIndex] = { ...result[rowIndex][columnIndex], ...deviceRules?.[rowIndex]?.[columnIndex] };
				}
			}
		}

		if (JSON.stringify(result) !== JSON.stringify(tableResult)) {
			setTableResult(result);
		}
		return result;
	};

	const switchBoxState = (values) => {
		navigate("edit", { state: values });
	};

	useEffect(() => {
		switch (location.state?.mode) {
			case stateMode.BoxEditConfig:
				const values = location.state.values;

				const isPanelMode = values.panelMode === "on" ? "DISABLED" : "NORMAL";

				setDeviceRules({
					...deviceRules,
					[values.row]: {
						...deviceRules[values.row],
						[values.cell]: {
							rule: isPanelMode,
							relay: values.relay,
							door: values.door,
							temp: values.temp,
							boxType: values.boxType,
						},
					},
				});
				break;
			case stateMode.BoxDeviceConfig:
				setDeviceInfo(location.state.deviceBasicData);
				const { boxes } = location.state.deviceBasicData;
				if (boxes) {
					setRowNumber(Object.keys(boxes).length);
					setColumnNumber(Object.keys(Object.values(boxes)[0]).length);
				}
				setDeviceRules(boxes);
				break;

			default:
				return;
		}

		location.state = null;
	}, [location, deviceRules]);

	const formatDeviceIndex = (index) => {
		return `Moduł: ${Number(index[0])}, pozycja: ${Number(index[1])}`;
	};

	const createBox = (values) => {
		switch (values.rule) {
			case "DISABLED":
				return (
					<td
						onClick={() => {
							switchBoxState(values);
						}}
						style={{ background: "var(--primary)" }}
						key={`${values.cell}${values.row}`}
					>
						<div>Miejsce na panel urządzenia</div>
					</td>
				);

			default:
				return (
					<td key={`${values.cell}${values.row}`} style={{ background: values.boxType === "WARM" ? "#ffb777" : "#add8e6" }}>
						<div
							title={`Przekaźnik zamka: ${formatDeviceIndex(values.relay)}\nStan drzwi: ${formatDeviceIndex(
								values.door
							)}\nOdczyt temperatury: ${formatDeviceIndex(values.temp)}`}
						>
							<span>Skrytka</span>
							<span>Kolumna: {values.cell}</span>
							<span>Wiersz: {values.row}</span>
							<Button
								onClick={() => {
									switchBoxState(values);
								}}
							>
								Edytuj
							</Button>
						</div>
					</td>
				);
		}
	};

	return (
		<>
			<Draggable nodeRef={nodeRef} handle={`.${styles.header}`}>
				<div ref={nodeRef} className={styles.container}>
					<div className={styles.header}>
						<h2>Konfiguruj skrytki urządzenia</h2>
					</div>
					<NavButtonLink type="reset" to="../" state={stateMode.Refresh} style={{ position: "absolute", right: "20px", top: "15px" }}>
						&#x2715;
					</NavButtonLink>
					<div className={styles.main}>
						<div className={styles.mainContainer}>
							<div className={styles.tableControls}>
								<NumberSelect min={1} number={rowNumber} setNumber={setRowNumber}>
									Ilość wierszy
								</NumberSelect>
								<NumberSelect min={1} number={columnNumber} setNumber={setColumnNumber}>
									Ilość kolumn
								</NumberSelect>
							</div>
							<div className={styles.tableContainer}>
								<table className={`${tableStyles.table} ${tableStyles.hover} ${styles.table}`}>
									<thead>
										<tr>
											<th colSpan={columnNumber}>{deviceInfo?.name ? `Nazwa urządzenia: ${deviceInfo.name}` : "Ładowanie.."}</th>
										</tr>
									</thead>
									<tbody>
										{Object.entries(createTable()).map((row) => (
											<tr key={row[0]}>{Object.entries(row[1]).map((values) => createBox(values[1]))}</tr>
										))}
									</tbody>
								</table>
							</div>
						</div>

						<div className={styles.buttons}>
							<Button type="submit" onClick={handleSaveChanges}>
								Zapisz zmiany
							</Button>
						</div>
					</div>
				</div>
			</Draggable>
			<Outlet />
		</>
	);
};

export default BoxDeviceConfig;

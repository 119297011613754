import React, { useEffect, useState } from "react";
import { useFetch } from "hooks/useFetch";
import PathsAPI from "constants/PathsAPI";
import styles from "styles/VendingList.module.css";
import tableStyles from "styles/Table.module.css";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import Button from "components/Button";
import NavButtonLink from "components/NavButtonLink";
import stateMode from "constants/stateMode";
import { useAuthContext } from "hooks/useAuthContext";
import { toast } from "react-toastify";
import { ReactSVG } from "react-svg";
import { mdiArchiveLockOpenOutline, mdiCloseBox, mdiTrashCan, mdiUpdate, mdiPercentCircle } from "@mdi/js";
import TableComponent from "components/Table";
import vendingTable from "constants/tables/vendingTable";
import getDeviceImage from "constants/getDeviceImage";
import { useParams } from "react-router-dom";

const VendingList = () => {
	const { user } = useAuthContext();

	const { useGet: useDevicesGet } = useFetch(PathsAPI.Devices);
	const { response: devicesList, request: refreshDeviceList, hasError: hasDevicesError, loading: loadingDevicesList } = useDevicesGet(true);

	const { useGet, useDelete } = useFetch(PathsAPI.VendingOffers);
	const { request: refreshVendingOffersList, response, loading, hasError } = useGet(true);
	const { request: reqDeleteVendingOffer } = useDelete();
	const [vendingOffers, setVendingOffers] = useState();

	const { usePost: useMakePost } = useFetch(PathsAPI.MakeVendingOffer);
	const { request: reqMakeVendingOffer } = useMakePost();

	const { usePost: useAbortPost } = useFetch(PathsAPI.AbortVendingOffer);
	const { request: reqAbortVendingOffer } = useAbortPost();

	const { usePut: useUpdatePut } = useFetch(PathsAPI.UpdateVendingOffer);
	const { request: reqUpdateVendingOffer } = useUpdatePut();

	const location = useLocation();

	const [selectedDevice, setSelectedDevice] = useState();

	const [offerToDelete, setOfferToDelete] = useState();

	const isAdmin = "ADMIN" === user?.role ? true : false;
	const isGlobal = user?.supplier === "GLOBAL";

	const navigate = useNavigate();
	const params = useParams();

	useEffect(() => {
		if (location.state === stateMode.Refresh) {
			refreshDeviceList();
			refreshVendingOffersList();
			location.state = null;
		} else if (location.state?.selectedDevice) {
			setSelectedDevice(location.state.selectedDevice);
		}
	}, [location, refreshDeviceList, refreshVendingOffersList]);

	useEffect(() => {
		if (!devicesList || !response) {
			return;
		}

		let currentSelectedDevice = selectedDevice || devicesList[0]?._id || null;

		setVendingOffers(response?.filter((vo) => vo.deviceId === currentSelectedDevice));
		setSelectedDevice(currentSelectedDevice);
	}, [devicesList, response, selectedDevice]);

	const useHandleLoadData = () => {
		refreshDeviceList();
		refreshVendingOffersList();
	};

	const handleChangeDevice = (e) => {
		setVendingOffers(response?.filter((vo) => vo.deviceId === e.target.value));
		navigate(".", { state: { selectedDevice: e.target.value } });
	};

	const handleAbortOffer = async (offerId) => {
		try {
			const { boxId } = await reqAbortVendingOffer({ offerId });
			toast.success(`Skrytka nr ${boxId} została otwarta, wyjmij ofertę`);
			refreshDeviceList();
			refreshVendingOffersList();
		} catch (error) {
			toast.error(error?.message);
		}
	};

	const handleMakeOffer = async (offerId) => {
		try {
			const toastId = toast.loading(`Dodawanie oferty ..`, {
				autoClose: 10000,
			});
			const { boxId } = await reqMakeVendingOffer({ offerId });

			toast.update(toastId, {
				render: `Skrytka nr ${boxId} została otwarta, umieść ofertę`,
				type: "success",
				isLoading: false,
				closeOnClick: true,
				autoClose: 5000,
				closeButton: true,
			});

			refreshDeviceList();
			refreshVendingOffersList();
		} catch (error) {
			toast.error(error?.message);
		}
	};

	const handleUpdateOffer = async (offerId) => {
		try {
			await reqUpdateVendingOffer({ offerId });
			toast.success(`Oferta została zaktualizowana o aktualne ceny produktów`);
			refreshVendingOffersList();
		} catch (error) {
			toast.error(error?.message);
		}
	};

	const getDeviceBySelected = () => devicesList?.find((device) => device._id === selectedDevice);

	const handleLoadDeviceImage = () => {
		const boxes = document.querySelectorAll(`.${styles.deviceImage} rect`);

		boxes.forEach((box) => {
			if (box.id === "") {
				return;
			}

			const device = getDeviceBySelected();

			if (!device) {
				return;
			}

			if (user.supplier !== "GLOBAL" && device.boxes[box.id].supplier !== user.supplier) {
				box.style.fill = "gray";
				return;
			}

			box.style.fill = device.boxes[box.id].rule === "BUSY" ? "red" : "";
		});
	};

	const handleDeleteOffer = async (offerId) => {
		if (offerId !== offerToDelete) {
			toast.warning(
				<>
					Czy na pewno chcesz usunąć tą ofertę?
					<br />W celu potwierdzenie kliknij przycisk jeszcze raz
				</>
			);

			return setOfferToDelete(offerId);
		}

		try {
			await reqDeleteVendingOffer({
				version: "V2",
				url: `/${offerId}`,
			});
			refreshDeviceList();
			refreshVendingOffersList();
		} catch (error) {
			toast.error(error?.message);
		}
	};

	const handleNavigateToSetDiscount = (offerId) => {
		navigate(`${offerId}/set-discount`);
	};

	useEffect(() => {
		toast.error(hasDevicesError?.message);
	}, [hasDevicesError]);

	const vendingTableActions = [
		{
			type: "setDiscount",
			name: (
				<>
					Ustaw
					<br />
					rabatowanie
				</>
			),
			icon: mdiPercentCircle,
			onClick: (row) => {
				handleNavigateToSetDiscount(row._id);
			},
			disabled: (row) => {
				if (params.offerId) {
					return params.offerId !== row._id;
				}
			},
			actionStyle: (row) => {
				if (params.offerId === row._id) {
					return { background: "#bde6ad" };
				}

				return {};
			},
		},
		{
			type: "abort",
			name: "Aktualizuj",
			icon: mdiUpdate,
			disabled: (row) => {
				return !(getDeviceBySelected()?.boxes[row.boxId]?.vendingOfferId === row._id);
			},
			onClick: (row) => {
				handleUpdateOffer(row._id);
			},
		},
		{
			type: "abort",
			name: "Wyjmij",
			icon: mdiCloseBox,
			disabled: (row) => {
				return !(getDeviceBySelected()?.boxes[row.boxId]?.vendingOfferId === row._id);
			},
			onClick: (row) => {
				handleAbortOffer(row._id);
			},
		},
		{
			type: "make",
			name: "Dodaj",
			icon: mdiArchiveLockOpenOutline,
			disabled: (row) => {
				return getDeviceBySelected()?.boxes[row.boxId]?.vendingOfferId === row._id;
			},
			onClick: (row) => {
				handleMakeOffer(row._id);
			},
		},
		{
			type: "delete",
			name: "Usuń",
			icon: mdiTrashCan,
			disabled: (row) => {
				return getDeviceBySelected()?.boxes[row.boxId]?.vendingOfferId === row._id;
			},
			onClick: (row) => {
				handleDeleteOffer(row._id);
			},
		},
	];

	return (
		<div className={styles.main}>
			<div className={styles.panelContainer}>
				<div className={styles.header}>
					<h2>Lista ofert vendingowych</h2>
					<div className={styles.buttonsContainer}>
						{
							<NavButtonLink to="create" state={{ selectedDevice }}>
								Dodaj nową ofertę
							</NavButtonLink>
						}
						{isGlobal && isAdmin && getDeviceBySelected()?.coffee?.enable && <NavButtonLink to={`${selectedDevice}/coffee-edit`}>Edytuj dane kawy</NavButtonLink>}
						<Button loading={loading} error={hasError} onClick={useHandleLoadData}>
							Odśwież
						</Button>
					</div>
				</div>

				<div className={styles.offersContainer}>
					<div className={styles.offersHeaderContainer}>
						<fieldset className={tableStyles.fieldset}>
							<legend>Urządzenie</legend>
							<select className={styles.select} value={selectedDevice} onChange={handleChangeDevice}>
								{devicesList &&
									devicesList.map((device) => (
										<option key={device._id} value={device._id}>
											{device.name}
										</option>
									))}
							</select>
						</fieldset>

						{getDeviceBySelected()?.coffee?.enable && (
							<table className={`${tableStyles.table} ${tableStyles.hover} ${styles.table}`}>
								<thead>
									<tr>
										<th>Cena kawy</th>
										<th>Wielkość kawy</th>
									</tr>
								</thead>
								<tbody>
									{selectedDevice && (
										<tr>
											<td>{getDeviceBySelected().coffee.cost} zł</td>
											<td>{getDeviceBySelected().coffee.size} ml</td>
										</tr>
									)}
								</tbody>
							</table>
						)}
					</div>
				</div>
			</div>

			<div className={[styles.panelContainer, styles.deviceContainer].join(" ")}>
				{devicesList && selectedDevice && (
					<ReactSVG
						src={getDeviceImage(devicesList.find((d) => d._id === selectedDevice)?.configuration?.type)}
						className={styles.deviceImage}
						alt="DeviceType1"
						afterInjection={handleLoadDeviceImage}
					/>
				)}
			</div>
			<div className={styles.panelContainer}>
				<TableComponent
					title="Lista ofert vendingowych"
					headValues={vendingTable}
					bodyValues={vendingOffers}
					error={hasError}
					actions={vendingTableActions}
					additionalValues={{ devicesList, selectedDevice }}
					loadedAdditionalValues={[!loadingDevicesList, Boolean(selectedDevice)]}
				/>
			</div>
			<Outlet />
		</div>
	);
};

export default VendingList;

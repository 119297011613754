import React from "react";
import { Outlet } from "react-router-dom";
import styles from "styles/AdminDashboard.module.css";
import NavButtonLink from "components/NavButtonLink";
import { useEffect } from "react";
import { useAuthContext } from "hooks/useAuthContext";
import actionType from "constants/actionTypes";

const Dashboard = () => {
	const { user, menu: viewMainMenu, dispatch } = useAuthContext();
	const isMobile = window.innerWidth < 700;

	const isGlobal = "GLOBAL" === user?.supplier ? true : false;
	const isDeliverer = "DELIVERER" === user?.role ? true : false;
	const isAdmin = "ADMIN" === user?.role ? true : false;

	useEffect(() => {
		document.documentElement.style.setProperty(
			"--background-color",
			"linear-gradient(30deg, rgb(145 255 0 / 50%) 0%, rgb(134 214 232 / 50%) 100%)"
		);
		document.documentElement.style.setProperty(
			"--background-accent",
			"#00000038"
		);
		document.documentElement.style.setProperty("--navbar-height", "5rem");
	}, []);

	const viewStyles = (viewCondition) => {
		if (viewCondition) {
			return {};
		} else {
			return { display: "none" };
		}
	};

	const hideMainMenuForMobile = () => {
		dispatch({ type: actionType.VIEW_MENU, payload: false });
	};

	return (
		<div className={styles.container}>
			<div
				style={viewMainMenu ? {} : { display: "none" }}
				className={styles.mainMenu}
			>
				<NavButtonLink
					onAfterNavigate={isMobile ? hideMainMenuForMobile : false}
					style={viewStyles(isDeliverer || isAdmin)}
					to="orders"
				>
					Zamówienia
				</NavButtonLink>
				<NavButtonLink
					onAfterNavigate={isMobile ? hideMainMenuForMobile : false}
					style={viewStyles(isDeliverer || isAdmin)}
					to="vending-orders"
				>
					Transakcje
				</NavButtonLink>

				<NavButtonLink
					onAfterNavigate={isMobile ? hideMainMenuForMobile : false}
					style={viewStyles(isDeliverer || isAdmin)}
					to="vending"
				>
					Oferty vendingowe
				</NavButtonLink>

				<NavButtonLink
					onAfterNavigate={isMobile ? hideMainMenuForMobile : false}
					style={viewStyles(isDeliverer || isAdmin)}
					to="devices"
				>
					Urządzenia
				</NavButtonLink>
				<NavButtonLink
					onAfterNavigate={isMobile ? hideMainMenuForMobile : false}
					style={viewStyles(isDeliverer || isAdmin)}
					to="products"
				>
					Produkty
				</NavButtonLink>
				<NavButtonLink
					onAfterNavigate={isMobile ? hideMainMenuForMobile : false}
					style={viewStyles(isAdmin)}
					to="vouchers"
				>
					Vouchery
				</NavButtonLink>

				<NavButtonLink
					onAfterNavigate={isMobile ? hideMainMenuForMobile : false}
					style={viewStyles(isAdmin)}
					to="users"
				>
					Użytkownicy
				</NavButtonLink>
				<NavButtonLink
					onAfterNavigate={isMobile ? hideMainMenuForMobile : false}
					style={viewStyles(isDeliverer || isAdmin)}
					to="shops"
				>
					Dostawcy
				</NavButtonLink>

				<NavButtonLink
					onAfterNavigate={isMobile ? hideMainMenuForMobile : false}
					style={viewStyles(isAdmin)}
					to="info"
				>
					Wersja aplikacji
				</NavButtonLink>

				{/* <NavButtonLink to="calendar">Kalendarz</NavButtonLink> */}

				{/* <NavButtonLink to="reports">Raporty</NavButtonLink> */}
			</div>
			<div
				style={viewMainMenu ? {} : { borderRadius: "1em" }}
				className={styles.context}
			>
				<Outlet />
			</div>
		</div>
	);
};

export default Dashboard;

import React from "react";
import styles from "styles/NewVendingOffer.module.css";
import { useFetch } from "hooks/useFetch";
import PathsAPI from "constants/PathsAPI";
import { useNavigate } from "react-router-dom";
import Button from "components/Button";
import NavButtonLink from "components/NavButtonLink";
import { useEffect, useRef, useState } from "react";
import Draggable from "react-draggable";
import stateMode from "constants/stateMode";
import { toast } from "react-toastify";
import { useAuthContext } from "hooks/useAuthContext";
import Icon from "@mdi/react";
import { mdiMinus, mdiPlus } from "@mdi/js";
import { useLocation } from "react-router-dom";

const NewVendingOffer = () => {
	const isMobile = window.innerWidth < 700;

	const nodeRef = useRef(null);

	const { useGet: useDevicesGet } = useFetch(PathsAPI.Devices);
	const { response: devicesList, hasError: hasDevicesError } = useDevicesGet(true);

	const { useGet: useProductsGet } = useFetch(PathsAPI.Products);
	const { response: resProductList, loading, hasError } = useProductsGet(true);

	const { usePost } = useFetch(PathsAPI.VendingOffers);
	const { request, response: postVendingOfferResponse } = usePost();

	const [productList, setProductList] = useState();
	const [selectedDevice, setSelectedDevice] = useState();
	const [selectedProducts, setSelectedProducts] = useState({});
	const [selectedBox, setSelectedBox] = useState(false);

	const { user } = useAuthContext();
	const isGlobal = user.supplier === "GLOBAL";

	const { useGet: useSuppliersGet } = useFetch(PathsAPI.Suppliers);
	const { response: suppliersList } = useSuppliersGet(isGlobal);

	let navigate = useNavigate();
	let location = useLocation();

	useEffect(() => {
		if (resProductList) {
			setProductList(resProductList);
		}
	}, [resProductList]);

	useEffect(() => {
		if (devicesList) {
			setSelectedDevice(devicesList[0]._id);
		}
	}, [devicesList]);

	useEffect(() => {
		if (location.state?.selectedDevice !== selectedDevice) {
			setSelectedDevice(location.state?.selectedDevice);
		}
	}, [location, selectedDevice]);

	const getDefaultOfferName = () => {
		const [selectedProduct, value] = Object.entries(selectedProducts)?.[0];
		let tempResult = "";
		if (value > 1) {
			tempResult = `${value} x `;
		}

		tempResult += `${getProductById(selectedProduct).join(" ")}`;

		return tempResult;
	};

	const handleSubmit = async (e) => {
		e.preventDefault();

		const formData = new FormData(e.target);

		if (!Object.entries(selectedProducts).length > 0 || !selectedDevice || !selectedBox) {
			return toast.error("Uzupełnij wszystkie dane");
		}

		let tempOfferName = "";

		if (Object.entries(selectedProducts).length === 1) {
			tempOfferName = getDefaultOfferName();
		} else {
			tempOfferName = formData.get("offerName");
		}

		if (tempOfferName === "") {
			return toast.warning("Podaj nazwę oferty");
		}

		try {
			await request({
				offerName: tempOfferName,
				products: selectedProducts,
				deviceId: selectedDevice,
				boxId: selectedBox,
			});
		} catch (error) {
			toast.error(error);
		}
	};

	const handleChangeSearchInput = (e) => {
		const searchText = e.target.value.toLowerCase();

		const result = resProductList.filter((product) => {
			const formattedName = product?.name?.toLowerCase();

			if (!formattedName) {
				return false;
			}

			return formattedName?.includes(searchText);
		});

		setProductList(result);
	};

	const handleChangeProductValue = (productId, changeValue) => {
		setSelectedProducts((prevValues) => {
			let tempPrevValues = { ...prevValues };
			let newValue = (tempPrevValues[productId] || 0) + changeValue;

			if (newValue < 0) {
				return prevValues;
			}

			if (newValue === 0) {
				delete tempPrevValues[productId];
				return tempPrevValues;
			}

			return { ...tempPrevValues, [productId]: newValue };
		});
	};

	useEffect(() => {
		postVendingOfferResponse?.time && navigate("../", { state: stateMode.Refresh });
	}, [postVendingOfferResponse, navigate]);

	const getAvailableBoxes = () => {
		const device = devicesList.find((device) => device._id === selectedDevice);

		if (!device) {
			return [];
		}

		const tempAvailableBoxes = Object.entries(device.boxes).map(([boxId, box]) => {
			const theSameSupplierCondition = box.supplier !== user.supplier;

			const boxIsBusyCondition = box.rule === "BUSY";

			if ((!isGlobal && theSameSupplierCondition) || boxIsBusyCondition) {
				return false;
			}

			return boxId;
		});

		return tempAvailableBoxes.filter(Boolean);
	};

	const getProductById = (productId) => {
		if (!productList) {
			return;
		}

		const product = productList.find((product) => product._id === productId);

		if (!product) {
			return;
		}

		return [`${product.name}`, `(${product._id})`];
	};

	const handleChangeDevice = (e) => {
		setSelectedDevice(e.target.value);
		setSelectedBox(false);
	};

	const getSupplierName = (supplierId) => {
		return suppliersList?.find((supplier) => supplier._id === supplierId)?.name;
	};

	const handleChangeSelectedBox = (e) => {
		setSelectedBox(e.target.value);
	};

	useEffect(() => {
		toast.error(hasDevicesError);
	}, [hasDevicesError]);

	return (
		<Draggable nodeRef={nodeRef} handle={`.${styles.header}`} cancel={"button"} disabled={isMobile} positionOffset={{ x: "-50%", y: "-50%" }}>
			<div ref={nodeRef} className={styles.container}>
				<header className={styles.header}>
					<h2>Dodaj nową ofertę vendingową</h2>
					<NavButtonLink type="reset" to="../" state={stateMode.Refresh}>
						&#x2715;
					</NavButtonLink>
				</header>

				<form className={styles.form} onSubmit={handleSubmit}>
					<div className={styles.mainContainer}>
						<fieldset>
							<legend>Nazwa oferty</legend>
							<input
								type="text"
								placeholder={`${Object.entries(selectedProducts).length === 1 ? `Domyślna wartość (${getDefaultOfferName()})` : "Wpisz nazwę oferty.."}`}
								defaultValue=""
								name="offerName"
							/>
						</fieldset>
						<fieldset>
							<legend>Urządzenie</legend>
							<select className={styles.select} onChange={handleChangeDevice}>
								{devicesList &&
									devicesList.map((device) => (
										<option key={device._id} value={device._id} selected={device._id === selectedDevice}>
											{device.name} {isGlobal && <>({device._id})</>}
										</option>
									))}
							</select>
						</fieldset>

						<fieldset>
							<legend>Wybrana skrytka</legend>
							<select className={styles.select} defaultValue={false} onChange={handleChangeSelectedBox}>
								<option value={false} disabled>
									Wybierz skrytkę
								</option>
								{devicesList &&
									getAvailableBoxes().map((boxId) => (
										<option key={`box-${boxId}`} value={boxId} selected={boxId === selectedBox}>
											{boxId}
										</option>
									))}
							</select>
						</fieldset>

						<fieldset>
							<legend>Wyszukiwarka produktów</legend>
							<input type="text" placeholder="Wpisz szukaną frazę.." onChange={handleChangeSearchInput} />
						</fieldset>
						<div className={styles.productContainer}>
							{productList &&
								productList.slice(0, 15).map((product) => (
									<fieldset key={product._id} className={styles.productListItem}>
										<label htmlFor={`product-${product._id}`}>
											{product.name} ({product._id})
											{isGlobal && (
												<>
													<br />
													{getSupplierName(product.supplier)}
												</>
											)}
										</label>
										<div className={styles.productListValues}>
											<button
												type="button"
												onClick={() => {
													handleChangeProductValue(product._id, -1);
												}}
											>
												<Icon path={mdiMinus} size={1} />
											</button>
											<div className={styles.productItemAmount}>{selectedProducts[product._id] || 0}</div>
											<button
												type="button"
												onClick={() => {
													handleChangeProductValue(product._id, 1);
												}}
											>
												<Icon path={mdiPlus} size={1} />
											</button>
										</div>
									</fieldset>
								))}
						</div>
					</div>

					<footer>
						{hasError && <div className="error">{hasError}</div>}

						<Button type="submit" loading={loading}>
							Dodaj ofertę
						</Button>
					</footer>
				</form>
			</div>
		</Draggable>
	);
};

export default NewVendingOffer;

import React from "react";
import styles from "styles/DeviceConfig.module.css";
import { useFetch } from "hooks/useFetch";
import PathsAPI from "constants/PathsAPI";
import { useNavigate, useParams } from "react-router-dom";
import Button from "components/Button";
import NavButtonLink from "components/NavButtonLink";
import { useEffect, useRef } from "react";
import Draggable from "react-draggable";
import stateMode from "constants/stateMode";

const DeviceThermoConfig = () => {
	const nodeRef = useRef(null);
	let params = useParams();

	const { useGet, usePut } = useFetch(`${PathsAPI.Devices}/${params.id}/thermo/${params.optionId}`);
	const { response: option } = useGet(true);
	const { request, response, loading, hasError } = usePut();

	let navigate = useNavigate();

	const handleSubmit = (e) => {
		e.preventDefault();
		const formData = new FormData(e.target);

		request({
			active: (formData.get("active") || "off") === "on",
			min: formData.get("min"),
			max: formData.get("max"),
		});
	};

	useEffect(() => {
		response?.thermo && navigate("../", { state: stateMode.Refresh });
	}, [response, navigate]);

	return (
		<Draggable nodeRef={nodeRef} handle={`.${styles.header}`}>
			<div ref={nodeRef} className={styles.container}>
				<div className={styles.header}>
					<h2>Edytuj konfigurację termostatu</h2>
					<NavButtonLink type="reset" to="../" state={stateMode.Refresh}>
						&#x2715;
					</NavButtonLink>
				</div>
				<form className={styles.form} onSubmit={handleSubmit}>
					<h2>{option?.description}</h2>

					<fieldset>
						<legend>Temperatura minimalna</legend>
						<input required type="text" name="min" placeholder="Podaj wartość w stopniach Celsjusza" defaultValue={option?.thermo?.min} />
					</fieldset>

					<fieldset>
						<legend>Temperatura maksymalna</legend>
						<input required type="text" name="max" placeholder="Podaj wartość w stopniach Celsjusza" defaultValue={option?.thermo?.max} />
					</fieldset>

					{option && (
						<fieldset className={styles.checkbox}>
							<label htmlFor="active">Aktywny</label>
							<span />
							<input type="checkbox" name="active" id="active" defaultChecked={option?.thermo?.active} />
						</fieldset>
					)}

					{hasError && <div className="error">{hasError}</div>}

					<div className={styles.buttons}>
						<Button type="submit" loading={loading}>
							Zapisz zmiany
						</Button>
					</div>
				</form>
			</div>
		</Draggable>
	);
};

export default DeviceThermoConfig;

class PathsAPI {
	static BaseApiUrl = process.env.REACT_APP_API_URL;
	static Devices = `${this.BaseApiUrl}/device`;
	static DeviceBasic = (deviceId) => `${this.BaseApiUrl}/device/${deviceId}`;
	static DeviceStatus = (deviceId) => `${this.BaseApiUrl}/device/${deviceId}/info`;
	static DeviceRelay = (deviceId) => `${this.BaseApiUrl}/device/${deviceId}/relay`;
	static DeviceBoxes = `${this.BaseApiUrl}/device/box`;
	static Products = `${this.BaseApiUrl}/products`;
	static Categories = `${this.BaseApiUrl}/products/categories`;
	static EditCategories = `${this.BaseApiUrl}/products/categories/edit`;
	static Orders = `${this.BaseApiUrl}/order`;
	static OrderRefund = `${this.BaseApiUrl}/order/refund`;
	static VendingOrders = `${this.BaseApiUrl}/vending-orders`;
	static UserOrders = `${this.BaseApiUrl}/order/user`;
	static UserLastOrderLocation = `${this.BaseApiUrl}/order/user/last-location`;
	static UserOrderPaymentCallback = `${this.BaseApiUrl}/order/user/pay-online/callback`;
	static UserOrderPickUp = `${this.BaseApiUrl}/order/user/pick-up`;
	static UserDetails = `${this.BaseApiUrl}/user/details`;
	static UserPasswordChange = `${this.BaseApiUrl}/user/password`;
	static Telemetry = `${this.BaseApiUrl}/telemetry`;
	static Users = `${this.BaseApiUrl}/user/all`;
	static User = `${this.BaseApiUrl}/user`;
	static Suppliers = `${this.BaseApiUrl}/suppliers`;
	static VendingOffers = `${this.BaseApiUrl}/vending-offers/`;
	static VendingOfferDiscount = (offerId) => `${this.BaseApiUrl}/vending-offers/discount/${offerId}`;
	static MakeVendingOffer = `${this.BaseApiUrl}/vending-offers/make/`;
	static AbortVendingOffer = `${this.BaseApiUrl}/vending-offers/abort/`;
	static UpdateVendingOffer = `${this.BaseApiUrl}/vending-offers/update/`;
	static ForgetPassword = `${this.BaseApiUrl}/user/forget/`;
	static ResetPassword = `${this.BaseApiUrl}/user/reset/`;
	static Signup = `${this.BaseApiUrl}/user/signup`;
	static SignupConfirmUser = `${this.BaseApiUrl}/user/signup/confirm`;
	static Logout = `${this.BaseApiUrl}/user/logout`;
	static UserLogged = `${this.BaseApiUrl}/user/logged`;
	static Version = `${this.BaseApiUrl}/version`;
	static VersionServer = `${this.BaseApiUrl}/version/server`;
	static Login = `${this.BaseApiUrl}/user/login`;
	static Voucher = `${this.BaseApiUrl}/voucher`;
	static VoucherUser = `${this.BaseApiUrl}/voucher/user`;
	static OrderUserCart = (deviceId, mode = "products") => `${this.BaseApiUrl}/order/user/cart/${deviceId}?mode=${mode}`;
	static KioskList = `${this.BaseApiUrl}/kiosk/vending/list/`;
}

export default PathsAPI;

import React from "react";
import styles from "styles/DeviceConfig.module.css";
import { useFetch } from "hooks/useFetch";
import PathsAPI from "constants/PathsAPI";
import { useNavigate, useParams } from "react-router-dom";
import Button from "components/Button";
import NavButtonLink from "components/NavButtonLink";
import { useEffect, useRef, useState } from "react";
import Draggable from "react-draggable";
import stateMode from "constants/stateMode";
import { toast } from "react-toastify";
import { useAuthContext } from "hooks/useAuthContext";

const ChangeOrderStatus = () => {
	const nodeRef = useRef(null);
	let params = useParams();

	const { useGet, usePut } = useFetch(`${PathsAPI.Orders}/${params.id}`);
	const { response: orderInfo, loading, hasDataError } = useGet(true);
	const { request, response, hasError: hasUpdateError } = usePut();
	const [currentStatus, setCurrentStatus] = useState();

	const { user } = useAuthContext();
	const isAdmin = user.role === "ADMIN";
	const isGlobal = user.supplier === "GLOBAL";
	const isDeliverer = user.role === "DELIVERER";

	useEffect(() => {
		if (!orderInfo) {
			return;
		}

		const { statusHistory } = orderInfo;
		const status = statusHistory[statusHistory.length - 1]?.status;

		setCurrentStatus(status);
	}, [orderInfo]);

	let navigate = useNavigate();

	const handleSubmit = (e) => {
		e.preventDefault();
		const formData = new FormData(e.target);

		request(formData);
	};

	useEffect(() => {
		response?._id && navigate("../", { state: stateMode.Refresh });
	}, [response, navigate]);

	useEffect(() => {
		toast.error(hasDataError);
	}, [hasDataError]);

	useEffect(() => {
		toast.error(hasUpdateError?.message);
	}, [hasUpdateError]);

	return (
		<>
			{currentStatus && (
				<Draggable nodeRef={nodeRef} handle={`.${styles.header}`}>
					<div ref={nodeRef} className={styles.container}>
						<div className={styles.header}>
							<h2>Edytuj status zamówienia</h2>
							<NavButtonLink type="reset" to="../" state={stateMode.Refresh}>
								&#x2715;
							</NavButtonLink>
						</div>
						<form className={styles.form} onSubmit={handleSubmit}>
							<fieldset>
								<legend>Status</legend>
								<select className={styles.select} name="status" defaultValue={currentStatus}>
									<option value={"WAIT_FOR_ACCEPT"}>Oczekuje na zatwierdzenie</option>
									<option value={"ACCEPTED_TO_DELIVER"}>Przyjęte do dostarczenia</option>
									<option value={"DECLINED"}>Odrzucone</option>
									<option value={"DELIVERED"}>Dostarczone</option>
									{(isAdmin || (isGlobal && isDeliverer)) && <option value={"RECEIVED"}>Odebrane</option>}
								</select>
							</fieldset>

							<div className={styles.buttons}>
								<Button type="submit" loading={loading}>
									Zapisz zmiany
								</Button>
							</div>
						</form>
					</div>
				</Draggable>
			)}
		</>
	);
};

export default ChangeOrderStatus;

import React from "react";
import styles from "styles/UsersEdit.module.css";
import { useFetch } from "hooks/useFetch";
import PathsAPI from "constants/PathsAPI";
import { useNavigate, useParams } from "react-router-dom";
import Button from "components/Button";
import NavButtonLink from "components/NavButtonLink";
import { useEffect, useRef, useState } from "react";
import Draggable from "react-draggable";
import stateMode from "constants/stateMode";
import { toast } from "react-toastify";
import { useAuthContext } from "hooks/useAuthContext";

const UsersEdit = () => {
	const nodeRef = useRef(null);
	let params = useParams();

	const { useGet: useSuppliersGet } = useFetch(PathsAPI.Suppliers);
	const { response: suppliersList } = useSuppliersGet(true);

	const { useGet, usePut, useDelete } = useFetch(`${PathsAPI.User}/${params.id}`);
	const { response: userInfo } = useGet(true);
	const { request, response, loading, hasError } = usePut();
	const { request: delReq, response: delRes, loading: delLoading, hasError: delHasError } = useDelete();
	const [adminRole, setAdminRole] = useState(false);

	let navigate = useNavigate();

	const { user } = useAuthContext();
	const isGlobal = user.supplier === "GLOBAL";

	const handleSubmit = (e) => {
		e.preventDefault();
		const formData = new FormData(e.target);
		formData.set("active", (formData.get("active") || "off") === "on");
		request(formData);
	};

	const handleDelete = () => {
		delReq();
	};

	useEffect(() => {
		(delRes?._id || response?._id) && navigate("../", { state: stateMode.Refresh });
	}, [delRes, response, navigate]);

	useEffect(() => {
		const checkAdminRoles = ["ADMIN", "DELIVERER"].includes(userInfo?.role);
		setAdminRole(checkAdminRoles);
	}, [userInfo?.role]);

	const handleChangeAdminRole = (e) => {
		const checkAdminRoles = ["ADMIN", "DELIVERER"].includes(e.target.value);
		setAdminRole(checkAdminRoles);
	};

	useEffect(() => {
		toast.error(hasError);
	}, [hasError]);

	useEffect(() => {
		toast.error(delHasError);
	}, [delHasError]);

	return (
		<Draggable nodeRef={nodeRef} handle={`.${styles.header}`}>
			<div ref={nodeRef} className={styles.container}>
				<div className={styles.header}>
					<h2>Edytuj dane użytkownika</h2>
					<NavButtonLink type="reset" to="../" state={stateMode.Refresh}>
						&#x2715;
					</NavButtonLink>
				</div>
				<form className={styles.form} onSubmit={handleSubmit}>
					<fieldset>
						<legend>Email</legend>
						<input required type="text" name="email" placeholder="Podaj email .." defaultValue={userInfo?.email} />
					</fieldset>

					<fieldset>
						<legend>Imię</legend>
						<input required type="text" name="fname" placeholder="Podaj imię .." defaultValue={userInfo?.details?.fname} />
					</fieldset>

					<fieldset>
						<legend>Nazwisko</legend>
						<input required type="text" name="lname" placeholder="Podaj nazwisko .." defaultValue={userInfo?.details?.lname} />
					</fieldset>

					<fieldset>
						<legend>Numer telefonu</legend>
						<input required type="text" name="phone" placeholder="Podaj numer telefonu .." defaultValue={userInfo?.details?.phone} />
					</fieldset>

					{userInfo !== null && (
						<div className={styles.checkboxContainer}>
							<fieldset className={styles.checkbox} style={{ background: "#ffb777" }}>
								<label htmlFor="roleAdminType">Administrator</label>
								<span />
								<input
									type="radio"
									name="role"
									id="roleAdminType"
									value={"ADMIN"}
									onChange={handleChangeAdminRole}
									defaultChecked={userInfo?.role === "ADMIN"}
								/>
							</fieldset>

							<fieldset className={styles.checkbox} style={{ background: "#e3e6ad" }}>
								<label htmlFor="roleDelivererType">Dostawca</label>
								<span />
								<input
									type="radio"
									name="role"
									id="roleDelivererType"
									value={"DELIVERER"}
									onChange={handleChangeAdminRole}
									defaultChecked={userInfo?.role === "DELIVERER"}
								/>
							</fieldset>

							{isGlobal && (
								<fieldset className={styles.checkbox} style={{ background: "#add8e6" }}>
									<label htmlFor="roleUserType">Użytkownik</label>
									<span />
									<input
										type="radio"
										name="role"
										id="roleUserType"
										value={"USER"}
										onChange={handleChangeAdminRole}
										defaultChecked={userInfo?.role === "USER"}
									/>
								</fieldset>
							)}
						</div>
					)}

					{isGlobal && suppliersList && (
						<>
							{userInfo !== null ? (
								<fieldset>
									<legend>Przypisany do dostawcy</legend>
									<select
										className={styles.select}
										name="supplier"
										key={userInfo?.supplier}
										defaultValue={userInfo?.supplier}
										disabled={!adminRole}
									>
										<option key={`key-global`} value={`GLOBAL`}>
											Globalny
										</option>
										{suppliersList &&
											suppliersList.map((supplier) => (
												<option key={supplier._id} value={supplier._id}>
													{supplier.name} ({supplier._id})
												</option>
											))}
									</select>
								</fieldset>
							) : (
								<fieldset>
									<legend>Przypisany do dostawcy</legend>
									<select className={styles.select} disabled={true}></select>
								</fieldset>
							)}
						</>
					)}

					<div className={styles.buttons}>
						{userInfo !== null && (
							<fieldset className={styles.checkbox}>
								<label htmlFor="active">Aktywne</label>
								<span />
								<input type="checkbox" name="active" id="active" defaultChecked={userInfo.active} />
							</fieldset>
						)}
						<Button type="reset" loading={delLoading} onClick={handleDelete}>
							Usuń
						</Button>
						<Button type="submit" loading={loading}>
							Zapisz zmiany
						</Button>
					</div>
				</form>
			</div>
		</Draggable>
	);
};

export default UsersEdit;

import React from "react";
import styles from "styles/ProductEdit.module.css";
import { useFetch } from "hooks/useFetch";
import PathsAPI from "constants/PathsAPI";
import { useNavigate, useParams } from "react-router-dom";
import Button from "components/Button";
import NavButtonLink from "components/NavButtonLink";
import { useEffect, useRef, useState } from "react";
import Draggable from "react-draggable";
import stateMode from "constants/stateMode";
import { toast } from "react-toastify";
import { useAuthContext } from "hooks/useAuthContext";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import locale from "date-fns/locale/pl";

const ProductEdit = () => {
	const nodeRef = useRef(null);
	let params = useParams();
	const { useGet, usePut, useDelete } = useFetch(`${PathsAPI.Products}/${params.id}`);
	const { response: productInfo } = useGet(true);
	const { request, response, loading, hasError } = usePut();
	const { request: delReq, response: delRes, loading: delLoading, hasError: delHasError } = useDelete();
	let navigate = useNavigate();

	const { useGet: useSuppliersGet } = useFetch(PathsAPI.Suppliers);
	const { response: suppliersList } = useSuppliersGet(true);

	const { useGet: useCategoriesGet } = useFetch(PathsAPI.EditCategories);
	const { response: categoriesList } = useCategoriesGet(true);

	const [vatRate, setVatRate] = useState("");
	const [netto, setNetto] = useState("");

	const { user } = useAuthContext();
	const isGlobal = user.supplier === "GLOBAL";

	const [startDeliveryDate, setStartDeliveryDate] = useState(new Date());
	const [openStartDeliveryDatePicker, setOpenStartDeliveryDatePicker] = useState(false);
	const openStartDeliveryDatePickerRef = useRef();

	const [endDeliveryDate, setEndDeliveryDate] = useState(new Date());
	const [openEndDeliveryDatePicker, setOpenEndDeliveryDatePicker] = useState(false);
	const openEndDeliveryDatePickerRef = useRef();

	useEffect(() => {
		if (!productInfo) {
			return;
		}

		setStartDeliveryDate(new Date(productInfo.startDeliveryDate));
		setEndDeliveryDate(new Date(productInfo.endDeliveryDate));
		setVatRate(productInfo.vat);
		setNetto(productInfo.cost);
	}, [productInfo]);

	const handleSubmit = (e) => {
		e.preventDefault();
		let result = Object.fromEntries(new FormData(e.target));
		result.active = (result.active || "off") === "on";

		result.startDeliveryDate = startDeliveryDate;
		result.endDeliveryDate = endDeliveryDate;

		result.cost = result.cost.replaceAll(",", ".");
		result.brutto = result.brutto.replaceAll(",", ".");

		request(result);
	};

	const handleDelete = () => {
		delReq();
	};

	useEffect(() => {
		(delRes?._id || response?._id) && navigate("../", { state: stateMode.Refresh });
	}, [delRes, response, navigate]);

	useEffect(() => {
		toast.error(hasError);
	}, [hasError]);

	useEffect(() => {
		toast.error(delHasError);
	}, [delHasError]);

	return (
		<Draggable nodeRef={nodeRef} handle={`.${styles.header}`}>
			<div ref={nodeRef} className={styles.container}>
				<div className={styles.header}>
					<h2>Edytuj dane produktu</h2>
					<NavButtonLink type="reset" to="../" state={stateMode.Refresh}>
						&#x2715;
					</NavButtonLink>
				</div>
				<div className={styles.mainContainer}>
					<form className={styles.form} onSubmit={handleSubmit}>
						<div className={styles.formContainer}>
							{isGlobal && suppliersList && productInfo?.supplier && (
								<fieldset>
									<legend>Przypisany do sklepu</legend>
									<select className={styles.select} name="supplier" defaultValue={productInfo?.supplier}>
										{suppliersList.map((supplier) => (
											<option key={supplier._id} value={supplier._id}>
												{supplier.name} ({supplier._id})
											</option>
										))}
									</select>
								</fieldset>
							)}

							<fieldset>
								<legend>Nazwa</legend>
								<input required type="text" name="name" placeholder="Podaj nazwę produktu .." defaultValue={productInfo?.name} />
							</fieldset>

							<fieldset>
								<legend>Opis produktu</legend>
								<textarea required type="text" name="desc" placeholder="Podaj opis produktu .." defaultValue={productInfo?.desc} />
							</fieldset>

							<fieldset>
								<legend>Cena netto (PLN)</legend>
								<input
									required
									type="text"
									name="cost"
									placeholder="Podaj cenę netto .."
									defaultValue={productInfo?.cost}
									onChange={(e) => {
										setNetto(e.target.value);
									}}
								/>
							</fieldset>

							{productInfo && (
								<fieldset>
									<legend>Stawka VAT</legend>
									<select
										className={styles.select}
										defaultValue={productInfo.vat}
										name="vat"
										onChange={(e) => {
											setVatRate(e.target.value);
										}}
									>
										<option value={"0.23"}>Stawka A (23%)</option>
										<option value={"0.08"}>Stawka B (8%)</option>
										<option value={"0.05"}>Stawka C (5%)</option>
										<option value={"0.00"}>Stawka D (0%)</option>
									</select>
								</fieldset>
							)}

							<fieldset>
								<legend>Cena brutto (PLN)</legend>
								<input
									required
									type="text"
									name="brutto"
									defaultValue={productInfo?.brutto}
									placeholder={`Sugerowana cena brutto: ${((1 + Number(vatRate)) * Number(netto)).toFixed(2)} zł`}
								/>
							</fieldset>

							{categoriesList && productInfo?.category && (
								<fieldset>
									<legend>Kategoria</legend>
									<select className={styles.select} name="category" defaultValue={productInfo?.category}>
										{categoriesList.map((category) => (
											<option key={category._id} value={category.name}>
												{category.name}
											</option>
										))}
									</select>
								</fieldset>
							)}

							<fieldset>
								<legend>Adres URL zdjęcia</legend>
								<input required type="text" name="photoUrl" placeholder="Podaj adres URL zdjęcia .." defaultValue={productInfo?.photoUrl} />
							</fieldset>

							<fieldset>
								<legend>Numer kodu kreskowego</legend>
								<input required type="text" name="barcode" placeholder="Podaj numer kodu kreskowego .." defaultValue={productInfo?.barcode} />
							</fieldset>

							<div style={{ display: "flex", alignItems: "center" }}>
								<fieldset>
									<legend>Rozpoczęcie dostarczania</legend>
									<input
										type="text"
										value={new Date(startDeliveryDate).toLocaleDateString("pl")}
										onClick={() => {
											setOpenStartDeliveryDatePicker(!openStartDeliveryDatePicker);
										}}
										ref={openStartDeliveryDatePickerRef}
									></input>
								</fieldset>

								<LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={locale}>
									<DatePicker
										open={openStartDeliveryDatePicker}
										onClose={() => {
											setOpenStartDeliveryDatePicker(false);
										}}
										slotProps={{ popper: { anchorEl: openStartDeliveryDatePickerRef.current } }}
										value={startDeliveryDate}
										onChange={(value) => {
											value.setHours(23, 59, 59, 999);
											setStartDeliveryDate(value);
										}}
										slots={{ field: () => <></> }}
									/>
								</LocalizationProvider>

								<fieldset>
									<legend>Zakończenie dostarczania</legend>
									<input
										type="text"
										value={new Date(endDeliveryDate).toLocaleDateString("pl")}
										onClick={() => {
											setOpenEndDeliveryDatePicker(!openEndDeliveryDatePicker);
										}}
										ref={openEndDeliveryDatePickerRef}
									></input>
								</fieldset>

								<LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={locale}>
									<DatePicker
										open={openEndDeliveryDatePicker}
										onClose={() => {
											setOpenEndDeliveryDatePicker(false);
										}}
										slotProps={{ popper: { anchorEl: openEndDeliveryDatePickerRef.current } }}
										value={endDeliveryDate}
										onChange={(value) => {
											value.setHours(23, 59, 59, 999);
											setEndDeliveryDate(value);
										}}
										slots={{ field: () => <></> }}
									/>
								</LocalizationProvider>
							</div>

							{productInfo?.prefBoxType && (
								<div className={styles.checkboxContainer}>
									<fieldset className={styles.checkbox} style={{ background: "#ffb777" }}>
										<label htmlFor="warnBoxType">Ciepła skrytka</label>
										<span />
										<input
											type="radio"
											name="prefBoxType"
											id="warnBoxType"
											value={"WARM"}
											defaultChecked={productInfo?.prefBoxType === "WARM"}
										/>
									</fieldset>

									<fieldset className={styles.checkbox} style={{ background: "#add8e6" }}>
										<label htmlFor="coldBoxType">Zimna skrytka</label>
										<span />
										<input
											type="radio"
											name="prefBoxType"
											id="coldBoxType"
											value={"COLD"}
											defaultChecked={productInfo?.prefBoxType === "COLD"}
										/>
									</fieldset>
								</div>
							)}
						</div>

						<div className={styles.buttons}>
							{productInfo !== null && (
								<fieldset className={styles.checkbox}>
									<label htmlFor="active">Aktywny</label>
									<span />
									<input type="checkbox" name="active" id="active" defaultChecked={productInfo.active} />
								</fieldset>
							)}
							<Button type="reset" loading={delLoading} onClick={handleDelete}>
								Usuń
							</Button>
							<Button type="submit" loading={loading}>
								Zapisz zmiany
							</Button>
						</div>
					</form>
				</div>
			</div>
		</Draggable>
	);
};

export default ProductEdit;

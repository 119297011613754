import getVendingOrderStatus from "constants/vendingOrderStatus";
import getCardNumber from "pages/Dashboard/Admin/VendingOrders/cardNumber";

const vendingOrdersExportTable = {
	vendingOrderId: {
		name: "Identyfikator transakcji",
	},
	vendingOrderProductsCounter: {
		name: "Ilość produktów",
		parser: (value) => {
			return `'${value}'`;
		},
	},
	supplierName: {
		name: "Dostawca",
	},
	productName: {
		name: "Nazwa produktu",
	},
	productNetto: {
		name: "Koszt netto",
		parser: (value) => {
			return `${value} zł`;
		},
	},
	productBrutto: {
		name: "Koszt brutto",
		parser: (value) => {
			return `${value} zł`;
		},
	},
	"payment.transactionEndData": {
		name: "Numer karty",
		parser: (transactionEndData) => {
			return getCardNumber({ transactionEndData });
		},
	},
	"payment.status": {
		name: "Status transakcji",
		parser: (status, { row }) => {
			return getVendingOrderStatus(status, row) || "Nieznany";
		},
	},
	createdAt: {
		name: "Data utworzenia",
		isDate: true,
		isOnlyDate: true,
		filter: (value) => {
			return new Date(value);
		},
		parser: (value) => {
			return `${new Date(value).toLocaleString()}`;
		},
	},
	updatedAt: {
		name: "Data aktualizacji",
		isDate: true,
		isOnlyDate: true,
		filter: (value) => {
			return new Date(value);
		},
		parser: (value) => {
			return `${new Date(value).toLocaleString()}`;
		},
	},
};

export default vendingOrdersExportTable;

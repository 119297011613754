import React, { useEffect } from "react";
import { useFetch } from "hooks/useFetch";
import PathsAPI from "constants/PathsAPI";
import styles from "styles/Orders.module.css";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import Button from "components/Button";
import stateMode from "constants/stateMode";
import vendingOrdersTable from "constants/tables/vendingOrdersTable";
import TableComponent from "components/Table";
import vendingOrdersExportTable from "constants/tables/export/vendingOrdersExportTable";

const VendingOrdersList = () => {
	const { useGet } = useFetch(PathsAPI.VendingOrders);
	const { request, response, loading, hasError } = useGet(true);
	const location = useLocation();

	const { useGet: useSuppliersGet } = useFetch(PathsAPI.Suppliers);
	const { response: suppliersList, loading: loadingSuppliersList } = useSuppliersGet(true);

	useEffect(() => {
		if (location.state === stateMode.Refresh) {
			request();
			location.state = null;
		}
	}, [location, request]);

	let navigate = useNavigate();

	const useHandleLoadData = () => {
		request();
	};

	const handleOpenDevice = ({ _id }) => {
		navigate(_id);
	};

	const handleExportParser = (body) => {
		let result = [];

		for (const vendingOrder of body) {
			const { _id: vendingOrderId, orderList, payment, createdAt, updatedAt, coffeeNetto, coffeeBrutto, coffeeCounter } = vendingOrder;
			let coffeeInProducts = 0;

			let potentialCoffeeNetto = coffeeNetto;
			let potentialCoffeeBrutto = coffeeBrutto;

			if (coffeeCounter > 0) {
				coffeeInProducts = 1;

				if (!potentialCoffeeNetto || !potentialCoffeeBrutto) {
					potentialCoffeeNetto = (payment.summaryCost.netto / 100).toFixed(2);
					potentialCoffeeBrutto = (payment.summaryCost.brutto / 100).toFixed(2);

					for (const order of orderList) {
						potentialCoffeeNetto -= Number(order.offerNetto);
						potentialCoffeeBrutto -= Number(order.offerBrutto);
					}

					potentialCoffeeNetto = (potentialCoffeeNetto / coffeeCounter).toFixed(2);
					potentialCoffeeBrutto = (potentialCoffeeBrutto / coffeeCounter).toFixed(2);
				}

				result.push({
					vendingOrderId,
					vendingOrderProductsCounter: `1 / ${orderList.length + 1}`,
					supplierName: "FoodBox",
					productName: "Kawa/Czekolada",
					productNetto: potentialCoffeeNetto || "Błąd",
					productBrutto: potentialCoffeeBrutto || "Błąd",
					payment,
					createdAt,
					updatedAt,
				});
			}

			for (const vendingOrderBox of orderList) {
				for (const [vendingOrderIndex, vendingOrderProduct] of vendingOrderBox.products.entries()) {
					const { supplier: supplierId, name: productName, cost: productNetto, brutto: productBrutto } = vendingOrderProduct;

					const { name: supplierName } = suppliersList.find((supplierData) => supplierData._id === supplierId);

					result.push({
						vendingOrderId,
						vendingOrderProductsCounter: `${vendingOrderIndex + 1 + coffeeInProducts} / ${orderList.length + coffeeInProducts}`,
						supplierName: supplierName || "Błąd",
						productName,
						productNetto: Number(productNetto).toFixed(2),
						productBrutto: Number(productBrutto).toFixed(2),
						payment,
						createdAt,
						updatedAt,
					});
				}
			}
		}

		return result;
	};

	return (
		<div className={styles.main}>
			<div className={styles.panelContainer}>
				<div className={styles.header}>
					<h2>Lista transakcji</h2>
					<div>
						<Button loading={loading} error={hasError} onClick={useHandleLoadData}>
							Odśwież
						</Button>
					</div>
				</div>
				<TableComponent
					title="Lista transakcji"
					headValues={vendingOrdersTable}
					bodyValues={response}
					error={hasError}
					additionalValues={{ suppliersList }}
					loadedAdditionalValues={[!loadingSuppliersList]}
					rowAction={handleOpenDevice}
					exportHeaders={vendingOrdersExportTable}
					exportParser={handleExportParser}
				/>
			</div>
			<Outlet />
		</div>
	);
};

export default VendingOrdersList;

const usersTable = {
	_id: {
		name: "Identyfikator",
	},
	supplier: {
		name: "Przypisany ..",
		parser: (supplierId, { suppliersList }) => {
			if (!supplierId) {
				return "-";
			}

			if (supplierId === "GLOBAL") {
				return "Globalny";
			}

			return suppliersList?.find((supplier) => supplier._id === supplierId)?.name || "Błąd";
		},
		hideColumn: ({ supplier }) => {
			return !["GLOBAL"].includes(supplier);
		},
		oneLine: true,
	},
	role: {
		name: "Typ",
		parser: (value, { row }) => {
			switch (value) {
				case "ADMIN":
					return row.supplier === "GLOBAL" ? "Administrator globalny" : "Administrator";
				case "DELIVERER":
					return row.supplier === "GLOBAL" ? "Dostawca globalny" : "Dostawca";
				case "USER":
					return "Użytkownik";

				default:
					return value;
			}
		},
	},
	email: {
		name: "Email",
	},
	"details.fname": {
		name: "Imię",
	},
	"details.lname": {
		name: "Nazwisko",
	},
	"details.phone": {
		name: "Numer telefonu",
	},
};

export default usersTable;

const devicesTable = {
	_id: {
		name: "Identyfikator urządzenia",
		mobile: false,
	},
	name: {
		name: "Nazwa",
	},
	IPAddress: {
		name: "Adres IP",
		mobile: false,
		oneLine: true,
		hideColumn: ({role, supplier}) => {
			const isGlobal = supplier === "GLOBAL";
			const isAdmin = role === "ADMIN";
			return !(isGlobal && isAdmin);
		},
	},
	locatedAt: {
		name: "Położony w",
		mobile: false,
		oneLine: true,
	},
	createdAt: {
		name: "Data dodania",
		mobile: false,
		isDate: true,
		filter: (value) => {
			return new Date(value);
		},
		parser: (value) => {
			return `${new Date(value).toLocaleString()}`;
		},
	},
	updatedAt: {
		name: "Data aktualizacji",
		mobile: false,
		isDate: true,
		filter: (value) => {
			return new Date(value);
		},
		parser: (value) => {
			return `${new Date(value).toLocaleString()}`;
		},
	},
};

export default devicesTable;

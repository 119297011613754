import React from "react";
import styles from "styles/OrderInfo.module.css";
import tableStyles from "styles/Table.module.css";
import { useFetch } from "hooks/useFetch";
import PathsAPI from "constants/PathsAPI";
import { Outlet, useParams } from "react-router-dom";
import Button from "components/Button";
import getVendingOrderStatus from "constants/vendingOrderStatus";
import { ReactSVG } from "react-svg";
import getCardNumber from "./cardNumber";
import getDeviceImage from "constants/getDeviceImage";

const VendingOrderInfo = () => {
	let params = useParams();
	const { useGet: useGetBasicData } = useFetch(`${PathsAPI.VendingOrders}/${params.id}`);
	const { response: resBasicData, loading, hasError, request } = useGetBasicData(true);

	const { useGet: useDevicesGet } = useFetch(PathsAPI.Devices);
	const { response: devicesList } = useDevicesGet(true);

	const calcSummary = (cartList) => {
		let newSummary = 0;
		cartList.forEach((product) => {
			const amount = product.hasOwnProperty("amount") ? product.amount : 1;
			newSummary += amount * product.cost;
		});
		return newSummary.toFixed(2);
	};

	const handleLoadDeviceImage = () => {
		const boxes = document.querySelectorAll(`.${styles.deviceImage} rect`);
		boxes.forEach((box) => {
			if (box.id === "") {
				return;
			}

			resBasicData.orderList.forEach((order) => {
				box.id === String(order.boxId) && (box.style.fill = "red");
			});
		});
	};

	const createOrderList = () => {
		if (!resBasicData?.orderList) {
			return "Ładowanie";
		}

		let result = resBasicData?.orderList
			?.map((order) => {
				return `- ${order.offerName}: ${order.boxId}`;
			})
			?.join("\n");

		if (resBasicData.coffeeCounter > 0) {
			if (result.length > 0) {
				result += "\n";
			}

			result += `- kawa ${resBasicData.coffeeCounter} szt.`;
		}

		return result;
	};

	const getSummaryCost = (summaryCost) => {
		if (summaryCost === "NONE") {
			return "-";
		}

		return `${(summaryCost / 100).toFixed(2)} zł`;
	};

	return (
		<div className={styles.main}>
			<div className={styles.panelContainer}>
				<div className={styles.header}>
					<h2>Szczegóły transakcji</h2>
					<div>
						<Button
							loading={loading}
							error={hasError}
							onClick={() => {
								request();
							}}
						>
							Odśwież
						</Button>
					</div>
				</div>
				<div className={styles.spaceAround}>
					<div>
						<table className={tableStyles.table}>
							<tbody>
								<tr style={{ fontWeight: "bold" }}>
									<td>Identyfikator transakcji</td>
									<td>{resBasicData?._id || "Ładowanie ..."}</td>
								</tr>
								<tr>
									<td style={{ fontWeight: "bold" }}>Status transakcji</td>
									<th>{resBasicData ? getVendingOrderStatus(resBasicData?.payment?.status, resBasicData) : "Ładowanie ..."}</th>
								</tr>
								<tr>
									<td style={{ fontWeight: "bold" }}>Wybrane oferty (Oferta: skrytka)</td>
									<td style={{ fontWeight: "bold", whiteSpace: "pre-line" }}>{createOrderList()}</td>
								</tr>
								<tr>
									<td style={{ fontWeight: "bold" }}>Identyfikator urządzenia</td>
									<td>{resBasicData?.deviceId || "Ładowanie ..."}</td>
								</tr>
								<tr>
									<td style={{ fontWeight: "bold" }}>Numer karty</td>
									<td style={{ fontWeight: "bold" }}>{resBasicData ? getCardNumber(resBasicData.payment) : "Ładowanie ..."}</td>
								</tr>
								<tr>
									<td style={{ fontWeight: "bold" }}>Koszt netto</td>
									<td>{getSummaryCost(resBasicData?.payment?.summaryCost?.netto) || "Ładowanie ..."}</td>
								</tr>
								<tr>
									<td style={{ fontWeight: "bold" }}>Koszt brutto</td>
									<td>{getSummaryCost(resBasicData?.payment?.summaryCost?.brutto) || "Ładowanie ..."}</td>
								</tr>
								<tr>
									<td style={{ fontWeight: "bold" }}>Położenie urządzenia</td>
									<td>{resBasicData?.deviceLocation || "Ładowanie ..."}</td>
								</tr>
								<tr>
									<td style={{ fontWeight: "bold" }}>Data utworzenia</td>
									<td>{resBasicData ? new Date(resBasicData?.createdAt).toLocaleString() : "Ładowanie ..."}</td>
								</tr>
								<tr>
									<td style={{ fontWeight: "bold" }}>Data aktualizacji</td>
									<td>{resBasicData ? new Date(resBasicData?.updatedAt).toLocaleString() : "Ładowanie ..."}</td>
								</tr>
							</tbody>
						</table>
					</div>

					<div>
						<table className={tableStyles.table}>
							<tbody>
								{resBasicData?.cartList && (
									<>
										<tr style={{ fontWeight: "bold" }}>
											<td>Nazwa produktu</td>
											<td>Ilość</td>
											<td>Cena</td>
										</tr>
										{resBasicData.cartList.map((item, index) => (
											<tr key={index}>
												<td>{item.name}</td>
												<td>{item.amount || 1}</td>
												<td>{item.cost} zł</td>
											</tr>
										))}
										<tr style={{ background: "var(--primary)", color: "#fff", fontWeight: "bold" }}>
											<td colSpan={2}>Razem</td>
											<td>{calcSummary(resBasicData.cartList)} zł</td>
										</tr>
									</>
								)}
							</tbody>
						</table>
					</div>
				</div>
			</div>

			<div className={styles.panelContainer}>
				<div className={styles.header}>
					<h2>Urządzenie</h2>
					<div></div>
				</div>

				<div style={{ display: "flex" }}>
					{resBasicData && devicesList && (
						<ReactSVG
							src={getDeviceImage(devicesList.find((device) => device._id === resBasicData?.deviceId)?.configuration?.type)}
							className={styles.deviceImage}
							alt="DeviceType1"
							afterInjection={handleLoadDeviceImage}
						/>
					)}
				</div>
			</div>
			<Outlet />
		</div>
	);
};

export default VendingOrderInfo;

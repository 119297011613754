import React from "react";
import styles from "styles/NumberSelect.module.css";

const NumberSelect = ({ children, number, setNumber, min, max, ...props }) => {
	const incValue = () => (!max || number < max) && setNumber(number + 1);
	const decValue = () => (!min || number > min) && setNumber(number - 1);

	return (
		<div className={styles.container} {...props}>
			<div className={styles.title}>{children}</div>
			<div>
				<span className={styles.divider} />
				<div className={styles.number}>{number}</div>
			</div>
			<div className={styles.buttons}>
				<span className={styles.divider} />
				<button onClick={decValue}>-</button>
				<span className={styles.divider} />
				<button onClick={incValue}>+</button>
			</div>
		</div>
	);
};

export default NumberSelect;

import React from "react";
import styles from "styles/BoxEditConfig.module.css";
import Button from "components/Button";
import NavButtonLink from "components/NavButtonLink";
import { useLocation, useNavigate } from "react-router-dom";
import { useRef } from "react";
import Draggable from "react-draggable";
import stateMode from "constants/stateMode";

const BoxEditConfig = () => {
	const nodeRef = useRef(null);
	const navigate = useNavigate();
	const location = useLocation();
	const { door: defaultDoor, relay: defaultRelay, temp: defaultTemp, rule: panelMode, cell, row, boxType } = location?.state || {};

	const handleSubmit = async (e) => {
		e.preventDefault();
		const formData = new FormData(e.target);

		navigate("..", { state: { mode: stateMode.BoxEditConfig, values: { ...location.state, ...Object.fromEntries(formData) } } });
	};

	return (
		<Draggable nodeRef={nodeRef} handle={`.${styles.header}`}>
			<div ref={nodeRef} className={styles.container}>
				<div className={styles.header}>
					<h2>Konfiguracja połączenia skrytki</h2>
					<NavButtonLink type="reset" to="../" state={stateMode.Refresh}>
						&#x2715;
					</NavButtonLink>
				</div>

				<form className={styles.form} onSubmit={handleSubmit}>
					<div className={styles.boxInfoContainer}>
						<div className={styles.boxInfoText}>
							<span>Dodatkowe informacje:</span>
							<span>Numeracja od 0</span>
						</div>
						<span className={styles.divider} />
						<div className={styles.boxInfoText}>
							<span>Kolumna: {cell + 1}</span>
							<span>Wiersz: {row + 1}</span>
						</div>
					</div>

					<fieldset className={styles.checkbox}>
						<label htmlFor="panelMode">Miejsce dla panelu urządzenia</label>
						<span />
						<input type="checkbox" name="panelMode" id="panelMode" defaultChecked={panelMode === "DISABLED"} />
					</fieldset>

					<fieldset className={styles.checkbox} style={{ background: "#ffb777" }}>
						<label htmlFor="warnBoxType">Ciepła skrytka</label>
						<span />
						<input type="radio" name="boxType" id="warnBoxType" value={"WARM"} defaultChecked={boxType === "WARM"} />
					</fieldset>

					<fieldset className={styles.checkbox} style={{ background: "#add8e6" }}>
						<label htmlFor="coldBoxType">Zimna skrytka</label>
						<span />
						<input type="radio" name="boxType" id="coldBoxType" value={"COLD"} defaultChecked={boxType === "COLD"} />
					</fieldset>

					<fieldset>
						<legend>Przekaźnik zamka (W postaci {`{moduł}{pozycja}`})</legend>
						<input
							required
							type="text"
							name="relay"
							placeholder={`Podaj w postaci {moduł}{pozycja}, np. ${cell + 1}${row + 1}`}
							defaultValue={`${defaultRelay}`}
						/>
					</fieldset>

					<fieldset>
						<legend>Stan drzwi (W postaci {`{moduł}{pozycja}`})</legend>
						<input
							required
							type="text"
							name="door"
							placeholder={`Podaj w postaci {moduł}{pozycja}, np. ${cell + 1}${row + 1}`}
							defaultValue={`${defaultDoor}`}
						/>
					</fieldset>

					<fieldset>
						<legend>Odczyt temperatury (W postaci {`{moduł}{pozycja}`})</legend>
						<input
							required
							type="text"
							name="temp"
							placeholder={`Podaj w postaci {moduł}{pozycja}, np. ${cell + 1}${row + 1}`}
							defaultValue={`${defaultTemp}`}
						/>
					</fieldset>

					<div className={styles.buttons}>
						<Button>Zapisz ustawienia</Button>
					</div>
				</form>
			</div>
		</Draggable>
	);
};

export default BoxEditConfig;

import React from "react";
import styles from "styles/NewProduct.module.css";
import { useFetch } from "hooks/useFetch";
import PathsAPI from "constants/PathsAPI";
import Button from "components/Button";
import NavButtonLink from "components/NavButtonLink";
import { useNavigate } from "react-router-dom";
import { useEffect, useRef, useState } from "react";
import Draggable from "react-draggable";
import stateMode from "constants/stateMode";
import { toast } from "react-toastify";
import { useAuthContext } from "hooks/useAuthContext";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import locale from "date-fns/locale/pl";

const NewProduct = () => {
	const nodeRef = useRef(null);
	const { usePost } = useFetch(PathsAPI.Products);
	const { request, response, loading, hasError } = usePost();
	const navigate = useNavigate();

	const { useGet: useSuppliersGet } = useFetch(PathsAPI.Suppliers);
	const { response: suppliersList } = useSuppliersGet(true);

	const { useGet: useCategoriesGet } = useFetch(PathsAPI.EditCategories);
	const { response: categoriesList } = useCategoriesGet(true);

	const [vatRate, setVatRate] = useState("0.23");
	const [netto, setNetto] = useState("");

	const { user } = useAuthContext();
	const isGlobal = user.supplier === "GLOBAL";

	const [startDeliveryDate, setStartDeliveryDate] = useState(new Date());
	const [openStartDeliveryDatePicker, setOpenStartDeliveryDatePicker] = useState(false);
	const openStartDeliveryDatePickerRef = useRef();

	const [endDeliveryDate, setEndDeliveryDate] = useState(new Date());
	const [openEndDeliveryDatePicker, setOpenEndDeliveryDatePicker] = useState(false);
	const openEndDeliveryDatePickerRef = useRef();

	const handleSubmit = (e) => {
		e.preventDefault();
		let result = Object.fromEntries(new FormData(e.target));

		result.startDeliveryDate = startDeliveryDate;
		result.endDeliveryDate = endDeliveryDate;

		result.cost = result.cost.replaceAll(",", ".");
		result.brutto = result.brutto.replaceAll(",", ".");

		request(result);
	};

	useEffect(() => {
		response?._id && navigate("../", { state: stateMode.Refresh });
	}, [response, navigate]);

	useEffect(() => {
		toast.error(hasError);
	}, [hasError]);

	return (
		<Draggable nodeRef={nodeRef} handle={`.${styles.header}`}>
			<div ref={nodeRef} className={styles.container}>
				<div className={styles.header}>
					<h2>Dodaj nowy produkt</h2>
					<NavButtonLink type="reset" to="../" state={stateMode.Refresh}>
						&#x2715;
					</NavButtonLink>
				</div>
				<div className={styles.mainContainer}>
					<form className={styles.form} onSubmit={handleSubmit}>
						<div className={styles.formContainer}>
							{isGlobal && (
								<fieldset>
									<legend>Przypisany do sklepu</legend>
									<select className={styles.select} name="supplier">
										{suppliersList &&
											suppliersList.map((supplier) => (
												<option key={supplier._id} value={supplier._id}>
													{supplier.name} ({supplier._id})
												</option>
											))}
									</select>
								</fieldset>
							)}

							<fieldset>
								<legend>Nazwa</legend>
								<input required type="text" name="name" placeholder="Podaj nazwę produktu .." />
							</fieldset>

							<fieldset>
								<legend>Opis produktu</legend>
								<textarea required type="text" name="desc" placeholder="Podaj opis produktu .." />
							</fieldset>

							<fieldset>
								<legend>Cena netto (PLN)</legend>
								<input
									required
									type="text"
									name="cost"
									placeholder="Podaj cenę netto .."
									onChange={(e) => {
										setNetto(Number(e.target.value.replaceAll(",", ".")));
									}}
								/>
							</fieldset>

							<fieldset>
								<legend>Stawka VAT</legend>
								<select
									className={styles.select}
									name="vat"
									onChange={(e) => {
										setVatRate(e.target.value);
									}}
								>
									<option value={"0.23"}>Stawka A (23%)</option>
									<option value={"0.08"}>Stawka B (8%)</option>
									<option value={"0.05"}>Stawka C (5%)</option>
									<option value={"0.00"}>Stawka D (0%)</option>
								</select>
							</fieldset>

							<fieldset>
								<legend>Cena brutto (PLN)</legend>
								<input
									required
									type="text"
									name="brutto"
									placeholder={`Sugerowana cena brutto: ${((1 + Number(vatRate)) * Number(netto)).toFixed(2)} zł`}
								/>
							</fieldset>

							<fieldset>
								<legend>Kategoria</legend>
								<select className={styles.select} name="category">
									{categoriesList &&
										categoriesList.map((category) => (
											<option key={category._id} value={category.name}>
												{category.name}
											</option>
										))}
								</select>
							</fieldset>

							<fieldset>
								<legend>Adres URL zdjęcia</legend>
								<input required type="text" name="photoUrl" placeholder="Podaj adres URL zdjęcia .." />
							</fieldset>

							<fieldset>
								<legend>Numer kodu kreskowego</legend>
								<input required type="text" name="barcode" placeholder="Podaj numer kodu kreskowego .." />
							</fieldset>

							<div style={{ display: "flex", alignItems: "center" }}>
								<fieldset>
									<legend>Rozpoczęcie dostarczania</legend>
									<input
										type="text"
										value={new Date(startDeliveryDate).toLocaleDateString("pl")}
										onClick={() => {
											setOpenStartDeliveryDatePicker(!openStartDeliveryDatePicker);
										}}
										ref={openStartDeliveryDatePickerRef}
									></input>
								</fieldset>

								<LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={locale}>
									<DatePicker
										open={openStartDeliveryDatePicker}
										onClose={() => {
											setOpenStartDeliveryDatePicker(false);
										}}
										slotProps={{ popper: { anchorEl: openStartDeliveryDatePickerRef.current } }}
										value={startDeliveryDate}
										onChange={(value) => {
											value.setHours(23, 59, 59, 999);
											setStartDeliveryDate(value);
										}}
										slots={{ field: () => <></> }}
									/>
								</LocalizationProvider>

								<fieldset>
									<legend>Zakończenie dostarczania</legend>
									<input
										type="text"
										value={new Date(endDeliveryDate).toLocaleDateString("pl")}
										onClick={() => {
											setOpenEndDeliveryDatePicker(!openEndDeliveryDatePicker);
										}}
										ref={openEndDeliveryDatePickerRef}
									></input>
								</fieldset>

								<LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={locale}>
									<DatePicker
										open={openEndDeliveryDatePicker}
										onClose={() => {
											setOpenEndDeliveryDatePicker(false);
										}}
										slotProps={{ popper: { anchorEl: openEndDeliveryDatePickerRef.current } }}
										value={endDeliveryDate}
										onChange={(value) => {
											value.setHours(23, 59, 59, 999);
											setEndDeliveryDate(value);
										}}
										slots={{ field: () => <></> }}
									/>
								</LocalizationProvider>
							</div>

							<div className={styles.checkboxContainer}>
								<fieldset className={styles.checkbox} style={{ background: "#ffb777" }}>
									<label htmlFor="warnBoxType">Ciepłe danie</label>
									<span />
									<input required type="radio" name="prefBoxType" id="warnBoxType" value={"WARM"} />
								</fieldset>

								<fieldset className={styles.checkbox} style={{ background: "#add8e6" }}>
									<label htmlFor="coldBoxType">Zimne danie</label>
									<span />
									<input required type="radio" name="prefBoxType" id="coldBoxType" value={"COLD"} />
								</fieldset>
							</div>
						</div>

						<div className={styles.buttons}>
							<Button loading={loading}>Dodaj nowy produkt</Button>
						</div>
					</form>
				</div>
			</div>
		</Draggable>
	);
};

export default NewProduct;

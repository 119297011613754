const { useMap } = require("react-leaflet");

const CenterToCords = ({ coords }) => {
	const map = useMap();
	map.setView(coords, map.getZoom());

	return null;
};

export default CenterToCords;

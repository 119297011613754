import React from "react";
import styles from "styles/DeviceDestinyConfig.module.css";
import { useFetch } from "hooks/useFetch";
import PathsAPI from "constants/PathsAPI";
import { useParams } from "react-router-dom";
import NavButtonLink from "components/NavButtonLink";
import { useEffect, useRef, useState } from "react";
import Draggable from "react-draggable";
import stateMode from "constants/stateMode";
import { ReactSVG } from "react-svg";
import { toast } from "react-toastify";
import getDeviceImage from "constants/getDeviceImage";

const DeviceDestinyConfig = () => {
	const nodeRef = useRef(null);
	let params = useParams();

	const { useGet: useSuppliersGet } = useFetch(PathsAPI.Suppliers);
	const { response: suppliersList, hasError: hasSuppliersError } =
		useSuppliersGet(true);

	const { useGet: useDeviceGet } = useFetch(`${PathsAPI.Devices}/${params.id}`);
	const { response: deviceList, hasError: hasDeviceError } = useDeviceGet(true);

	const { usePut: useDevicePut } = useFetch(
		`${PathsAPI.Devices}/${params.id}/box`
	);
	const { request } = useDevicePut();

	const [selectedSupplier, setSelectedSupplier] = useState("");

	const [deviceBoxes, setDeviceBoxes] = useState();

	useEffect(() => {
		deviceList && setDeviceBoxes(deviceList.boxes);
	}, [deviceList]);

	const handleLoadDeviceImage = () => {
		const boxes = document.querySelectorAll(`.${styles.deviceSVG} rect`);

		boxes.forEach((box) => {

			if(box.id.includes("c")) {
				const boxIdNumber = box.id.replace("c", "");

				box.onclick = () => {
					const oldSelected = deviceBoxes[boxIdNumber].supplier === selectedSupplier;

					request({
						version: "V2",
						url: `/${boxIdNumber}`,
						body: { supplier: !oldSelected ? selectedSupplier : "" },
					})
						.then((newDeviceBoxes) => {
							setDeviceBoxes(newDeviceBoxes);
						})
						.catch(toast.error);

					return;
				};

				return;
			}

			if (box.id === "") {
				return;
			}

			if (selectedSupplier === "") {
				box.style.fill = "gray";
				return;
			}

			if (!deviceBoxes) {
				box.style.fill = "gray";
				return;
			}

			if (deviceBoxes[box.id].supplier === selectedSupplier) {
				box.style.fill = "orange";
			} else if (deviceBoxes[box.id].supplier) {
				box.style.fill = "red";
			}

			if (box.style.fill === "gray" || box.style.fill === "red") {
				return;
			}

			box.onclick = () => {
				const oldSelected = box.style.fill === "orange";
				box.style.fill = "gray";

				request({
					version: "V2",
					url: `/${box.id}`,
					body: { supplier: !oldSelected ? selectedSupplier : "" },
				})
					.then((newDeviceBoxes) => {
						setDeviceBoxes(newDeviceBoxes);
					})
					.catch(toast.error);

				return;
			};
		});
	};

	const handleChangeSupplier = (e) => {
		setSelectedSupplier(e.target.value);
	};

	useEffect(() => {
		toast.error(hasSuppliersError);
	}, [hasSuppliersError]);

	useEffect(() => {
		toast.error(hasDeviceError);
	}, [hasDeviceError]);

	return (
		<Draggable
			nodeRef={nodeRef}
			handle={`.${styles.header}`}
			positionOffset={{ x: "-50%", y: "-50%" }}
		>
			<div ref={nodeRef} className={styles.container}>
				<div className={styles.header}>
					<h2>Uprawnienia dostawców</h2>
					<NavButtonLink type="reset" to="../" state={stateMode.Refresh}>
						&#x2715;
					</NavButtonLink>
				</div>
				<div className={styles.form}>
					<div className={styles.mainContainer}>
						<fieldset>
							<legend>Dostawcy</legend>
							<select className={styles.select} onChange={handleChangeSupplier}>
								<option selected={selectedSupplier === ""} disabled>
									Wybierz dostawcę ..
								</option>
								{suppliersList &&
									suppliersList.map((supplier) => (
										<option
											key={supplier._id}
											value={supplier._id}
											selected={supplier._id === selectedSupplier}
										>
											{supplier.name} ({supplier._id})
										</option>
									))}
							</select>
						</fieldset>

						{suppliersList && deviceList?.configuration?.type && (
							<ReactSVG
								src={getDeviceImage(deviceList.configuration.type)}
								className={styles.deviceSVG}
								alt="DeviceType1"
								afterInjection={handleLoadDeviceImage}
							/>
						)}
					</div>
				</div>
			</div>
		</Draggable>
	);
};

export default DeviceDestinyConfig;

import DeviceType1 from "assets/devices/type1.svg";
import DeviceType2 from "assets/devices/type2.svg";
import DeviceType3 from "assets/devices/type3.svg";
import DeviceType4 from "assets/devices/type4.svg";
import DeviceType5 from "assets/devices/type5.svg";

const getDeviceImage = (DeviceType) => {
	switch (Number(DeviceType)) {
		case 1:
			return DeviceType1;
		case 2:
			return DeviceType2;
		case 3:
			return DeviceType3;
		case 4:
			return DeviceType4;
		case 5:
			return DeviceType5;
		default:
			return "";
	}
};

export default getDeviceImage;

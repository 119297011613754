import getVendingOrderStatus from "constants/vendingOrderStatus";
import getCardNumber from "pages/Dashboard/Admin/VendingOrders/cardNumber";

const getSummaryCost = (summaryCost) => {
	if (summaryCost === "NONE") {
		return "-";
	}

	return `${(summaryCost / 100).toFixed(2)} zł`;
};

const vendingOrdersTable = {
	_id: {
		name: "Identyfikator transakcji",
	},
	"payment.summaryCost.netto": {
		name: "Koszt netto",
		parser: (value) => {
			return `${getSummaryCost(value)}`;
		},
	},
	"payment.summaryCost.brutto": {
		name: "Koszt brutto",
		parser: (value) => {
			return getSummaryCost(value);
		},
	},
	"payment.transactionEndData": {
		name: "Numer karty",
		parser: (transactionEndData) => {
			return getCardNumber({ transactionEndData });
		},
	},
	"payment.status": {
		name: "Status transakcji",
		parser: (status, { row }) => {
			return getVendingOrderStatus(status, row) || "Nieznany";
		},
	},
	createdAt: {
		name: "Data utworzenia",
		isDate: true,
		filter: (value) => {
			return new Date(value);
		},
		parser: (value) => {
			return `${new Date(value).toLocaleString()}`;
		},
	},
	updatedAt: {
		name: "Data aktualizacji",
		isDate: true,
		filter: (value) => {
			return new Date(value);
		},
		parser: (value) => {
			return `${new Date(value).toLocaleString()}`;
		},
	},
};

export default vendingOrdersTable;

import React, { useEffect } from "react";
import { useFetch } from "hooks/useFetch";
import PathsAPI from "constants/PathsAPI";
import styles from "styles/Devices.module.css";
import { Outlet, useLocation } from "react-router-dom";
import Button from "components/Button";
import { mdiNoteEdit } from "@mdi/js";
import NavButtonLink from "components/NavButtonLink";
import stateMode from "constants/stateMode";
import { useAuthContext } from "hooks/useAuthContext";
import TableComponent from "components/Table";
import voucherTable from "constants/tables/voucherTable";

const VouchersList = () => {
	const { user } = useAuthContext();
	const { useGet } = useFetch(PathsAPI.Voucher);
	const { request, response, loading, hasError } = useGet(true);
	const location = useLocation();

	const { useGet: useProductsGet } = useFetch(PathsAPI.Products);
	const { response: productsList, loading: loadingProducts } = useProductsGet(true);

	const isAdmin = "ADMIN" === user?.role ? true : false;

	useEffect(() => {
		if (location.state === stateMode.Refresh) {
			request();
			location.state = null;
		}
	}, [location, request]);

	const useHandleLoadData = () => {
		request();
	};

	const voucherTableActions = [
		{
			type: "edit",
			name: "Edytuj",
			hideColumn: () => {
				return !isAdmin;
			},
			icon: mdiNoteEdit,
			navigate: (value) => {
				return `${value._id}/edit`;
			},
		},
	];

	return (
		<div className={styles.main}>
			<div className={styles.panelContainer}>
				<div className={styles.header}>
					<h2>Lista voucherów</h2>
					<div>
						{isAdmin && <NavButtonLink to="create">Dodaj nowy voucher</NavButtonLink>}
						<Button loading={loading} error={hasError} onClick={useHandleLoadData}>
							Odśwież
						</Button>
					</div>
				</div>
				<TableComponent
					title="Lista voucherów"
					headValues={voucherTable}
					bodyValues={response}
					error={hasError}
					actions={voucherTableActions}
					additionalValues={{ productsList }}
					loadedAdditionalValues={[!loadingProducts]}
				/>
			</div>
			<Outlet />
		</div>
	);
};

export default VouchersList;

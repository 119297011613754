const getVendingOrderStatus = (status, row) => {
	let result = "";

	switch (status) {
		case "READY":
			result = "Gotowe do płatności";
			break;

		case "START":
			result = "Rozpoczęto płatność";
			break;

		case "TERMINAL_WAIT":
			result = "Oczekiwanie na odpowiedź terminala";
			break;

		case "ERROR_PAYMENT":
			result = "Płatność nieudana";

			const readerType = row.payment.transactionEndData?.terminal?.readerType;

			if (readerType) {
				result += ", ";

				if (readerType === "ok_icc_card_contactless") {
					result += "karta zbliżona";
				} else if (readerType === "error_nocard_timeout") {
					result += "przekroczono czas oczekiwania";
				} else if (readerType === "ok_emv_card_icc") {
					result += "karta włożona";
				} else {
					result += "błąd nieznany";
				}
			}

			break;

		case "SUCCESS_PAYMENT_DEVICE_ERROR":
			result = "Płatność pomyślna, problem z otwarciem skrytki";
			break;

		case "SUCCESS_PAYMENT_DEVICE_SUCCESS":
			result = "Płatność pomyślna, zamówienie odebrane";
			break;

		default:
			result = "Błąd płatności";
			break;
	}

	return result;
};

export default getVendingOrderStatus;
